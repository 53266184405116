import React, { useState } from "react";
import {
  faMemory,
  faMicrochip,
  faSimCard,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPhone, faPlay } from "@fortawesome/free-solid-svg-icons";
import "../../Styles/Redirecting Styles/NavbarStyles/Software.css";
import shape6 from "../../assets/brand/choose-shape-2.png";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import Header1 from "../../Components/seperate Components/Header1";
import Footer from "../../Components/Footer";
import python from "../../assets/logo/python.png";
import magento from "../../assets/logo/magento.png";
import drupal from "../../assets/logo/drupal.png";
import joomla from "../../assets/logo/joomla.png";
import shopify from "../../assets/logo/shopify.png";
import woocommerce from "../../assets/logo/woocommerce.png";
import wordpress from "../../assets/logo/wordpress.png";
import css from "../../assets/logo/css-3.png";
import { Helmet } from "react-helmet";

const WebsiteDevelopment = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Helmet>
        <title>Best Website Design and Development Company in India</title>
        <meta name='description' content='Discover excellence with the best web development company in India. Expert in website design and development, we redefine your online presence.' />
      </Helmet>

      <Header1 />

      {/* Banner section */}
      <div
        className="bd-page-title-area bd-page-title-bg3 pt-220 pb-155"
        style={{ height: "380px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="bd-page-title text-center z-index"
                style={{ bottom: "50px" }}
              >
                <h2
                  className="breadcrumb-title"
                  style={{ fontSize: "50px", color: "#4a026c" }}
                >
                  Website Development
                </h2>
                <div className="breadcrumb-menu">
                  <nav className="breadcrumb-trail breadcrumbs">
                    <p style={{ color: "black" }}>
                      We use cutting-edge frameworks to guarantee that our
                      solutions are prepared for the future and easily scalable.
                    </p>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* First Section */}
      <section className="bd-about-area">
        <div className="bd-custom-container">
          <div className="bd-about-four-bg  pt-80 pb-70 fix">
            <div className="bd-about-shape-box">
              <img
                className="bd-about-shape bd-about-shape-1"
                src="assets/img/shape/about-shape-3.png"
                alt="shape img not found"
              />
              <img
                className="bd-about-shape bd-about-shape-2"
                src="assets/img/shape/about-shape-4.png"
                alt="shape img not found"
              />
              <div className="container" style={{ maxWidth: "1270px" }}>
                <div className="row justify-content-center align-items-center">
                  <div className="col-xl-6 col-lg-8 software-first-section1">
                    <div className="bd-choose-img text-end mb-30 main-section-software z-index">
                      <div className="row align-items-center custom-mar-15">
                        <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                          <div className="bd-choose-img-inner mb-15 bd-choose-img-inner-1">
                            <img
                              src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659376400/website/software-development/software-development-sec-1.3_lwp4ka.jpg"
                              className="bordertop-img1"
                              alt="img not found"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                          <div className="bd-choose-img-inner mb-15">
                            <img
                              src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659376400/website/software-development/software-development-sec-1.1_vkvegk.jpg"
                              className="bordertop-img2"
                              alt="img not found"
                            />
                          </div>
                          <div className="bd-choose-img-inner mb-15">
                            <img
                              src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659376400/website/software-development/software-development-sec-1.2_rzowlx.jpg"
                              className="bordertop-img3"
                              alt="img not found"
                            />
                          </div>
                        </div>
                      </div>
                      <img
                        className="bd-choose-shape bd-choose-shape-2"
                        src={shape6}
                        alt="shape img not found"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-8 software-first-section2">
                    <div className="bd-about-text bd-about-text-four z-index">
                      <div className="bd-section-title-wrapper">
                        <h2 className="bd-section-title mb-15 first_section-title md-pr-50">
                          Why do you need a website?{" "}
                        </h2>
                        <p className="mt-4">
                          A website establishes credibility, builds trust, and
                          provides a platform to showcase your expertise.
                          Whether you're a small startup or a large enterprise,
                          a website is your online identity, a powerful tool for
                          brand promotion, customer engagement, and driving
                          business growth. Zithas Technologies understands the
                          significance of a robust online presence and can help
                          you create a website that truly reflects your business
                          and its potential.
                        </p>
                        {/* <p>
                                                    In the realm of business, software holds a pivotal role, fostering task automation and elevating efficiency levels. The automation of various tasks translates to significant time and resource savings for businesses. Furthermore, for businesses based in Canada, software stands as a cornerstone in enhancing the customer experience and fostering productivity by reducing stress and facilitating sound decision-making.
                                                </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalVideo
          autoplay
          isOpen={isOpen}
          channel="youtube"
          videoId="6tZI6yQowmk"
          onClose={() => setOpen(false)}
        ></ModalVideo>
        <button
          className="btn btn touch_pop_vid first_pop_video_software"
          onClick={() => setOpen(true)}
        >
          <FontAwesomeIcon
            icon={faPlay}
            className="play_bt_vid play_transition"
          />
        </button>
      </section>

      {/* Second Section */}
      <section className="bd-choose-area fix">
        <div className="container" style={{ maxWidth: "1300px" }}>
          <div className="bd-choose-shape pb-70">
            <div className="row responsive-second-part align-items-center">
              <div className="col-xl-6 col-lg-6 order-2 order-lg-1 second-responsive-software1">
                {/* <div className="ablog ablog-4 mb-60"> */}

                <div className="bd-choose-text  z-index">
                  <h2 className="bd-section-title ml-15 software-responsive-tittle">
                    We provide exceptional{" "}
                    <span style={{ color: "#ffbf1e" }}>
                      web design and development{" "}
                    </span>{" "}
                    services{" "}
                  </h2>
                  <div className="bd-section-title-wrapper">
                    <p className="ml-25 para-software">
                      Unlock the potential of your online presence with our
                      unparalleled web design and development services. Our
                      expert team crafts seamless digital experiences tailored
                      to your unique needs. Harnessing the latest technologies
                      and creative prowess, we ensure your website not only
                      stands out visually but also excels in user engagement.
                      Elevate your brand with our exceptional services that go
                      beyond the ordinary, setting you apart in the digital
                      landscape.
                    </p>
                  </div>
                  <div></div>
                </div>
                {/* </div> */}
              </div>
              <div className="col-xl-6 col-lg-6 order-1 order-lg-2 mt-0 second-responsive-software2">
                <div className="bd-choose-img mb-30 z-index pt-30">
                  <div className="bd-choose-info-wrapper">
                    <div className="container">
                      <div className="row mb-50">
                        <div className="col mr-50">
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440813/website/icons/transaction-64_fiawxy.png" />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                Unique Design{" "}
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440813/website/icons/unique-64_po4w9h.png" />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                Optimized Pages{" "}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="row ">
                        <div className="col mr-25">
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440813/website/icons/business-process-64_nyvjei.png" />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                User Friendly{" "}
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440813/website/icons/cost-64_h5mry4.png" />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                Web Integration{" "}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Third Section */}
      <section
        className="bd-video-area pt-105 pb-120"
        style={{ backgroundColor: "#5c0385" }}
      >
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row">
            <div className="col-lg-12 first_section">
              <div className="bd-video-text">
                <p className="contact_section">Your timing, Your Success.</p>
              </div>
            </div>
            <div className="col-lg-12 second_section">
              <div className="contact-stuff">
                <div className="contact_icon pt-15 pl-50">
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ fontSize: "30px" }}
                  />
                </div>
                <div className="contact_txt">
                  <p className="contact-sub-text">CALL US NOW</p>
                  <a
                    href="tel:(+91)720-288-2277"
                    style={{ fontSize: "17px" }}
                    className="mbb-5 pb-20"
                  >
                    (+91) 720-288-2277
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* new sections */}

      {/* <section className="bd-about-six-area  pt-80 pb-50">
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <h2 className="bd-section-title mb-20 third-mobile-tittle" >
                        Make your dreams a reality with our
                        <span style={{ color: "#ffbf1e" }}> Web Development Solutions. </span>
                    </h2>

                    <ul style={{ color: "black", fontSize: "20px", listStyle: "none" }}>
                        <li>
                            What do we provide?
                        </li>
                    </ul>
                    <div className="bd-service service-mobile mb-30 mt-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                        <div className="row third-mobile-section1">
                            <div className="col-lg-6 mobile-subpoint-section">
                                <h4 style={{ fontWeight: "600" }}>Custom Web Development </h4>
                                <div className="bd-about-six-text">
                                    <div className="bd-section-title-wrapper mb-25">
                                        <p style={{ fontSize: "18px", lineHeight: "1.75" }}>
                                            Our proficient in-house professionals use state-of-the-art technologies to construct custom web applications that are not only visually appealing but also resilient, secure, and adaptable. We adhere to agile development principles, guaranteeing adaptability and expedited delivery for your projects.
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-6 col-md-8 mobile-subpoint-section " >
                                <div className="bd-about-six-img">
                                    <div className="bd-about-six-feature six-feature-mobile pl-120">
                                        <h4 className="mb-15" style={{ fontWeight: "600" }}>Technologies</h4>
                                        <ul className="">
                                            <li className='d-flex align-items-center' style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i>
                                                Php </li>
                                            <li className='d-flex align-items-center' style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> Node js </li>
                                            <li className='d-flex align-items-center' style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> .net </li>
                                            <li className='d-flex align-items-center' style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> Ruby</li>
                                            <li className='d-flex align-items-center' style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i>Python</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bd-service mb-30 service-mobile" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                        <div className="row third-mobile-section1">
                            <div className="col-lg-6 mobile-subpoint-section">
                                <h4 style={{ fontWeight: "600" }}>Content Management System</h4>
                                <div className="bd-about-six-text">
                                    <div className="bd-section-title-wrapper mb-25">
                                        <p style={{ fontSize: "18px", lineHeight: "1.75" }}>
                                            Our mastery lies in crafting content management systems tailored to the web. By meticulously researching market dynamics and aligning them with your unique business needs, we sculpt an ideal CMS framework to propel your business forward.
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-6 col-md-8 mobile-subpoint-section">
                                <div className="bd-about-six-img">
                                    <div className="bd-about-six-feature six-feature-mobile pl-120">
                                        <h4 className="mb-15" style={{ fontWeight: "600" }}>Technologies</h4>
                                        <ul className="">
                                            <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> WordPress</li>
                                            <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i>Joomla</li>
                                            <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i>Drupal</li>
                                            <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i>Custom CMS </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bd-service mb-30 service-mobile" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                        <div className="row third-mobile-section1">
                            <div className="col-lg-6 mobile-subpoint-section">
                                <h4 style={{ fontWeight: "600" }}>Ecommerce </h4>
                                <div className="bd-about-six-text">
                                    <div className="bd-section-title-wrapper mb-25">
                                        <p style={{ fontSize: "18px", lineHeight: "1.75" }}>
                                        We specialize in crafting ecommerce websites with a distinctive design that sets you apart in the competitive landscape. With eCommerce, you can streamline your sales processes, offer personalized shopping experiences, and boost your bottom line.
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-6 col-md-8 mobile-subpoint-section">
                                <div className="bd-about-six-img">
                                    <div className="bd-about-six-feature six-feature-mobile pl-120">
                                        <h4 className="mb-15" style={{ fontWeight: "600" }}>Technologies</h4>
                                        <ul className="">
                                            <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> Magento</li>
                                            <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i>Woo commerce </li>
                                            <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i>Shopify</li>
                                            <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i>Open cart </li>
                                            <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i>Zen cart  </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

      {/* Fourth Section */}
      {/* <section className="bd-about-six-area    pt-80">
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row">
                        <div className="col-lg-6 col-md-8">
                            <div className="bd-about-six-text">
                                <div className="bd-section-title-wrapper mb-25">
                                    <h2 className="bd-section-title mb-15 kick-section-tittle">
                                        Kickstart Your Endeavors with a Structured and Simplified Method.
                                    </h2>
                                </div>
                                <div className="bd-about-six-feature">
                                    <ul className="mb-45">
                                        <li className="point-software" style={{ fontSize: "20px" }}><i><FontAwesomeIcon icon={faCheck} /></i> Outline the specific requirements for your business.</li>
                                        <p className="soft-para">
                                            Prior to initiating software development, it's vital to establish your business requirements. This pivotal phase streamlines the creation of a well-aligned process and ensures the development of a product that effectively caters to your specific needs.
                                        </p>
                                        <li className="point-software" style={{ fontSize: "20px" }}><i><FontAwesomeIcon icon={faCheck} /></i> Prepare a comprehensive project plan</li>
                                        <p className="soft-para">
                                            Once you have identified your business requirements, it is time to create a project plan that outlines the necessary steps to successfully accomplish your project.
                                        </p>
                                        <li className="point-software" style={{ fontSize: "20px" }}><i><FontAwesomeIcon icon={faCheck} /></i>Develop a product</li>
                                        <p className="soft-para">
                                            After pinpointing your business requirements, the next step is to formulate a project plan delineating the essential actions required to achieve project success.
                                        </p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="bd-about-six-img">
                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659587831/website/software-development/software-section2-img1_mgnepm.jpg" className="pl-60" alt="img not found" />
                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659413697/website/software-development/software-development-sec-2.2_hecshw.jpg" className="bd-about-six-img-second" alt="img not found" />
                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659413698/website/software-development/software-development-sec-2.3_ywiajg.jpg" className="bd-about-six-img-third" alt="img not found" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

      {/* Fiveth Section */}
      {/* <div className="packages-text pb-80 pt-80">
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="packages-quest">
                        <h1 className="fiveth-section-soft mb-25" style={{ fontWeight: "700" }}>
                            Curious about Choosing the Perfect Canadian <span style={{ color: "#ffbf1e" }}>Software Development</span> Company for Your Business?
                        </h1>

                        <ul className="soft-responsive pl-20">
                            <li className="packages-content-text extra-para pb-25 d-flex " style={{ listStyle: "none" }}><FontAwesomeIcon icon={faCheck} style={{ paddingRight: "20px", color: "var(--clr-body-heading)" }} />
                                In today's fast-paced world, there's no shortage of choices when it comes to picking a Software Development Company for your business. However, identifying the perfect fit for your unique requirements can pose quite a challenge.
                            </li>

                            <li className="packages-content-text pb-25 d-flex" style={{ listStyle: "none" }}><FontAwesomeIcon icon={faCheck} style={{ paddingRight: "20px", color: "var(--clr-body-heading)" }} />
                                Opting for a Software Development Company with extensive industry experience ensures their capability to deliver a customized software solution tailored to your business. When seeking a company well-versed in software development, focus on those capable of offering valuable insights and demonstrating a track record of undeniable success.
                            </li>

                            <li className="packages-content-text d-flex" style={{ listStyle: "none" }}> <FontAwesomeIcon icon={faCheck} style={{ paddingRight: "20px", color: "var(--clr-body-heading)" }} />
                                Opting for a Software Development Company in Canada that offers a broad spectrum of relevant services reflects their capability to provide exceptional development solutions. Nevertheless, depending on your precise business needs, this might not suffice. You might require more specialized services tailored to your unique requirements. Hence, it's vital to seek out companies that deliver top-tier software development services to guarantee your expectations are not just met but exceeded.
                            </li>
                        </ul>
                    </div>
                </div>
            </div> */}

      {/* sixth Section */}
      <section className="bd-service-area pb-70 pt-70 ">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row"></div>
          <div className="row sixth-main-responsive">
            <h2 className="mb-30 sixth-section-soft">
              Make your dreams a reality with our web development solutions.{" "}
            </h2>
            <div className="col-lg-4 col-md-6 sixth-soft-section1">
              <div
                className="bd-service mb-30"
                style={{ backgroundColor: "var(--clr-bg-gray)" }}
              >
                <h4 class="bd-service-title mb-20">
                  <a>Custom Web Development</a>
                </h4>
                <p>
                  Our skilled in-house experts leverage cutting-edge
                  technologies to create tailor-made web applications that are
                  not just visually captivating but also robust, secure, and
                  flexible. We follow agile development principles, ensuring
                  flexibility
                </p>
                <table style={{ marginLeft: "50px" }}>
                  <tbody>
                    <tr style={{ marginBottom: "20px" }}>
                      <td className="pb-30" style={{ width: "180px" }}>
                        {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616468/website/icons/filezilla_t4bxqw.png" /> */}
                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/php_s2e2xk.png" />
                      </td>
                      <td className="pb-30" style={{ width: "180px" }}>
                        {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616468/website/icons/visual-studio-code_ouivep.png" /> */}
                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/nodejs_lab0zs.png" />
                      </td>
                      <td className="pb-30">
                        {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616468/website/icons/windows-10_kl8cu2.png" /> */}
                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/dot-net_rqknrj.png" />
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616468/website/icons/linux-server_p6azlz.png" />
                      </td>
                      <td>
                        {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616468/website/icons/xcode_hxtmqb.png" /> */}
                        <img src={python} style={{ width: "64px" }} />
                      </td>
                      <td>
                        {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616468/website/icons/postman_bshp0x.png" /> */}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 sixth-soft-section1">
              <div
                className="bd-service mb-30"
                style={{ backgroundColor: "var(--clr-bg-gray)" }}
              >
                <h4 class="bd-service-title mb-20">
                  <a>CMS (Content Management System)</a>
                </h4>
                <p>
                  We specialize in developing content management systems finely
                  tuned for the web. Through thorough research of market
                  dynamics harmonized with your distinct business requirements,
                  we shape an optimal CMS framework to propel your business to
                  new heights.
                </p>
                <table style={{ marginLeft: "50px" }}>
                  <tbody>
                    <tr style={{ marginBottom: "20px" }}>
                      <td className="pb-30" style={{ width: "180px" }}>
                        {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/dot-net_rqknrj.png" /> */}
                        <img src={wordpress} style={{ width: "64px" }} />
                      </td>
                      <td className="pb-30" style={{ width: "180px" }}>
                        {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/angularjs_luxe11.png" /> */}
                        <img src={joomla} style={{ width: "64px" }} />
                      </td>
                      <td className="pb-30">
                        {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/nodejs_lab0zs.png" /> */}
                        <img src={drupal} style={{ width: "64px" }} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/flutter_p8yphu.png" /> */}
                        <img src={css} style={{ width: "64px" }} />
                      </td>
                      <td>
                        {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/php_s2e2xk.png" /> */}
                      </td>
                      <td>
                        {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/react_hmd8zn.png" /> */}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 sixth-soft-section1">
              <div
                className="bd-service mb-30"
                style={{ backgroundColor: "var(--clr-bg-gray)" }}
              >
                <h4 class="bd-service-title mb-20">
                  <a>Ecommerce</a>
                </h4>
                <p>
                  Our expertise lies in creating unique, standout eCommerce
                  websites that give you a competitive edge. Through eCommerce,
                  you can streamline your sales procedures, deliver personalized
                  shopping experiences, and enhance your profitability.
                </p>
                <table style={{ marginLeft: "50px" }}>
                  <tbody>
                    <tr style={{ marginBottom: "20px" }}>
                      <td className="pb-30" style={{ width: "180px" }}>
                        {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/dot-net_rqknrj.png" /> */}
                        <img src={magento} style={{ width: "64px" }} />
                      </td>
                      <td className="pb-30" style={{ width: "180px" }}>
                        {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/angularjs_luxe11.png" /> */}
                        <img src={woocommerce} style={{ width: "64px" }} />
                      </td>
                      <td className="pb-30">
                        {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/nodejs_lab0zs.png" /> */}
                        <img src={shopify} style={{ width: "64px" }} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/flutter_p8yphu.png" /> */}
                      </td>
                      <td>
                        {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/php_s2e2xk.png" /> */}
                      </td>
                      <td>
                        {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/react_hmd8zn.png" /> */}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Seventh Section */}
      {/* <section className="bd-about-area">
                <div className="bd-custom-container" >
                    <div className="bd-about-four-bg pb-70 fix">
                        <div className="bd-about-shape-box">
                            <img className="bd-about-shape bd-about-shape-1" src="assets/img/shape/about-shape-3.png" alt="shape img not found" />
                            <img className="bd-about-shape bd-about-shape-2" src="assets/img/shape/about-shape-4.png" alt="shape img not found" />
                            <div className="container" style={{ maxWidth: "1270px" }}>
                                <div className="row justify-content-center seventh-main">

                                    <div className="col-xl-6 col-lg-8 seventh-soft-section1">
                                        <div className="bd-about-img mb-30">
                                            <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659413697/website/software-development/software-development-sec-3_ezx78s.jpg" style={{ width: "450px", height: "500px" }} className="xss-w-full rounded-10" alt="about img not found" />
                                            <img className="bd-about-shape bd-about-shape-6 hero-1-dot" src={shape6} alt="shape img not found" />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-8 pl-30 seventh-soft-section2">
                                        <div className="bd-about-text bd-about-text-four mb-40 z-index">
                                            <div className="bd-section-title-wrapper">

                                                <h2 className="bd-section-title mb-25  md-pr-50 seventh-soft-tittle">
                                                    Exceptional team, cutting-edge technical tools, and unmatched project management expertise – all under one roof.
                                                </h2>
                                                <p className="">
                                                    If you're seeking a team of highly skilled professionals in Canada to assist you in shaping a seamless and prosperous software development project, your search culminates here. Our team of experts is unrivaled in their proficiency. With our cutting-edge tech tools and expert management, we proudly stand as the top pick in the industry.
                                                </p>
                                                <p>
                                                    From project inception to the final touch, we oversee every detail, meticulously managing your project within the set timeframe and budget. Get in touch with us today to uncover how we can support you in accomplishing your project with the utmost precision and success!
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

      {/* Banner Section */}
      <section
        className="bd-video-area pt-135 pb-130"
        style={{ backgroundColor: "#5c0385" }}
      >
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row responsive-banner">
            <div className="col-lg-12 first_section">
              <div className="bd-video-text">
                <h2 className="bd-video-title first-para-resp">
                  Looking for a website(web) development expert?
                </h2>
                <p className="contact_section second-para-resp">
                  You’re here at the right moment.
                </p>
                <p className="contact_subtext">
                  Outstanding developers, Let’s kickstart the project.
                </p>
              </div>
            </div>
            <div className="col-lg-12 second_section">
              <div className="contact-stuff pt-30">
                <div className="contact_icon icon_respon pt-15 pl-50">
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ fontSize: "30px" }}
                  />
                </div>
                <div className="contact_txt ">
                  <p className="contact-sub-text">CALL US NOW</p>
                  <a
                    href="tel:(+91)720-288-2277"
                    style={{ fontSize: "17px" }}
                    className="mbb-5 pb-20"
                  >
                    (+91) 720-288-2277
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default WebsiteDevelopment;
