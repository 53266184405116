import React, { useState, useEffect } from 'react'
import Header1 from '../../Components/seperate Components/Header1'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import Choose1 from '../../Components/seperate Components/Choose1'
import serviceBanner from '../../assets/service/BannerSection.jpg'
import slider1 from "../../assets/slider/slider-shape-1.png";
import Footer from '../../Components/Footer';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import axios from 'axios';
import MetaTags from 'react-meta-tags';

const GapAnalysis = () => {

    const [description, setDescription] = useState([])

    useEffect(() => {
        const fetch = async () => {
            try {
                const { data } = await axios.get("https://mars.zithas.com/api/currentopening/wordpress-developer");
                setDescription(data);
            }
            catch (err) {
                console.error(err);
            }
        }
        fetch();
    }, [])


    return (
        <div>
            <MetaTags>
                <title>Business Software Audit Services in India - Expert Solutions</title>
                <meta
                    name="description"
                    content="Elevate your business with expert software audit services in India. Unlock efficiency and reliability with our tailored solutions."
                />
                <meta
                    name="keywords"
                    content="business software audit,business software audit india"
                />
            </MetaTags>

            <Header1 />


            {/* Banner section */}
            <div className="bd-page-title-area bd-page-title-bg pt-220 pb-155" style={{ height: "380px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="bd-page-title text-center z-index" style={{ bottom: "45px" }}>
                                <h2 className="breadcrumb-title" style={{ fontSize: '50px', color: "#4a026c" }}>Business Software Audit</h2>
                                <p style={{ color: "black" }}>Identifying opportunity for growth and helping you bring your business closer to its potential.</p>
                                <div className="breadcrumb-menu">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* First Section */}
            <Choose1 />

            {/* Second Section */}
            <section className="bd-choose-area fix">
                <div className="container" style={{ maxWidth: "1300px" }}>
                    <div className="bd-choose-shape">
                        <div className="row " >
                            <div className="col-xl-6 col-lg-6 order-2 order-lg-1 first_resp_audit">
                                {/* <div className="ablog ablog-4 mb-60"> */}
                                <div className="bd-choose-text mb-30 z-index">
                                    <div className="bd-section-title-wrapper mb-35">
                                        <h2 className="bd-section-title title_resp mb-15 ml-15"><span style={{ color: "#ffbf1e" }}>Business Software Audit</span> Is Not Just a Process !</h2>
                                        <p className="ml-15 para_one">Business Software Audit is the practice of identifying and analyzing the software and process gap between your business requirements and the current capabilities of your current systems that you use. Business Software Audit can be used to identify missing capabilities, determine which areas you need to focus on, and how you can use technology investments to improve service</p>
                                        <h4 className="ml-15 para_one" >Business Software Audit is not just a process, it's a way of thinking about a process !</h4>
                                        <p className="ml-15 para_one">Business Software Audit Service can help your business identify gaps in software you use, it can be data flow between different software in business. It helps you identify those gaps. There are many factors that can influence the performance and quality of software, including factors such as.</p>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                            <div className="col-xl-6 col-lg-6 order-1 order-lg-2 mt-0 second_resp_audit" >
                                <div className="bd-choose-img mb-30 z-index mt-125">
                                    <div className="bd-choose-info-wrapper">
                                        <div className="container ">
                                            <div className="row mb-50">
                                                <div className="col cell_resp mr-50">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15">
                                                            <i>
                                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440198/website/icons/Data-Collection_nchwqi.png" />
                                                            </i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2 className="bd-choose-info-title mb-15" style={{ color: "#5e5858" }}>Data collection from multiple sources</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15" style={{ color: "#5e5858" }}>
                                                            <i>
                                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440198/website/icons/Data-Analysis_c2nxvo.png" />
                                                            </i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2 className="bd-choose-info-title mb-15" style={{ color: "#5e5858" }}>Data Modelling and Analytics</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container ">
                                            <div className="row second_flex ">
                                                <div className="col cell_resp cell_padd_resp mr-50">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15" style={{ color: "#5e5858" }}>
                                                            <i>
                                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440198/website/icons/Buisness-Planning_asrnre.png" />
                                                            </i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2 className="bd-choose-info-title mb-15" style={{ color: "#5e5858" }}>Improving Your Business Planning</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15" >
                                                            <i>
                                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440198/website/icons/automated-process-64_f0p7gm.png" />
                                                            </i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2 className="bd-choose-info-title mb-15" style={{ color: "#5e5858" }}>Automised work process</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Thrid section */}
            <div className="container mt-0" style={{ maxWidth: "1250px" }}>
            <h4 className="bd-slider-fact-title text-heading pb-30" style={{ fontSize: "26px" }}>Process We Follow</h4>
                <div className="row three_section_resp">
                    <div className="col-sm" style={{ color: "black" }}>
                        <div className="top-border-line"></div>
                        <div className="bd-about-text-five-fact" data-animation="fadeInUp" data-delay="1.1s">
                            <div className="section-number pb-30">01</div>
                            <h4 className="bd-slider-fact-title text-heading pb-30" style={{ fontSize: "26px" }}>Business Process and Rules</h4>
                            <span className="bd-slider-fact-subtitle" style={{ color: "#5e5858" }}>Companies need a balance between rules and business processes. Too much of one will compromise the other.</span>

                        </div>
                    </div>
                    <div className="col-sm ml-40 block_two " style={{ color: "black" }}>
                        <div className="top-border-line"></div>
                        <div className="bd-about-text-five-fact" data-animation="fadeInUp" data-delay="1.1s">
                            <div className="section-number pb-30">02</div>
                            <h4 className="bd-slider-fact-title text-heading pb-30" style={{ fontSize: "26px" }}>Software architecture used in systems</h4>
                            <span className="bd-slider-fact-subtitle" style={{ color: "#5e5858" }}>Most software systems are designed with a single concern – that specific software for specific area.</span>

                        </div>
                    </div>
                    <div className="col-sm ml-40 block_two" style={{ color: "black" }}>
                        <div className="top-border-line"></div>
                        <div className="bd-about-text-five-fact" data-animation="fadeInUp" data-delay="1.1s">
                            <div className="section-number pb-30">03</div>
                            <h4 className="bd-slider-fact-title text-heading pb-30" style={{ fontSize: "26px" }}>Data access mechanisms and structures</h4>
                            <span className="bd-slider-fact-subtitle" style={{ color: "#5e5858" }}>Data access mechanism is a way to allow you to access data of software internally between two different software.</span>

                        </div>
                    </div>
                </div>
            </div>


            {/* Fourth Section */}
            <section className="bd-slider-area pt-80" style={{ height: "700px" }}>
                <div className="bd-slider-actives" style={{ height: "700px" }}>
                    <div className="swiper-wrappers" style={{ height: "700px" }}>
                        <div className="bd-single-slider bd-slider-height bd-single-slider-overlay-invisible d-flex align-items-center swiper-slides">
                            <div className="bd-slide-bg"><img src={serviceBanner} style={{ height: "700px", width: "100%" }} /></div>
                            <div className="bd-slide-shape"><img src={slider1} style={{ height: "700px", width: "1800px", backgroundColor: "rgba(0,0,0,0.7)" }} alt="img not found" /></div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="bd-slider-two z-index mb-200 pb-20">
                                            <h1 className="bd-slider-title-two mb-25 wow fadeInUp" data-wow-delay=".2s" style={{ color: "#c89fdb" }}>New Ways To Run Business</h1>
                                            <h4 className="bd-slider-subtitle-two mb-45 wow fadeInUp" style={{}} data-wow-delay=".4s">Our consulting services will help you to understand your business better and make the most of it.</h4>
                                            <div className="bd-slider-btn  wow fadeInUp" data-wow-delay=".6s">
                                                <Link to={"/about-us"} className="theme-btn theme-btn-rounded">About US</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* Fifth Section */}
            <section className="bd-slider-area fix">
                <div className="bd-slider-actives">
                    <div className="swiper-wrappers">
                        <div className="bd-single-slider gray-bg bd-slider-height w-full position-relative pt-80 pb-80" style={{ minHeight: "0px" }}>
                            <div className="container" style={{ maxWidth: "1270px" }}>
                                <div className="row align-items-center">
                                    <div className="col-xl-7">
                                        <div className="bd-slider-six">
                                            <h1 className="bd-slider-six-title  sixth-title mb-15 wow fadeInUp" data-wow-delay=".2s">Power Of <span style={{ color: "#ffbf1e" }}>Business Software Audit</span> In Your Business</h1>
                                            <p className="mb-20 para_resp_one" style={{ fontSize: "18px" }}>Business Software Audit helps uncover and remove waste, inefficiencies, risk, unmet expectations and opportunities. The objective of Business Software Audit is to achieve a sustainable level of innovation by identifying the critical gaps between the current state of systems, process and the company’s vision for achieving that state.</p>
                                            <p className='para_resp_one' style={{ width: "95%", fontSize: "18px" }}>The results from a Business Software Audit can also be used as a part of a data-driven decision-making process. There are many ways to leverage this information including in terms of ROI and risk mitigation decisions. The most common uses in terms of R&D include: Analytics, Strategy & Planning (R&D), Data Analysis and Decision Making.</p>

                                        </div>
                                    </div>
                                    <div className="col-xl-5">
                                        <div className="bd-slider-six-img">
                                            <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659413014/website/business-audit/business-audit-sec-2_asnjrm.jpg" className="rounded-10" alt="img not found" />
                                            <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659587681/website/business-audit/slider-shape-3_rtsny4.png" className="bd-slider-six-img-shape1" alt="img not found" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Sixth Section */}
            <section className="bd-video-area pt-135 pb-130" style={{ backgroundColor: "#5c0385" }}>
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row responsive-banner">
                        <div className="col-lg-12 first_section">
                            <div className="bd-video-text">
                                <h2 className="bd-video-title first-para-resp" >Looking for a Business Software Audit Expert?</h2>
                                <p className="contact_section second-para-resp">You're at the right place. The right time</p>
                                <p className="contact_subtext">Surprisingly great experts, Let's get started right now!</p>
                            </div>
                        </div>
                        <div className="col-lg-12 second_section">
                            <div className="contact-stuff pt-30">
                                <div className="contact_icon icon_respon pt-15 pl-50">
                                    <FontAwesomeIcon icon={faPhone} style={{ fontSize: "30px" }} />
                                </div>
                                <div className="contact_txt ">
                                    <p className="contact-sub-text">CALL US NOW</p>
                                    <a href="tel:(+91)720-288-2277" style={{ fontSize: "17px" }} className="mbb-5 pb-20">(+91) 720-288-2277</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Footer Section */}
            <Footer />


        </div>
    )
}

export default GapAnalysis