import React, { useEffect, useState } from 'react'
import Header1 from '../../Components/seperate Components/Header1'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faPhone } from '@fortawesome/free-solid-svg-icons';
import '../../Styles/About.css'
import '../../Styles/Redirecting Styles/FooterStyle/Career.css'
import Footer from '../../Components/Footer';
import { Link, Outlet, useParams } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll';
import img1 from "../../assets/service/output-onlinepngtools3.png"
import img2 from "../../assets/service/happiness .png"
import img3 from "../../assets/service/networking.png"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios"
import { Helmet } from 'react-helmet';

const Career = () => {

    const toggleContact = () => {
        scroll.scrollToTop(0, 0);
    }


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    var setting1 = {
        dots: true,
        infinite: true,
        speed: 300,
        arrow: true,
        autoplay: false,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    autoplay: true,
                    autoplaySpeed: 2000,
                    speed: 300,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    var setting2 = {
        dots: true,
        infinite: true,
        speed: 300,
        autoplay: false,
        autoplaySpeed: 3000,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    autoplay: true,
                    autoplaySpeed: 2000,
                    speed: 300,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const { id } = useParams();
    const [opening, SetOpening] = useState([]);
    const [isLoading, setLoading] = useState(true)

    useEffect(() => {
        const fetch = async () => {
            try {
                const { data } = await axios.get("https://mars.zithas.com/api/currentopening");
                SetOpening(data);
                setLoading(false)
            } catch (err) {
                console.error(err);
            }
        };
        fetch();
    }, []);

    const [gallery, SetGallery] = useState([]);

    useEffect(() => {
        const fetch = async () => {
            try {
                const { data } = await axios.get("https://mars.zithas.com/api/gallery");
                SetGallery(data);
                setLoading(false);
            } catch (err) {
                console.error(err);
            }
        };
        fetch();
    }, []);


    const [empTestimonial, SetEmpTestimonial] = useState([]);

    useEffect(() => {
        const fetch = async () => {
            try {
                const { data } = await axios.get("https://mars.zithas.com/api/empTestimonial");
                SetEmpTestimonial(data);
                setLoading(false);
            } catch (err) {
                console.error(err);
            }
        };
        fetch();
    }, []);

    return (
        <div>
            <Helmet>
                <title>Career at Zithas- Hiring Software, Mobile and Web Developers</title>
                <meta name='description' content="Explore exciting career opportunities at Zithas Technologies. Join our team and be a part of our innovative projects and success story." />
            </Helmet>

            <Header1 />

            {/* Banner section */}
            <div className="bd-page-title-area bd-page-title-bg_career pt-175 pb-155 main-heading" style={{ height: "380px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="bd-page-title text-center z-index">
                                <h2 className="breadcrumb-title" style={{ fontSize: '50px', color: "#4a026c" }}>Career at Zithas</h2>
                                <div className="breadcrumb-menu">
                                    <nav className="breadcrumb-trail breadcrumbs">
                                        <p style={{ color: "black" }}>How We're Helping You Achieve Your Career Goals!</p>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* First Section */}
            <section className="bd-about-area">
                <div className="bd-about-shape-box pt-80">
                    <div className="container" style={{ maxWidth: "1270px" }}>
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-lg-8 pt-20 pb-20 first-responsive" >
                                <div className="bd-about-img " style={{ height: "100%" }}>
                                    <div className="side_video1" style={{ height: "100%" }}>
                                        <iframe src="https://www.youtube-nocookie.com/embed/ZXc5PdSaxa4?autoplay=0&rel=0"
                                            allowFullScreen="allowFullScreen" className="video-reponsive" frameBorder="0" style={{ width: "100%", height: "100%" }} title="Everytime You Go Away - Paul Young" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-8">
                                <div className="bd-about-text z-index pt-35">
                                    <div className="bd-section-title-wrapper">
                                        <h2 className="bd-section-title mb-25 md-pr-50">Get the Best Out of Your Career</h2>
                                        <p className="">Your career advances while you work at Zithas.We are always looking for ways to make the workplace better so that it is the best and most fun place for people to work.With our cutting-edge technologies and innovative ideas, we can help you achieve your career goals</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="text-center pt-60 mt-10 mb-20">
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="bd-service" style={{ backgroundColor: "var(--clr-bg-gray)" }}   >
                        <p>
                            <a target="_blank" href='https://www.ambitionbox.com/overview/zithas-technologies-overview?utm_source=employer-dashboard&utm_campaign=zithas-technologies&utm_medium=badges'><img src='https://employer.ambitionbox.com/api/badge/722317?badge-type=ratings-detailed' alt="image" /></a>
                        </p>
                    </div>
                </div>
            </section>

            {/* second Section */}
            <section className="bd-service-area pt-80 ">
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row">
                        <div className="col-12">
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 career-responsive">
                            <div className="bd-service " style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div style={{ textAlign: "center", paddingBottom: "30px" }}>
                                    <img src={img1}></img>
                                </div>

                                <p>We provide outstanding career prospects with competitive pay and performance-based incentives that allow you to advance both personally and professionally. We have an amazing office environment, making it a terrific place to work.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 career-responsive">
                            <div className="bd-service" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div style={{ textAlign: "center", paddingBottom: "30px" }}>
                                    <img src={img2}></img>
                                </div>
                                <p>Our innovative staff will work with you to develop your leadership abilities as well as your ability to think creatively when approaching and solving problems. We take tremendous pleasure in celebrating our successes, our colleagues' birthdays, meals, and other occasions.
                                </p>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 career-responsive">
                            <div className="bd-service " style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div style={{ textAlign: "center", paddingBottom: "30px" }}>
                                    <img src={img3}></img>
                                </div>
                                <p>We think that being unique allows you to develop personally and contributes to a more diversified and fascinating society. To keep our staff members energised and alert throughout the day, we offer complimentary refreshments including tea or coffee.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Third Section */}
            <section className="bd-pricing-area pt-80 pb-80">
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row">
                        <div className="col-12">
                            <div className="bd-section-title-wrapper mb-50 z-index text-center">
                                <h2 className="bd-section-title mb-25">Current Openings</h2>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                            <div className="row">
                                {opening.map((jobs) => {
                                    if (jobs.feautured == 1) {
                                        return (
                                            <div className="col-lg-4 col-md-6">
                                                <div className="bd-pricing mb-30">
                                                    <div className="bd-pricing-title-wrapper text-center mb-65">
                                                        <h4 className="bd-pricing-subtitle mb-15">{jobs.position_name}</h4>
                                                    </div>
                                                    <ul className="mb-80">
                                                        <li className="open-text"><i><FontAwesomeIcon icon={faAngleRight} /></i><span style={{ color: "black", fontWeight: "450" }}>Vacancies  </span> {jobs.vacancies} </li>
                                                        <li className="open-text "><i><FontAwesomeIcon icon={faAngleRight} /></i><span className="mb-10" style={{ color: "black", fontWeight: "450", }}>Preferred Skills</span>  <span > {jobs.preffered_skills} </span></li>
                                                    </ul>
                                                    <div className="bd-pricing-btn">
                                                        <Link to={`/current-opening/${jobs.permalink}`} onClick={toggleContact} className="theme-btn">Apply Now</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                            <div className="bd-header-btn2 responsive-button">
                                <Link to={"/current-openings"}>
                                    <button className="get-start" >View More</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Fourth Section */}
            <section className="bd-team-area pb-80 ">
                <div className="container" style={{ maxWidth: "1300px" }}>
                    <h2 className="bd-section-title pb-10" style={{ textAlign: "center" }}>Gallery</h2>
                    <div className="bd-team-active swiper-container pl-50" >
                        <div className="swiper-wrapper" style={{ width: "95%" }}>

                            <Slider {...setting1}>

                                {gallery.slice(0, 6).map((img) => {
                                    return (
                                        <div className="swiper-slide">
                                            <div className="bd-team-four mb-30" style={{ marginRight: "30px" }}>
                                                <img src={img.link} style={{ height: "300px" }} alt="porfolio not found" />
                                            </div>
                                        </div>
                                    )
                                })}


                            </Slider>

                        </div>
                    </div>
                    <div className="bd-header-btn2 responsive-button">
                        <Link to={"/gallery"}>
                            <button className="get-start" >View All</button>
                        </Link>
                    </div>
                </div>
            </section>

            {/* Fifth Section */}
            <section className="bd-team-area pb-80 ">
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="bd-team-active swiper-container" style={{ width: "97%" }}>
                        <h2 className="bd-section-title pb-10" style={{ textAlign: "center" }}>Listen What Our Employees Says</h2>
                        <div className="swiper-wrapper pl-30" >
                            <Slider {...setting2}>
                                {empTestimonial.slice(0, 3).map((video) => {
                                    return (
                                        <div className="swiper-slide">
                                            <div className="bd-team-four mb-30" style={{ marginRight: "30px" }}>
                                                <div className="testimonial_video">

                                                    <iframe src={video.video_link}
                                                        allowFullScreen="allowFullScreen" className="video-reponsive" frameBorder="0" width="550" height="300" title="Everytime You Go Away - Paul Young" />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </Slider>
                        </div>

                    </div>
                    <div className="bd-header-btn2 responsive-button">
                        <Link to={"/employee-testimonial"}>
                            <button className="get-start" >View All</button>
                        </Link>
                    </div>
                </div>
            </section>

            <section className="bd-video-area pt-135 pb-130" style={{ backgroundColor: "#5c0385" }}>
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row responsive-banner">
                        <div className="col-lg-12 first_section">
                            <div className="bd-video-text">
                                <h2 className="bd-video-title first-para-resp" >Looking for Growth in Career ?</h2>
                                <p className="contact_section second-para-resp">You're at the right place. The right time</p>
                                <p className="contact_subtext">Surprisingly great Career, Let's get started right now !</p>
                            </div>
                        </div>
                        <div className="col-lg-12 second_section">
                            <div className="contact-stuff pt-30">
                                <div className="contact_icon icon_respon pt-15 pl-50">
                                    <FontAwesomeIcon icon={faPhone} style={{ fontSize: "30px" }} />
                                </div>
                                <div className="contact_txt ">
                                    <p className="contact-sub-text">CALL US NOW</p>
                                    <a href="tel:(+91)720-288-2277" style={{ fontSize: "17px" }} className="mbb-5 pb-20">(+91) 720-388-2277</a>
                                </div>
                            </div>
                            {/* <Link to={"/zithas/contact-us"} onClick={toggleContact}>
                    <button className="touch-btn1 mt-20">Get Started</button>
                    </Link> */}
                        </div>

                    </div>
                </div>
            </section>



            <Footer />

            <Outlet />
        </div>



    )
}

export default Career