import { faPhone } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect } from 'react'
import "../../Styles/seperate Comp Css/ServiceBanner.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { animateScroll as scroll } from 'react-scroll';


export const ServiceBanner = () => {

  const toggleContact = () => {
    scroll.scrollToTop(0, 0);
  }



  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <section className="bd-video-area pt-135 pb-130" style={{ backgroundColor: "#5c0385" }}>
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row responsive-banner">
            <div className="col-lg-12 first_section">
              <div className="bd-video-text">
                <h2 className="bd-video-title first-para-resp" >Looking for Gap Analyst expert ?</h2>
                <p className="contact_section second-para-resp">You're at the right place. The right time</p>
                <p className="contact_subtext">Surprisingly great analyst, Let's get started right now !</p>
              </div>
            </div>
            <div className="col-lg-12 second_section">
              <div className="contact-stuff pt-30">
                <div className="contact_icon icon_respon pt-15 pl-50">
                  <FontAwesomeIcon icon={faPhone} style={{ fontSize: "30px" }} />
                </div>
                <div className="contact_txt ">
                  <p className="contact-sub-text">CALL US NOW</p>
                  <a href="tel:(+91)720-288-2277" style={{ fontSize: "17px" }} className="mbb-5 pb-20">(+91) 720-288-2277</a>
                </div>
              </div>
              {/* <Link to={"/contact-us"} onClick={toggleContact}>
                    <button className="touch-btn1 mt-20">Get Started</button>
                    </Link> */}
            </div>

          </div>
        </div>
      </section>
    </div>
  )
}
