import React, { useState } from 'react'
import Shape4 from "../assets/brand/about-shape-4.png"
import About1 from "../assets/shape/about-shape-1.png"
import About2 from "../assets/brand/about-shape-2.png"
import About5 from "../assets/brand/about-shape-5.png"
import Choose5 from "../assets/brand/choose-shape-2.png"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../Styles/About.css'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import pdf1 from "../assets/company-pdf/Company-Profile.pdf"
import "../Styles/seperate Comp Css/Modal.css"
import axios from 'axios';
import Spinner from './seperate Components/Spinner'

const About = () => {



    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [isLoading, setLoading] = useState(false);


    const onHandleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("mobile", mobile);

        try {
            const response = await axios({
                method: "post",
                url: "https://mars.zithas.com/api/portfolio",
                data: formData,
                headers: { "Content-Type": "multipart/form-data", },
            })
            
                .then((response) => {
                    axios({
                        url: 'https://mars.zithas.com/api/getindiapdf', //your url
                        method: 'GET',
                        responseType: 'blob', // important
                    }).then((response) => {
                        alert("Plaese click ok button to Download Company Portfolio")
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'company-porfolio.pdf'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    });
                });

            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }



    return (
        <div>

            <section className="bd-about-area ">
                <div className="bd-about-shape-box about-main-responsive pt-100 ">
                    <img className="bd-about-shape bd-about-shape-2" src={Shape4} alt="shape img not found" />
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-lg-8 first-section-about">
                                <div className="bd-about-img mb-30">
                                    <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659349937/website/home/home-inspiration-a_vxkvj5.jpg" style={{ width: "365px", height: "500px" }} className="xss-w-full rounded-10" alt="about img not found" />
                                    <img className="bd-about-seconday-img rounded-10" src="https://res.cloudinary.com/ztpl-images/image/upload/v1659414332/website/home/home-inspiration-b.2_zl2lma.jpg" style={{ width: "380px", height: "550px" }} alt="about img not found" />
                                    <img className="bd-about-shape bd-about-shape-3" src={About1} alt="shape img not found" />
                                    <img className="bd-about-shape bd-about-shape-4 exta-shape" src={About2} alt="shape img not found" />
                                    <img className="bd-about-shape bd-about-shape-5" src={About5} alt="shape img not found" />
                                    <img className="bd-about-shape bd-about-shape-6" src={Choose5} alt="shape img not found" />
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-8">
                                <div className="bd-about-text mb-30 pt-30 z-index">
                                    <div className="bd-section-title-wrapper">
                                        <h5 className="bd-section-subtitle mb-15">We aim to</h5>
                                        <h2 className="bd-section-title mb-25 md-pr-50" style={{ fontSize: '52px' }}>Connect Inspiration to <span style={{ color: "#ffbf1e" }}>Excellence</span></h2>
                                        <p className="mb-20">We are here to help you automize your business. We know that when you work with automation, in order to achieve the optimal levels of effectiveness and efficiency, you need a system that is easy to use and intuitively appealing. You need a system that is well-designed.</p>
                                        <p className="mb-20">In short, you want a system that will take care of all the important details for you and does exactly what it’s supposed to do. It will offer an incredible user experience and allow you to focus on your business goals.</p>
                                        <span className="bd-about-text-btn">
                                            <Link to={"/about-us"} className="theme-btn mr-15 three-button-respon" style={{ paddingRight: "25px", paddingLeft: "25px", }}>Know more</Link>
                                        </span>
                                        <span className="bd-about-text-btn">
                                            <a href={pdf1} download className="theme-btn mr-15 three-button-respon" style={{ paddingRight: "25px", paddingLeft: "25px", backgroundColor: "rgb(195, 41, 72)" }}><i style={{ lineHeight: "4", fontStyle: "normal", }} ><FontAwesomeIcon icon={faDownload} style={{ fontSize: "20px", paddingRight: "10px" }} />Company Profile</i></a>
                                        </span>
                                        <span className="bd-about-text-btn">
                                            <button className="theme-btn " data-bs-toggle="modal" data-bs-target="#portfolioModal" style={{ paddingRight: "25px", paddingLeft: "25px", backgroundColor: "rgb(195, 41, 72)" }}>
                                                <i style={{ lineHeight: "4", fontStyle: "normal" }}>
                                                    <FontAwesomeIcon icon={faDownload} style={{ fontSize: "20px", paddingRight: "10px" }} />Our Portfolio</i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* modal popup */}
                    <div className="modal fade" id="portfolioModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content" style={{ width: "120%" }}>
                                <div className="modal-header" style={{ border: "none" }}>
                                    <h5 className="modal-title" id="exampleModalLabel">Fill out the Details!</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ marginLeft: "50px" }}></button>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div style={{ width: "100%", paddingRight: "25px" }}>
                                        {isLoading && (<Spinner />)}
                                        <form onSubmit={onHandleSubmit}>
                                            <div className="row" style={{ paddingTop: "23px" }}>
                                                <div className="bd-contact-field mb-20">
                                                    <input className="blog-form" type="text" onChange={(e) => setName(e.target.value)} placeholder="Name" name="user_name" required />
                                                </div>
                                                <div className="bd-contact-field mb-20">
                                                    <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} name="user_email" required />
                                                </div>
                                                <div className="bd-contact-field mb-20 ">
                                                    <input type="text" maxLength={10} placeholder="Contact No"
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9.]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        onChange={(e) => setMobile(e.target.value)}
                                                        name="user_contact" required />
                                                </div>
                                                <div className="col-12 mb-20">
                                                    <div className="bd-contact-field">
                                                        <button type="submit" className="theme-btn" >Download</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="hello">
                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659453801/website/home/portfolio-side_y0lpqc.jpg" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}

export default About