import React from 'react'
import '../Styles/SliderArea.css'
import '../assets/css/swiper-bundle.css'
import '../assets/css/custom-animation.css'
import '../assets/css/animate.min.css'
import { Link } from "react-router-dom"


const SliderArea = () => {

    return (
        <div>
            <section className="bd-slider-area">
                <div className="bd-slider-active swiper-container">
                    <div >
                        <div className="bd-single-slider bd-single-slider-overlay bd-slider-height d-flex align-items-center swiper-slide">
                            <div className="bd-slide-bg" style={{ opacity: "0.3" }}>
                                <video src="https://res.cloudinary.com/ztpl-images/video/upload/q_auto,f_auto/v1659439415/website/home/zithas-intro-10500_ghzqha.mp4" autoPlay loop playsInline muted></video>
                                {/* <video src={video} autoPlay loop playsInline muted></video> */}
                            </div>
                            <div className="container">
                                <div className="row cont-title">
                                    <div className="col-12 ">
                                        <div className="bd-slider z-index text-center pt-35">
                                            <h1 className="bd-slider-title mb-20" data-animation="fadeInUp" data-delay=".3s"> Business Automation Expert</h1>
                                            <p className="mb-40" data-animation="fadeInUp" data-delay=".6s">Connecting business owners with their KPIs for improved performance and growth. </p>
                                            <div className="bd-slider-btn" data-animation="fadeInUp" data-delay=".9s">
                                                <Link to={"/about-us"} className="theme-btn">Explore Us</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SliderArea