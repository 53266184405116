import React, { useState } from 'react'
import Header1 from '../../Components/seperate Components/Header1'
import Shape2 from "../../assets/brand/choose-shape-2.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePen, faPlay, faPhone } from '@fortawesome/free-solid-svg-icons'
import Footer from '../../Components/Footer';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss'
import "../../Styles/Redirecting Styles/FooterStyle/Hosting.css"
import { Helmet } from 'react-helmet';

const HostingServices = () => {

    const [isOpen, setOpen] = useState(false)

    return (
        <div>
            <Helmet>
                <title>Best Hosting Service Provider in India</title>
                <meta name='description' content="Are you looking for the best hosting service provider in India? Uncover the top Indian hosting companies and choose the best hosting for your website." />
                <meta name='keywords' content='hosting services,hosting services india,website hosting india' />
            </Helmet>

            <Header1 />

            <div className="bd-page-title-area bd-page-title-bg2 pt-220 pb-155" style={{ height: "380px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="bd-page-title text-center z-index" style={{ bottom: "45px" }}>
                                <h2 className="breadcrumb-title" style={{ fontSize: '50px' }}>Hosting Services</h2>
                                <div className="breadcrumb-menu">
                                    <nav className="breadcrumb-trail breadcrumbs">
                                        <p style={{ color: "black" }}>We provide a wide range of features to help users manage their websites.</p>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* First section */}
            <section className="bd-choose-area fix pt-80 pb-80">
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="bd-choose-shape">
                        <div className="row respon_host">

                            <div className="col-xl-6 col-lg-6 order-2 order-lg-1 pr-20">
                                <div className="bd-choose-img text-end top-resp z-index">
                                    <div className="row align-items-center custom-mar-15">
                                        <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                                            <div className="bd-choose-img-inner mb-15 bd-choose-img-inner-1">
                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659374726/website/hosting/hosting-sec-1.2_ig2wws.jpg" className="resp-img bordertop-img1" alt="img not found" />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                                            <div className="bd-choose-img-inner mb-15">
                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659374726/website/hosting/hosting-sec-1.1_q8dhjj.jpg" className="resp-img bordertop-img2" alt="img not found" />
                                            </div>
                                            <div className="bd-choose-img-inner mb-15">
                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659374726/website/hosting/hosting-sec-1.3_mmk9wc.jpg" className="resp-img bordertop-img3" alt="img not found" />
                                            </div>
                                        </div>
                                    </div>
                                    <img
                                        className="bd-choose-shape bd-choose-shape-2"
                                        src={Shape2}
                                        alt="shape img not found"
                                    />
                                    {/* <div className="bd-choose-video-popup">
                                    <a href="https://www.youtube.com/watch?v=jdkWIdJobSA" className="play_btn popup-video"><i className="fas fa-play"></i></a>
                  </div> */}
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 order-1 order-lg-2">
                                <div className="bd-choose-text  z-index">
                                    <h2 className="bd-section-title mb-15" style={{ fontSize: "46px" }}>
                                        <span style={{ color: "#ffbf1e" }}> Hosting Services </span> That Perfectly Fit Your Business!
                                    </h2>
                                    <div className="bd-section-title-wrapper">
                                    </div>
                                    <p
                                        className="mb-20"
                                        style={{ width: "95%", fontSize: "18px" }}
                                    >Hosting service provides a wide range of features to help users manage their websites. These features include site tuning, security, and backup features. A hosting service can help you save time while you are working on your website. For example, you could use a hosting service to set up your website so that you can have a single point of contact for all your customer support needs. This way, you can save time and keep your customer service options open.
                                    </p>
                                    <p className="mb-20" style={{ fontSize: "18px" }}>Hosting service can be a great way to increase your website's visibility, and provide more access to your content for potential customers. This can be helpful if you're looking to attract attention from online marketers, or if you want to create a more professional online presence for your business. We here at Zithas render the hosting services mentioned below.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ModalVideo autoplay isOpen={isOpen} channel='youtube' videoId='L61p2uyiMSo' onClose={() => setOpen(false)}>
                </ModalVideo>
                <button style={{ display: "none" }} className="btn btn touch_pop_vid first_pop_hosting" onClick={() => setOpen(true)}>
                    <FontAwesomeIcon icon={faPlay} className="play_bt_vid play_transition" />
                </button>
            </section>

            <section className="bd-service-area pb-80 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 pt-20">
                            <div className="bd-section-title-wrapper mb-50">
                                <h2 className="bd-section-title" style={{ fontSize: "46px" }}><span style={{ color: "#ffbf1e" }}>Hosting Services</span> : Choose the right service for your needs. </h2>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i>
                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440581/website/icons/download-from-cloud-64_fewqme.png" />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20"><a>Cloud Hosting</a></h4>
                                <p>Separates each server to ensure that any potential stress on one does not impact the performance of another.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i>
                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440581/website/icons/server-shutdown-64_eacgw5.png" />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20"><a>Dedicated Server</a></h4>
                                <p>Full root access is provided by the service, enabling server customization and the installation of other software.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i>
                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440581/website/icons/vps-64_cr3pca.png" />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20"><a>VPS Hosting</a></h4>
                                <p>Automatic backups and manual live snapshots make guarantee that your website can be quickly restored.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i>
                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440581/website/icons/web-hosting-64_gd9bxw.png" />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20"><a>Web Hosting</a></h4>
                                <p>High-level server tuning produces blazing-fast performance and rapid loading times for all websites.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i >
                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440581/website/icons/mail-64_hrvu3p.png" />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20"><a>Email Hosting</a></h4>
                                <p>Service that operates email servers. Its service differs from the typical free webmail sites such as Yahoo and Google.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i >
                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440581/website/icons/security-ssl-64_zklinw.png" />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20"><a>SSL Certificates</a></h4>
                                <p>By obtaining an SSL Certificate, you can protect your website, increase client trust, and boost its SEO ranking.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i >
                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440582/website/icons/web-shield-64_wmivpn.png" />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20"><a>Site Security & BackUp</a></h4>
                                <p>Site protection against malware, viruses, and threats from hackers and hackers. Data loss and damage.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i >
                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440582/website/icons/wordpress-64_hhc6v7.png" />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20"><a>WordPress Hosting</a></h4>
                                <p>With a quicker loading time and an SEO-ready setup, you can improve your search engine rating.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* CTA */}
            <section className="bd-video-area pt-135 pb-130" style={{ backgroundColor: "#5c0385" }}>
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row responsive-banner">
                        <div className="col-lg-12 first_section">
                            <div className="bd-video-text">
                                <h2 className="bd-video-title first-para-resp" >Looking for Hosting Service Expert?</h2>
                                <p className="contact_section second-para-resp">You're at the right place. The right time</p>
                                <p className="contact_subtext">Surprisingly great Hosting Service, Let's get started right now !</p>
                            </div>
                        </div>
                        <div className="col-lg-12 second_section">
                            <div className="contact-stuff pt-30">
                                <div className="contact_icon icon_respon pt-15 pl-50">
                                    <FontAwesomeIcon icon={faPhone} style={{ fontSize: "30px" }} />
                                </div>
                                <div className="contact_txt ">
                                    <p className="contact-sub-text">CALL US NOW</p>
                                    <a href="tel:(+91)720-288-2277" style={{ fontSize: "17px" }} className="mbb-5 pb-20">(+91) 720-288-2277</a>
                                </div>
                            </div>
                            {/* <Link to={"/zithas/contact-us"} onClick={toggleContact}>
                    <button className="touch-btn1 mt-20">Get Started</button>
                    </Link> */}
                        </div>

                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default HostingServices

