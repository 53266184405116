import React, { useEffect } from 'react'
import About from '../About'
import BackToTop from '../BackToTop'
import Blog from '../Blog'
import { BrandArea } from '../BrandArea'
import Choose from '../Choose'
import Fact from '../Fact'
import Footer from '../Footer'
import Header from '../Header'
import Portfolio from '../Portfolio'
import Service from '../Service'
import SliderArea from '../SliderArea'
import Testimonial from '../Testimonial'
import ScrollTop from '../seperate Components/ScrollToTop';
import { animateScroll as scroll } from 'react-scroll';
import CookieConsent from 'react-cookie-consent'
import { Link, useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga'
import { createBrowserHistory } from 'history'
import axios from 'axios'
import { Helmet } from 'react-helmet'


const Main = () => {

  const navigate = useNavigate();
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    if (localStorage.getItem("load") == undefined) {
      if (res.data.country_name == "Canada") {
        window.location.assign('https://www.zithas.com/ca')
      } else if (res.data.country_name == "United Kingdom") {
        window.location.assign('https://www.zithas.co.uk')
      } else {
        navigate('/')
      }
    }
    localStorage.setItem("load", true)
  };
  useEffect(() => {
    getData();
  }, []);


  return (
    <div>
      <Helmet>
        <title>Top Web Development Company in India - Expert Design & Development</title>
        <meta
          name="description"
          content="Discover the best website development company in India specializing in website design and development. Your digital success begins here."
        />
        <meta
          name="keywords"
          content="website development company , website design and development company ,website development company india
        "
        />
      </Helmet>

      <ScrollTop />

      <Header />
      <SliderArea />
      <Service />
      <About />
      <Fact />
      <Choose />
      <Portfolio />
      <Testimonial />
      <Blog />
      <BrandArea />
      <Footer />


    </div>
  )
}

export default Main