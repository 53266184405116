import React, { useState } from 'react'
import Header1 from '../../Components/seperate Components/Header1'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPhone } from '@fortawesome/free-solid-svg-icons'
import Footer from '../../Components/Footer';
import "../../Styles/Redirecting Styles/NavbarStyles/Dedicate.css"
import Shape2 from "../../assets/brand/choose-shape-2.png";
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss'
import { Helmet } from 'react-helmet';

const DedicatedResource = () => {

    const [isOpen, setOpen] = useState(false);

    return (
        <div>
            <Helmet>
                <title>Hire Dedicated Resources in India | Expert Team for Your Projects</title>
                <meta name='description' content='Hire a dedicated resource in India for your projects. Find the right talent to scale your team. Explore our expert hiring solutions today.' />
                <meta name="keywords" content="hire a resource in india,hire a resource,hire dedicated resources,hire dedicated resources india" />
            </Helmet>
            <Header1 />

            {/* Banner section */}
            <div className="bd-page-title-area bd-page-title-bg6 pt-220 pb-155" style={{ height: "380px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="bd-page-title text-center z-index" style={{ bottom: "45px" }}>
                                <h2 className="breadcrumb-title" style={{ fontSize: '50px', color: "#4a026c" }}>Hire A Resource in India</h2>
                                <div className="breadcrumb-menu">
                                    <nav className="breadcrumb-trail breadcrumbs">
                                        <nav className="breadcrumb-trail breadcrumbs">
                                            <p style={{ color: "black" }}>Empower your team's capabilities – hire a resource in the India for unparalleled expertise and productivity.</p>
                                        </nav>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* First Section */}
            <section className="bd-about-area">
                <div className="bd-custom-container" >
                    <div className="bd-about-four-bg  pt-80 pb-0 fix">
                        <div className="bd-about-shape-box">
                            <img className="bd-about-shape bd-about-shape-1" src="assets/img/shape/about-shape-3.png" alt="shape img not found" />
                            <img className="bd-about-shape bd-about-shape-2" src="assets/img/shape/about-shape-4.png" alt="shape img not found" />
                            <div className="container" style={{ maxWidth: "1270px" }}>
                                <div className="row justify-content-center">
                                    <div className="col-xl-6 col-lg-8  first_dedicate" >

                                        <div className="bd-choose-img text-end mb-30 z-index top-resp">
                                            <div className="row align-items-center custom-mar-15">
                                                <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                                                    <div className="bd-choose-img-inner mb-15 bd-choose-img-inner-1">
                                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659372730/website/dedicate-resource/dedicate-resource-sec-1.2_ygpsiy.jpg" className="resp-img bordertop-img1" alt="img not found" />
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                                                    <div className="bd-choose-img-inner mb-15">
                                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659372730/website/dedicate-resource/dedicate-resource-sec-1.1_zht7ss.jpg" className="resp-img bordertop-img2" alt="img not found" />
                                                    </div>
                                                    <div className="bd-choose-img-inner mb-15">
                                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659372730/website/dedicate-resource/dedicate-resource-sec-1.3_jl92vq.jpg" className="resp-img bordertop-img3" alt="img not found" />
                                                    </div>
                                                </div>
                                            </div>
                                            <img
                                                className="bd-choose-shape bd-choose-shape-2"
                                                src={Shape2}
                                                alt="shape img not found" />
                                            {/* <div className="bd-choose-video-popup">
                                                <a href="https://www.youtube.com/watch?v=jdkWIdJobSA" className="play_btn popup-video"><i className="fas fa-play"></i></a>
                                                </div> */}
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-8 second_dedicated">
                                        <div className="bd-about-text bd-about-text-four z-index">
                                            <div className="bd-section-title-wrapper">
                                                <h2 className="bd-section-title md-pr-50 dedicate-first-tittle"> Save Time and Get More Done </h2>
                                                <p className="">Dedicated resources help businesses to focus and stay on track, preventing them from slipping and falling behind in their competitive marketplace. By hiring dedicated resources for your business, you can avoid some of the common pitfalls that can lead to success.</p>
                                                <p className="">Dedicated resources can also help you stay organized and efficient, leading to a faster turnaround time on projects. If you have dedicated resources in your business, you are more profitable.This also allows you to have a more successful business. Some businesses have dedicated resources in the form of employees, resources, or both. This allows businesses to have more control over their work environment and to better manage their resources. Additionally, it allows businesses to specialize in certain areas of work and to keep their resourceslocalized.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalVideo autoplay isOpen={isOpen} channel='youtube' videoId='B-4CmrDidvE' onClose={() => setOpen(false)}>
                </ModalVideo>
                <button className="btn btn touch_pop_vid first_pop_video_dedicated" onClick={() => setOpen(true)}>
                    <FontAwesomeIcon icon={faPlay} className="play_bt_vid play_transition" />
                </button>
            </section>


            {/* Second Section */}
            <section className="bd-choose-area fix">
                <div className="container" style={{ maxWidth: "1300px" }}>
                    <div className="bd-choose-shape">
                        <div className="row dedicated_first_responsive">

                            <div className="col-xl-6 col-lg-6 order-2 order-lg-1 deicated-first_sec" >
                                {/* <div className="ablog ablog-4 mb-60"> */}
                                <div className="bd-choose-text z-index">
                                    <h2 className="bd-section-title ml-15 second_sec-tittle">Focus on Vital Tasks with Help of <span style={{ color: "#ffbf1e" }}>Dedicated Team </span></h2>
                                    <div className="bd-section-title-wrapper">

                                        <p className="ml-25 dedicat-para1">When it comes to running a successful business, having dedicated resources is essential. By hiring a team of experts who are focused on your specific industry, your business can stay ahead of the competition and stay profitable.With all the information out there, it can be hard to know where to start. That's why we've got a dedicated resource team to help you make the most of your business.</p>
                                    </div>
                                    <div>

                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                            <div className="col-xl-6 col-lg-6 order-1 order-lg-2 deicated-second_sec" >
                                <div className="bd-choose-img  z-index">
                                    <div className="bd-choose-info-wrapper">
                                        <div className="container">
                                            <div className="row mb-50">
                                                <div className="col first-point-col mr-50">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15">
                                                            <i>
                                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440276/website/icons/workforce-64_heuj9y.png" />
                                                            </i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2 className="bd-choose-info-title mb-15" style={{ color: "#5e5858" }}>Diverse and Skilled Workforce</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15">
                                                            <i>
                                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440276/website/icons/sand-timer-64_kx6y3a.png" />
                                                            </i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2 className="bd-choose-info-title mb-15" style={{ color: "#5e5858" }}>Reduced Turnaround Time</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container">
                                            <div className="row ">
                                                <div className="col third-point-col mr-50">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15">
                                                            <i>
                                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440276/website/icons/report-64_kf5qew.png" />
                                                            </i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2 className="bd-choose-info-title mb-15" style={{ color: "#5e5858" }}>Guaranteed Results</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15">
                                                            <i><img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440276/website/icons/infrastructure-64_ixjfps.png" /></i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2 className="bd-choose-info-title mb-15" style={{ color: "#5e5858" }}>Established Infrastructure</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Third Section */}
            <section className="bd-brand-area-two pb-75 pt-80">
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row second-dedicated-resp">
                        <div className="col-xl-4 col-lg-6 col-md-6 third-sec1-responsive">
                            <div className="col-12">
                                <div className="bd-section-title-wrapper mb-20">
                                    <h2 className="bd-section-title mb-0 pl-0 fourth_sec_tittle-dedicat"> Hire the Ideal <span style={{ color: "#ffbf1e" }}>Dedicated Developer</span> For Your Business with the Right Company</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-6 col-md-6 third-sec2-responsive" >
                            <div className="bd-brand-active-two swiper-container">
                                <div className="swiper-wrapper">
                                    <p style={{ fontSize: "18px", lineHeight: "1.75" }}>If you are looking to hire a dedicated team of developers to help you build your business, then you should consider looking into finding them through an online resource or a specific business network. There are a few things to consider when looking for dedicated developers for your business. First and foremost, you'll want to make sure that you have the right skills and experience to develop and manage a product. Secondly, you'll want to make sure that the developers you choose have a passion for the task at hand - they need to be excited about their work and see it as an important part of their job description. Finally, it's important to ensure you're getting the best deal possible - you don't want to spend too much money on someone who isn't delivering on their promises.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Fourth section*/}

            <section className="bd-about-area pb-80">
                <div className="bd-custom-container" >
                    <div className="bd-about-four-bg pb-0 fix">
                        <div className="bd-about-shape-box">
                            <div className="container" style={{ maxWidth: "1270px" }}>
                                <div className="row justify-content-center">

                                    <div className="col-xl-6 col-lg-8 fourth_responsive_section1" >
                                        <div className="bd-about-text bd-about-text-four z-index">
                                            <div className="bd-section-title-wrapper">

                                                <h2 className="bd-section-title mb-15  md-pr-50 dedicate-last-tittle">We Work as a Team to Improve Client Satisfaction from Start to End.</h2>
                                                <p className="">When it comes to finding the right resources for your business, you want to make sure you're utilizing the most appropriate and effective options. That's why we've created a dedicated resource specifically for businesses. This resource provides information on the best resources for starting, running, and expanding your business. From business advice to stories from successful entrepreneurs, we have everything you need to get started in business.</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-8 pl-30 fourth_responsive_section2">
                                        <div className="bd-about-img">
                                            <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659407720/website/dedicate-resource/dedicate-resource-sec-2_blf8sb.jpg" className="xss-w-full rounded-10" alt="about img not found" />
                                            {/* <img className="bd-about-shape bd-about-shape-6 hero-1-dot" src={shape6} alt="shape img not found"/> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Banner Section */}
            <section className="bd-video-area pt-135 pb-130" style={{ backgroundColor: "#5c0385" }}>
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row responsive-banner">
                        <div className="col-lg-12 first_section">
                            <div className="bd-video-text">
                                <h2 className="bd-video-title first-para-resp" >Looking to Hire a Dedicated Developer Expert?</h2>
                                <p className="contact_section second-para-resp">You're at the right place. The right time</p>
                                <p className="contact_subtext">Surprisingly great Developer, Let's get started right now !</p>
                            </div>
                        </div>
                        <div className="col-lg-12 second_section">
                            <div className="contact-stuff pt-30">
                                <div className="contact_icon icon_respon pt-15 pl-50">
                                    <FontAwesomeIcon icon={faPhone} style={{ fontSize: "30px" }} />
                                </div>
                                <div className="contact_txt ">
                                    <p className="contact-sub-text">CALL US NOW</p>
                                    <a href="tel:(+91)720-288-2277" style={{ fontSize: "17px" }} className="mbb-5 pb-20">(+91) 720-288-2277</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default DedicatedResource

