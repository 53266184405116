import React, { useState, useEffect } from 'react';
import "../Styles/Fact.css";
import '../assets/css/odometer.css'
import Odometer from 'react-odometerjs';


const Fact = () => {

    // setTimeout(function() {
    //     Odometer.innerHTML = 80;
    // });

    return (
        <div>
            <section className="bd-fact-area bd-fact-about-spacing fact-responsive fix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-6">
                            <div className="bd-fact bd-fact-align-left mb-20">
                                <div className="bd-fact-inner">
                                    <h2 className="bd-fact-title"><span className="odometer">150</span>+</h2>
                                    <span className="bd-fact-subtitle" style={{ color: "black", fontWeight: "600" }}> Happy Clients</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="bd-fact bd-fact-align-center mb-30">
                                <div className="bd-fact-inner">
                                    <h2 className="bd-fact-title"><span className="odometer" data-count="80">200</span>+</h2>
                                    <span className="bd-fact-subtitle" style={{ color: "black", fontWeight: "600" }}>Project Completed</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="bd-fact bd-fact-align-center mb-30">
                                <div className="bd-fact-inner">
                                    <h2 className="bd-fact-title"><span className="odometer" data-count="30">30</span>+</h2>
                                    <span className="bd-fact-subtitle" style={{ color: "black", fontWeight: "600" }}>Team Members</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="bd-fact bd-fact-align-right mb-30">
                                <div className="bd-fact-inner">
                                    <h2 className="bd-fact-title">$50K</h2>
                                    <span className="bd-fact-subtitle" style={{ color: "black", fontWeight: "600" }}>Highest Project Value</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Fact