import React from "react";
import "../Styles/Service.css";
import "../assets/css/flaticon.css";
import "../Styles/Theme.css";
import "../assets/css/custom-animation.css";
import { Link } from "react-router-dom";

const Service = () => {
  return (
    <div>
      <section className="bd-service-area main-top-service pt-80 pb-50 gray-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 pt-20">
              <div className="bd-section-title-wrapper mb-50">
                <h5 className="bd-section-subtitle mb-15 subtitle-without-border">
                  Our Services
                </h5>
                <h2 className="bd-section-title">
                  Be at the ForeFront of{" "}
                  <span style={{ color: "#ffbf1e" }}>Innovation</span>
                </h2>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="bd-service mb-30">
                <div className="bd-service-icon mb-20">
                  <i>
                    <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659451034/website/icons/business-automation-icon_glwtlf.png" />
                  </i>
                </div>
                <h4 className="bd-service-title mb-20">
                  <Link to={"/business-automation"}>Business Automation</Link>
                </h4>
                <p>
                  We help businesses To automize their process, increase their
                  profitability, reduce their risk and achieve sustainable
                  growth.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="bd-service mb-30">
                <div className="bd-service-icon mb-20">
                  <i>
                    <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659451034/website/icons/bespoke-system-icon_fa3bts.png" />
                  </i>
                </div>
                <h4 className="bd-service-title mb-20">
                  <Link to={"/digital-marketing"}>
                    Digital Marketing
                  </Link>
                </h4>
                <p>
                  Our digital marketing services in Canada drive growth and
                  boost brand visibility, ensuring businesses reach their target
                  audience effectively, and maximizing their online presence and
                  conversion rates.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="bd-service mb-30">
                <div className="bd-service-icon mb-20">
                  <i>
                    <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659451034/website/icons/software-development-icon_kstwkg.png" />
                  </i>
                </div>
                <h4 className="bd-service-title mb-20">
                  <Link to={"/custom-software-development"}>
                    Custom Software Development
                  </Link>
                </h4>
                <p>
                  With our expertise, we create custom software that aligns
                  perfectly with your unique needs, ensuring streamlined
                  operations and accelerated growth.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="bd-service mb-30">
                <div className="bd-service-icon mb-20">
                  <i>
                    <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659451034/website/icons/mobile-apps-icon_oxjcze.png" />
                  </i>
                </div>
                <h4 className="bd-service-title mb-20">
                  <Link to={"/mobile-apps-development"}>
                    Mobile Apps Development
                  </Link>
                </h4>
                <p>
                  We provide complete mobile apps development services to help
                  you make the most out of your business.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="bd-service mb-30">
                <div className="bd-service-icon mb-20">
                  <i>
                    <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659451034/website/icons/dedicate-resource-icon_rr9dis.png" />
                  </i>
                </div>
                <h4 className="bd-service-title mb-20">
                  <Link to={"/website-development"}>
                    Website Development
                  </Link>
                </h4>
                <p>
                  In the Canadian business landscape, website development
                  encompasses crafting and maintaining web-based assets,
                  involving design, coding, and performance optimization to
                  drive digital growth.
                </p>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6">
              <div className="bd-service mb-30">
                <div className="bd-service-icon mb-20">
                  <i>
                    <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659451034/website/icons/dedicate-resource-icon_rr9dis.png" />
                  </i>
                </div>
                <h4 className="bd-service-title mb-20">
                  <Link to={"/hire-a-resource"}>Dedicate Resource</Link>
                </h4>
                <p>
                  Experience the true value of time and money by hiring a dedicated resource that allows you to focus on the projects you care about.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Service;
