import React, { useState } from 'react'
import Header1 from '../../Components/seperate Components/Header1'
import Shape2 from "../../assets/brand/choose-shape-2.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import Footer from '../../Components/Footer';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss'
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import "../../Styles/Redirecting Styles/FooterStyle/DigitalMarketing.css"
import { Helmet } from 'react-helmet';



const DigitalMarketing = () => {


  const [isOpen, setOpen] = useState(false)




  return (
    <div>
      <Helmet>
        <title>Top Digital Marketing Company in India - Best Services & Solutions</title>
        <meta name='description' content="Unlock success with the best digital marketing services in India. Our top-rated company delivers expert solutions for your business." />
        <meta name='keywords' content='top digital marketing services,digital marketing company in india,best digital marketing services,digital marketing services,digital marketing services india,top digital marketing company in india' />
      </Helmet>

      <Header1 />

      {/* Banner section */}
      <div className="bd-page-title-area bd-page-title-bg2 pt-220 pb-155" style={{ height: "380px" }}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bd-page-title text-center z-index" style={{ bottom: "45px" }}>
                <h2 className="breadcrumb-title" style={{ fontSize: '50px' }}>Digital Marketing</h2>
                <div className="breadcrumb-menu">
                  <nav className="breadcrumb-trail breadcrumbs">
                    <p style={{ color: "black" }}>Connect with a wider audience and make more sales with digital marketing!</p>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* First section */}
      <section className="bd-choose-area fix pb-70 pt-80 ">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="bd-choose-shape">
            <div className="row">
              <div className="col-xl-6 col-lg-6 order-2 order-lg-1 pr-20">
                <div className="bd-choose-text z-index">
                  <h2 className="bd-section-title mb-15" style={{ fontSize: "46px" }}>
                    Way to Increase Sales in Business
                  </h2>
                  <div className="bd-section-title-wrapper">
                  </div>
                  <p
                    className="mb-20"
                    style={{ width: "95%", fontSize: "18px" }}
                  >
                    Digital marketing also allows businesses to reach a larger audience more quickly and easily. By using social media platforms like Facebook, Twitter, and LinkedIn, businesses can connect with a wider range of customers. Additionally, online advertising can be used to promote new products and services to potential customers who may not have visited a business directly.
                  </p>
                  <p className="" style={{ fontSize: "18px" }}>When it comes to engaging with customers online, digital marketing is often better than traditional methods. In order to effectively interact with potential and current customers, businesses must find ways to attraction them through great content, attractive visuals, and helpful tips or advice.</p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 order-1 order-lg-2">
                <div className="bd-choose-img text-end  z-index">
                  <div className="row align-items-center media-respon-digital custom-mar-15">
                    <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                      <div className="bd-choose-img-inner mb-15 bd-choose-img-inner-1">
                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659374275/website/digital-marketing/digital-marketing-sec-1.2_uk04iv.jpg" className="resp-img bordertop-img1" alt="img not found" />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                      <div className="bd-choose-img-inner mb-15">
                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659374275/website/digital-marketing/digital-marketing-sec-1.1_wxskbw.jpg" className="resp-img bordertop-img2" alt="img not found" />
                      </div>
                      <div className="bd-choose-img-inner mb-15">
                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659374275/website/digital-marketing/digital-marketing-sec-1.3_ebq6gq.jpg" className="resp-img bordertop-img3" alt="img not found" />
                      </div>
                    </div>
                  </div>
                  <img
                    className="bd-choose-shape bd-choose-shape-2"
                    src={Shape2}
                    alt="shape img not found"
                  />
                  {/* <div className="bd-choose-video-popup">
                                    <a className="play_btn popup-video popup-youtube" onClick={()=> setOpen(true)}>
                                      <i className="fas fa-play"></i></a>
                                      <ModalVideo autoplay isOpen={isOpen} channel='youtube' videoId='L61p2uyiMSo' onClose={() => setOpen(false)}/>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="bd-choose-video-popup first_pop_video"> */}
        <ModalVideo autoplay isOpen={isOpen} channel='youtube' videoId='L61p2uyiMSo' onClose={() => setOpen(false)}>
        </ModalVideo>
        <button style={{ display: "none" }} className="btn btn  first_pop_video responsive_digital" onClick={() => setOpen(true)}>
          <FontAwesomeIcon icon={faPlay} className="play_bt_vid play_transition" />
        </button>

        {/* </div> */}


      </section>

      <section className="bd-service-area pb-80 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 pt-20">
              <div className="bd-section-title-wrapper mb-50">
                <h2 className="bd-section-title" style={{ fontSize: "46px" }}><span style={{ color: "#ffbf1e" }}> Digital Marketing </span>  Services that are best for Your Business</h2>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                <div className="bd-service-icon mb-20">
                  <i >
                    <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440353/website/icons/seo-64_syyuz1.png" />
                  </i>
                </div>
                <h4 className="bd-service-title mb-20"><a>Search Engine Optimization (SEO)</a></h4>
                <p>Increased brand visibility will result from SEO efforts, which will also enhance organic traffic.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                <div className="bd-service-icon mb-20">
                  <i>
                    <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440353/website/icons/pay-per-click-64_o03lrg.png" />
                  </i>
                </div>
                <h4 className="bd-service-title mb-20"><a>Pay-per-Click (PPC)</a></h4>
                <p>The number of clicks the link receives helps attain a top rank quickly as well as is time efficient.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                <div className="bd-service-icon mb-20">
                  <i>
                    <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440354/website/icons/social-media-marketing-64_yu13tb.png" />
                  </i>
                </div>
                <h4 className="bd-service-title mb-20"><a>Social Media Marketing</a></h4>
                <p>Target particular client categories with Facebook/Instagram ads and reach the relevant demographics.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                <div className="bd-service-icon mb-20">
                  <i>
                    <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440353/website/icons/content-marketing-64_mu03q4.png" />
                  </i>
                </div>
                <h4 className="bd-service-title mb-20"><a>Content Marketing</a></h4>
                <p>Interact with the audience and respond to their queries. Additionally, it can aid in lead generation and sales closure.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                <div className="bd-service-icon mb-20">
                  <i>
                    <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440353/website/icons/marketing-64_iuhj64.png" />
                  </i>
                </div>
                <h4 className="bd-service-title mb-20"><a>Marketing Analytics</a></h4>
                <p>Businesses can easily understand what motivates their consumer behavior and maximize their ROI.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA */}
      <section className="bd-video-area pt-135 pb-130" style={{ backgroundColor: "#5c0385" }}>
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row responsive-banner">
            <div className="col-lg-12 first_section">
              <div className="bd-video-text">
                <h2 className="bd-video-title first-para-resp" >Looking for Digital Marketing Expert?</h2>
                <p className="contact_section second-para-resp">You're at the right place. The right time</p>
                <p className="contact_subtext">Surprisingly great Digital Marketing, Let's get started right now !</p>
              </div>
            </div>
            <div className="col-lg-12 second_section">
              <div className="contact-stuff pt-30">
                <div className="contact_icon icon_respon pt-15 pl-50">
                  <FontAwesomeIcon icon={faPhone} style={{ fontSize: "30px" }} />
                </div>
                <div className="contact_txt ">
                  <p className="contact-sub-text">CALL US NOW</p>
                  <a href="tel:(+91)720-288-2277" style={{ fontSize: "17px" }} className="mbb-5 pb-20">(+91) 720-288-2277</a>
                </div>
              </div>
              {/* <Link to={"/zithas/contact-us"} onClick={toggleContact}>
                    <button className="touch-btn1 mt-20">Get Started</button>
                    </Link> */}
            </div>

          </div>
        </div>
      </section>

      <Footer />



    </div>
  )
}

export default DigitalMarketing