import React, { useState } from "react";
import Shape2 from "../../assets/brand/choose-shape-2.png";
import {
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



const Choos = () => {

  const [isOpen, setOpen] = useState(false)


  return (
    <div >
      <section className="bd-choose-area fix pb-0 pt-80">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="bd-choose-shape">
            <div className="row">
              <div className="col-xl-6 col-lg-6 order-2 order-lg-1">
                <div className="bd-choose-text  z-index">
                  <div className="bd-section-title-wrapper mb-25">
                    {/* <h5 className="bd-section-subtitle mb-15">Why Choose Us</h5> */}
                    <h2 className="bd-section-title mb-15">
                      Is Business Process Getting Complex ?
                    </h2>
                  </div>

                  <p
                    className="mb-20"
                    style={{ width: "95%", fontSize: "18px" }}
                  >
                    If yes then Business Audit is another tool in your business toolkit – it’s an essential part of improving your company’s performance in today’s world where competition has never been stronger and technology is constantly on top of people’s minds. Business Audit helps you identify opportunities while at the same time reducing costs & risks by providing insights into why things aren’t working well in today’s world.
                  </p>
                  <p className="mb-20" style={{ fontSize: "18px" }}>Gaps are areas within an organization that are not fully utilised because a lack of awareness. This lack of awareness causes an organization to miss opportunities for growth and success.</p>

                  <p style={{ width: "95%", fontSize: "18px" }}>
                    For example, if a company has a great product but lacks resources to properly train their employees in software they are using or fail to connect it with another software. A Business Audit allows your company to identify those gaps so you can further analyse them and find ways to close them.
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 order-1 order-lg-2">
                <div className="bd-choose-img text-end  z-index">
                  <div className="row align-items-center media-respon-digital custom-mar-15">
                    <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                      <div className="bd-choose-img-inner mb-15 bd-choose-img-inner-1">
                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659371664/website/business-audit/business-audit-sec-1.2_qq4lhw.jpg" className="resp-img bordertop-img1" alt="img not found" />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                      <div className="bd-choose-img-inner mb-15">
                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659371664/website/business-audit/business-audit-sec-1.1_m5wuof.jpg" className="resp-img bordertop-img2" alt="img not found" />
                      </div>
                      <div className="bd-choose-img-inner mb-15">
                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659371664/website/business-audit/business-audit-sec-1.3_nwjc30.jpg" className="resp-img bordertop-img3" alt="img not found" />
                      </div>
                    </div>
                  </div>
                  <img
                    className="bd-choose-shape bd-choose-shape-2"
                    src={Shape2}
                    alt="shape img not found"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>



      </section>

      <ModalVideo autoplay isOpen={isOpen} channel='youtube' videoId='MF4se5r_Uu4' onClose={() => setOpen(false)}>
      </ModalVideo>
      <button className="btn btn responsive_digital first_pop_video responsive_audit" onClick={() => setOpen(true)}>
        <FontAwesomeIcon icon={faPlay} className="play_bt_vid play_transition" />
      </button>



    </div>
  );
};

export default Choos;
