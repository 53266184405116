import React, { useState, useEffect } from "react";
import "../Styles/header.css";
import "../Styles/Theme.css";
import "../Styles/meanmenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAppleAlt, faArrowRight, faBars, faGlobe, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import indiaFlag from "../assets/logo/india.png";
import canadaFlag from "../assets/canada/canadian_flag.jpg";
import UkFlag from "../assets/canada/uk (1).png";
import Select from "react-select";
import { faAndroid, faApple } from "@fortawesome/free-brands-svg-icons";

const Header = () => {
  const [navbar, setNavbar] = useState(false);
  const [stickyClass, setStickyClass] = useState("");

  // sticky Navbar
  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 80 ? setStickyClass("sticky-nav") : setStickyClass("");
    }
  };

  window.addEventListener("scroll", stickNavbar);

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.addEventListener("scroll", stickNavbar);
  }, []);

  const change = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }

    // console.log(window.scrollY)
  };

  useEffect(() => {
    window.addEventListener("scroll", change);
    return () => window.addEventListener("scroll", change);
  }, []);

  const [click, setClick] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);

  window.addEventListener("resize", function (event) {
    setWidth(window.innerWidth);
  });

  const toggleContact = () => {
    scroll.scrollToTop(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const onChangeCountry = (e) => {
    if (e.value === "India") {
      navigate("/");
    } else if (e.value === "Canada") {
      window.location.assign('https://www.zithas.com/ca')
    } else if (e.value === "UK") {
      window.location.assign('https://www.zithas.co.uk')
    }
  };

  const options = [
    {
      value: "India",
      label: (
        <div>
          <img src={indiaFlag} height="20px" width="30px" className="mr-10" />
          India
        </div>
      ),
    },
    {
      value: "Canada",
      label: (
        <div >
          <img src={canadaFlag} height="20px" width="30px" className="mr-10" />
          Canada
        </div>
      ),
    },
    {
      value: "UK",
      label: (
        <div>
          <img src={UkFlag} height="20px" width="30px" className="mr-10" />
          UK
        </div>
      ),
    },
  ];
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        // You can adjust the threshold (100 in this example) as needed
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    const redirectionLinks = {
      android: "#",
      ios: "#",
      web: "https://clients.zithas.com",
    };

    const selectedLink = redirectionLinks[selectedValue];

    window.open(selectedLink, '_blank');
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "white" : state.isSelected ? "white" : undefined
    }),
  };

  return (
    <div>
      <header>
        <div
          className={
            navbar
              ? "bd-header-area active"
              : `bd-header-area-lg ${stickyClass}`
          }
        >
          <div className="bd-header-border" style={{ backgroundColor: "#fff" }}>
            <div className="bd-custom-container bd-spacing">
              <div className="row align-items-center justify-content-between pt-md-2">
                <div className="col-md-4 col-6">
                  <div className="logo-navbar">
                    <Link to="/">
                      <img
                        src={
                          "https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659376912/website/logo/zithas-logo-og_qruynr.png"
                          // navbar
                          //   ? "https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659376912/website/logo-logo-og_qruynr.png"
                          //   : "https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659376853/website/logo-main-logo_saitcj.png"
                        }
                        style={{ width: "180px", zIndex: "999" }}
                        alt="logo not found"
                      />
                    </Link>
                  </div>
                </div>

                <div className="col-md-8 col-6">
                  <div className=" d-flex align-items-center justify-content-end">
                    {/* call */}
                    <div
                      className="text-end d-md-block d-none"
                      style={{ paddingTop: "6px" }}
                    >
                      <div className="">
                        <a
                          href="tell:(+91)720-288-2277"
                          class="text-decoration-none text-dark"
                          style={{ fontSize: "18px" }}
                        >
                          {" "}
                          <span class=" fw-bold">
                            {" "}
                            Call for Inquiry :{" "}
                          </span>{" "}
                          (+91)720-288-2277
                        </a>
                      </div>
                    </div>

                    {/* map */}
                    <div className="px-4" style={{ paddingTop: "6px" }}>
                      <div
                        className="d-flex align-items-center justify-content-end"
                        style={{ justifyContent: "center" }}
                      >
                        <div className="bd-header-right flag-inca text-end mr-10">
                          <Select
                            options={options}
                            defaultValue={{
                              value: "India",
                              label: (
                                <div>
                                  <img
                                    src={indiaFlag}
                                    height="20px"
                                    width="30px"
                                  />
                                </div>
                              ),
                            }}
                            onChange={onChangeCountry}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: "#5c0385",
                                primary: "#5c0385",
                                color: "white",
                              },
                            })}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                    </div>

                    {/* <!-- mobile menu activation --> */}
                    <div
                      className="side-menu-icon d-xl-none text-end ps-md-4"
                      onClick={handleClick}
                    >
                      <button
                        className="side-toggle pt-3"
                        onClick={handleClick}
                      >
                        {" "}
                        <i className={``}>
                          <FontAwesomeIcon icon={faBars} />
                        </i>
                      </button>
                    </div>

                    {/* login btn */}
                    <div className="text-end" style={{ paddingTop: "6px" }}>
                      <div className="bd-header-btn">
                        <button
                          className="get-start2"
                          data-bs-toggle="modal" data-bs-target="#clientLoginModal1"
                        >
                          Client Login{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`row justify-content-center scrolled ${isScrolled ? "" : "scrolled"
                  }`}
                style={{
                  paddingLeft: "0px",
                  marginTop: "10px",
                  marginRight: "-10px",
                }}
              >
                {/* <div className="col-md-1"></div> */}
                <div className="col-md-11 bd-main-menu txt-cent">
                  <nav id="bd-mobile-menu">
                    <ul style={{ textAlign: "center" }}>
                      <li className="menu-item-has-children">
                        <Link
                          onClick={toggleContact}
                          to={"/business-software-audit"}
                          className="nav_txt_size"
                        >
                          BUSINESS SOFTWARE AUDIT
                        </Link>
                      </li>

                      {/* <li className="menu-item-has-children"><Link onClick={toggleContact} to={"/bespoke-systems"}  >BESPOKE SYSTEMS </Link>
                                                </li> */}

                      <li className="menu-item-has-children">
                        <Link
                          onClick={toggleContact}
                          to={"/business-automation"}
                        >
                          BUSINESS AUTOMATION{" "}
                        </Link>
                      </li>

                      <li className="menu-item-has-children">
                        <Link
                          onClick={toggleContact}
                          to={"/custom-software-development"}
                        >
                          CUSTOM SOFTWARE DEVELOPMENT
                        </Link>
                      </li>

                      <li className="menu-item-has-children">
                        <Link
                          onClick={toggleContact}
                          to={"/website-development"}
                        >
                          WEBSITE DEVELOPMENT
                        </Link>
                      </li>

                      <li className="menu-item-has-children">
                        <Link
                          onClick={toggleContact}
                          to={"/mobile-apps-development"}
                        >
                          MOBILE APPS DEVELOPMENT{" "}
                        </Link>
                      </li>

                      <li className="menu-item-has-children">
                        <Link
                          onClick={toggleContact}
                          to={"/hire-a-resource"}
                        >
                          HIRE A RESOURCE
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>

                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="fix">
        <div
          className={click ? "side-info1 info-open1 " : "side-info1 "}
          onClick={handleClick}
        >
          <i>
            <FontAwesomeIcon icon={faTimes} />{" "}
          </i>
          <div className="side-info-content mt-20">
            <div
              className={
                width <= 1199
                  ? "bd-mobile-menu mean-container"
                  : "bd-mobile-menu "
              }
            >
              <div className="bd-mobile-menu mean-container">
                <div className="mean-bar">
                  <a
                    href="#nav"
                    className="meanmenu-reveal"
                    style={{ right: "0px", left: "auto", display: "inline" }}
                  >
                    <span>
                      <span>
                        <span></span>
                      </span>
                    </span>
                  </a>
                  <nav className="mean-nav">
                    <ul style={{ display: "none" }}>
                      <li className="menu-item-has-children">
                        <Link to={"/business-software-audit"}>
                          BUSINESS SOFTWARE AUDIT
                        </Link>
                      </li>

                      {/* <li className="menu-item-has-children"><Link to={"/bespoke-systems"}>BESPOKE SYSTEMS </Link></li> */}

                      <li className="menu-item-has-children">
                        <Link to={"/business-automation"}>
                          BUSINESS AUTOMATION
                        </Link>
                      </li>

                      <li className="menu-item-has-children">
                        <Link to={"/custom-software-development"}>
                          CUSTOM SOFTWARE DEVELOPMENT
                        </Link>
                      </li>

                      <li className="menu-item-has-children">
                        <Link to={"/website-development"}>
                          WEBSITE DEVELOPMENT
                        </Link>
                      </li>

                      <li className="menu-item-has-children">
                        <Link to={"/mobile-apps-development"}>
                          MOBILE APPS DEVELOPMENT
                        </Link>
                      </li>

                      <li className="menu-item-has-children">
                        <Link to={"/hire-a-resource"}>
                          HIRE A RESOURCE
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="mt-20">
                  <button
                    className="get-start"
                    style={{ backgroundColor: "#c32948" }}
                  >
                    Get Started
                  </button>
                </div>
              </div>
            </div>
            <div className="contact-infos mb-30"></div>
          </div>
        </div>
      </div>
      <div className={click ? "offcanvas-overlay" : "overlay-open"}></div>


      <div className="modal fade" id="clientLoginModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog  pb-direct-btn">
          <div className="modal-content" style={{ width: "80%", padding: '12px' }}>
            {/* <div className="row" style={{ border: "none", justifyContent: "end" }}>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div> */}
            <div className="bd-redirect " >
              <div style={{ width: "100%" }}>

                <div>
                  <a href="https://clients.zithas.com" target="_blank">
                    <span><FontAwesomeIcon icon={faGlobe} className=" pr-10" /></span>
                    Login via Web
                  </a>
                </div>
                <div>
                  <a href="https://play.google.com/store/apps/details?id=com.mars.bms&pli=1" target="_blank">
                    {/* <span><FontAwesomeIcon icon={faAndroid} className=" pr-10" /></span> */}
                    <i className="bi bi-android2 pr-10" style={{ fontSize: "22px" }} ></i>
                    Download our Android App
                  </a>
                </div>
                {/* <div>
                  <a>
                    <span><FontAwesomeIcon icon={faApple} className=" pr-10" /></span>
                    Download our IOS App
                  </a>
                </div> */}
              </div>
            </div>

          </div>
        </div>
      </div>




    </div>
  );
};

export default Header;
