import React, { useState, useEffect } from "react";
import Header1 from "../../../Components/seperate Components/Header1";
import "../../../Styles/Redirecting Styles/FooterStyle/CareerDetails.css";
import "../../../assets/css/DetailModel.css";
import Footer from "../../../Components/Footer";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../Components/seperate Components/Spinner";
import swal from "sweetalert";
import Swal2 from "sweetalert2";
import { Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

const Details2 = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalShowDesc, setModalShowDesc] = useState(false);

  const [name, setName] = useState(localStorage.getItem("auth_name"));
  const [email, setEmail] = useState(localStorage.getItem("auth_email"));
  const [mobile, setMobile] = useState(localStorage.getItem("auth_mobile"));
  const [ctc, setCtc] = useState("");
  const [expected, setExpected] = useState("");
  const [notice_period, setNoticePeriod] = useState("");
  const [resume, setResume] = useState();
  const [Loading, setPostLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", mobile);
    {
      opening.map((jobs) => {
        formData.append("position", jobs.position_name);
      });
    }
    setPostLoading(true);
    try {
      const response = await axios({
        method: "post",
        url: "https://recruitment.thestaffguru.com/api/personal_info",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {
        localStorage.setItem("auth_id", response.data.ID);
        localStorage.setItem("auth_name", name);
        localStorage.setItem("auth_mobile", mobile);
        localStorage.setItem("auth_email", email);
        setModalShowDesc(true);
        setPostLoading(false);
      });
    } catch (error) {
      console.log(error);
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setPostLoading(false);
    }
  };

  const { permalink } = useParams();
  const [opening, SetOpening] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get(
          `https://mars.zithas.com/api/currentopening/${permalink}`
        );
        SetOpening(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, []);

  const [total_ctc, setTotalCtc] = useState("");
  const [desc, setShowDesc] = useState(false);

  const [otherCalc, setOtherCalc] = useState("");
  const [desired_ctc, setDesiredCtC] = useState("");
  const [desired_ctc_Cala, setDesiredCtCCal] = useState("");

  const onChangeExpected = (e) => {
    setDesiredCtC(e.target.value);
    const value = parseInt(e.target.value);
    setExpected(e.target.value);
    let percentage;
    let expectedCTC;
    if (ctc.length != 0) {
      percentage = (ctc * parseInt(e.target.value)) / 100;
      expectedCTC = parseInt(ctc) + percentage;
      setTotalCtc(<span className="form-type">{expectedCTC}</span>);
      setDesiredCtCCal(expectedCTC);
      setOtherCalc("");
    } else {
      setTotalCtc("");
    }

    if (value == "20" || value == "10") {
      // setShowDesc(true);
      setTotalCtc(<p className="smiley-face">&#128578;</p>);
      if (ctc.length != 0) {
        setOtherCalc(<span className="form-type">{expectedCTC}</span>);
      }
    } else if (value == "30") {
      setShowDesc(true);
      setTotalCtc(<p className="smiley-face"> &#128516;</p>);
      if (ctc.length != 0) {
        setOtherCalc(<span className="form-type">{expectedCTC}</span>);
      }
    } else if (value == "40") {
      setShowDesc(true);
      setBorder("orange");
      setTotalCtc(<p className="smiley-face">&#128513;</p>);
      if (ctc.length != 0) {
        setOtherCalc(<span className="form-type">{expectedCTC}</span>);
      }
    } else if (value == "50") {
      setShowDesc(true);
      setBorder("red");
      setTotalCtc(<p className="smiley-face">&#128517;</p>);
      if (ctc.length != 0) {
        setOtherCalc(<span className="form-type">{expectedCTC}</span>);
      }
    } else {
      setShowDesc(false);
    }
  };

  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get(
          "https://recruitment.thestaffguru.com/api/skills"
        );
        const options = data.map((item) => ({
          label: item.skills_name,
          value: item.id,
        }));
        setSkills(options);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, []);

  const [experience_year, setExperienceYear] = useState("");
  const [experience_month, setExperienceMonth] = useState("");
  const [show_current, setCtcCurrent] = useState(false);
  const [border_color, setBorder] = useState("");

  const onChangeExpected2 = (e) => {
    setDesiredCtC(e.target.value);
    if (e.target.value == "8k to 10k" || e.target.value == "10k to 15k") {
      setTotalCtc(<p className="smiley-face">&#128578;</p>);
      setShowDesc(false);
      setExpected(e.target.value);
    } else if (e.target.value == "15k to 20k") {
      setTotalCtc(<p className="smiley-face">&#128513;</p>);
      setShowDesc(true);
      setExpected(e.target.value);
      setBorder("purple");
    } else if (e.target.value == "20k to 25k") {
      setTotalCtc(<p className="smiley-face">&#128513;</p>);
      setShowDesc(true);
      setExpected(e.target.value);
      setBorder("orange");
    } else if (e.target.value == "25k to 30k") {
      setTotalCtc(<p className="smiley-face">&#128517;</p>);
      setShowDesc(true);
      setExpected(e.target.value);
      setBorder("red");
    } else {
      setTotalCtc("");
    }
  };

  let ExpectedCtC;
  if (experience_year == "0" && experience_month == "0") {
    ExpectedCtC = (
      <select className="pl-10" onChange={onChangeExpected2}>
        <option value="">Select Expected CTC</option>
        <option value="8k to 10k">8k to 10k</option>
        <option value="10k to 15k">10k to 15k</option>
        <option value="15k to 20k">15k to 20k</option>
        <option value="20k to 25k">20k to 25k</option>
        <option value="25k to 30k">25k to 30k</option>
      </select>
    );
  } else {
    ExpectedCtC = (
      <select className="pl-10" onChange={onChangeExpected}>
        <option value="">Select Expected CTC</option>
        <option value="10">10%</option>
        <option value="20">20%</option>
        <option value="30">30%</option>
        <option id="40%" value="40">
          40%
        </option>
        <option id="50%" value="50">
          50% or Greater than 50%
        </option>
      </select>
    );
  }

  const [select_skill, setSelectedSkill] = useState([]);
  const [description, setDescription] = useState("");
  const [abilities, setAbilities] = useState("");
  const [gender, setGender] = useState("");

  const navigate = useNavigate();

  const onMainSubmit = async (e) => {
    const result = select_skill.map((obj) => obj.label).join(",");

    let Calulate;
    if (desired_ctc_Cala.length != 0) {
      Calulate = desired_ctc + "-" + desired_ctc_Cala;
    } else {
      Calulate = desired_ctc;
    }
    e.preventDefault();
    const formData = new FormData();
    formData.append("id", localStorage.getItem("auth_id"));
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", mobile);
    formData.append("gender", gender);
    formData.append("skills", result);
    {
      opening.map((jobs) => {
        formData.append("position", jobs.position_name);
      });
    }
    if (ctc.length == 0) {
      formData.append("current_ctc", 0);
    } else {
      formData.append("current_ctc", ctc);
    }
    formData.append("desired_ctc", Calulate);
    formData.append("totalexp", experience_month);
    formData.append("totalexp1", experience_year);
    formData.append("noticetime", notice_period);
    formData.append("file", resume);
    formData.append("description", description);
    formData.append("abilities", abilities);
    setPostLoading(true);
    try {
      const response = await axios({
        method: "post",
        url: "https://recruitment.thestaffguru.com/api/zithas",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {
        setPostLoading(false);
        setModalShow(false);
        Swal2.fire({
          icon: "success",
          html: true,
          customClass: { container: "bd-position-popup" },
          title: " Your Application is Submited at Zithas Technologies! ",
          html: `<br /> We will review your resume and reach out to you accordingly. Kindly retain application ${response.data.ID}  for future reference. <br /><br/> If you have any questions, feel free to reach out to our   <br/> HR Department at <a style="color:#5c0385;font-weight:600" href="tel:(+91) 720-388-2277">720-388-2277</a> or via email at <a href="mailto:hr@zithas.com" style="color:#5c0385;font-weight:600" > hr@zithas.com </a>`,
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "Thank You !",
          className: "bd-position-popup",
        }).then((data) => {
          localStorage.removeItem("auth_id");
          localStorage.removeItem("auth_name");
          localStorage.removeItem("auth_email");
          localStorage.removeItem("auth_mobile");
          navigate("/");
          setName("");
          setMobile("");
          setMobile("");
        });
      });
    } catch (error) {
      console.log(error);
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setPostLoading(false);
    }
  };

  // console.log(!localStorage.getItem('auth_id'))

  let button;
  if (!localStorage.getItem("auth_id")) {
    button = (
      <button type="submit" className="theme-btn">
        Apply Now
      </button>
    );
  } else {
    button = (
      <button
        type="submit"
        onClick={(e) => {
          setModalShowDesc(true);
          e.preventDefault();
        }}
        className="theme-btn"
      >
        Apply Now
      </button>
    );
  }

  let title;
  let meta;
  if (permalink == ".net-developer") {
    title = "Dot Net Developer Jobs";
    meta =
      "Join Zithas Technologies as a .NET Developer. Exciting career opportunities await. Be part of our dynamic team driving technological excellence.";
  } else if (permalink == "business-developer-executive") {
    title = "Business Developer Executive Jobs";
    meta =
      "Shape the future of tech with Zithas. Explore the role of Business Developer Executive and be a catalyst for innovation and growth.";
  } else if (permalink == "php-developer") {
    title = "PHP Developer Jobs";
    meta =
      "Unlock your potential as a PHP Developer at Zithas Technologies. Join us to create cutting-edge solutions and drive technological progress.";
  }

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={meta} />
      </Helmet>
      <Header1 />

      <div
        className="bd-page-title-area bd-page-title-bg_careerDetail detail-responsive-tittle pt-175 pb-155"
        style={{ height: "380px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bd-page-title text-center z-index">
                {opening.map((jobs) => {
                  return (
                    <>
                      <h2 className="breadcrumb-title1 detail-main-tittle">
                        {jobs.position_name}
                      </h2>
                      <div className="breadcrumb-menu">
                        <nav className="breadcrumb-trail breadcrumbs"></nav>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Blogs section */}
      <section className="bd-blog-deatils-area pt-80 pb-70">
        <div className="container center" style={{ maxWidth: "1300px" }}>
          <div className="row ">
            <div className="col-lg-8 careerdetail-reponsive-section1">
              {isLoading ? (
                <Spinner />
              ) : (
                <div
                  className="blog__details--wrapper mr-00 "
                  style={{ width: "auto" }}
                >
                  {opening.map((jobs) => {
                    return (
                      <>
                        <h3 className="bd-blog-details-title mb-25">
                          {jobs.position_name}
                        </h3>

                        <h3>Job Description:</h3>

                        <ul
                          className="career-text pl-20"
                          style={{ lineHeight: "1.9", fontSize: "17px" }}
                        >
                          <p
                            className="pb-10"
                            dangerouslySetInnerHTML={{
                              __html: jobs.job_description,
                            }}
                          />
                        </ul>
                        <h3>Roles & Responsibilities:</h3>
                        <ul
                          className="career-text pl-20"
                          style={{ lineHeight: "1.9", fontSize: "17px" }}
                        >
                          <div
                            className="pb-20"
                            dangerouslySetInnerHTML={{
                              __html: jobs.roles_responsibilities,
                            }}
                          />
                        </ul>
                        <ul className="career-text ">
                          <h3>Soft Skills Requirements</h3>
                          <ul
                            className="career-text pl-20"
                            style={{ lineHeight: "1.9", fontSize: "17px" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: jobs.soft_skill_requirement,
                              }}
                            />
                          </ul>
                        </ul>
                      </>
                    );
                  })}
                </div>
              )}
            </div>

            <div className="col-lg-6 opening-reponsive">
              <div className="blog__details--wrapper mr-0 mb-50">
                <div className="ablog__sidebar mb-40">
                  <div
                    className="col-xxl-8 col-xl-7 col-lg-7 "
                    style={{ width: "100%" }}
                  >
                    <div className="bd-contact-form-wrapper mb-50">
                      <div
                        className="bd-service mb-30"
                        style={{ backgroundColor: "#fdd796" }}
                      >
                        <h2
                          style={{ textAlign: "center", marginBottom: "20px" }}
                        >
                          Apply Here
                        </h2>
                        {Loading && <Spinner />}
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="bd-contact-field mb-20">
                              <input
                                className="blog-form"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                type="text"
                                style={{ width: "300px" }}
                                placeholder="Name"
                                name="user_name"
                                required
                              />
                            </div>
                            <div className="bd-contact-field mb-20">
                              <input
                                type="email"
                                value={email}
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                                name="user_email"
                                style={{ width: "300px" }}
                                required
                              />
                            </div>

                            <div className="bd-contact-field mb-20 ">
                              <input
                                type="text"
                                value={mobile}
                                placeholder="Contact No"
                                name="user_contact"
                                onChange={(e) => setMobile(e.target.value)}
                                style={{ width: "300px" }}
                                required
                              />
                            </div>
                            <div className="col-12 mb-20 text-center">
                              <div className="bd-contact-field">{button}</div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <Modal
        show={modalShow}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="current-modal"
      >
        <div className="w-100 overflow-y-scroll">
          <div className="modal-header " style={{ border: "none" }}>
            <div className="py-4 d-flex justify-content-between w-100">
              <div>
                <h5 className="modal-title " id="exampleModalLabel">
                  {/* Fill out the Details! */}
                  {opening.map((jobs) => {
                    return (
                      <div className="">
                        <span className="text-yellow">Applying for</span>{" "}
                        {jobs.position_name}{" "}
                        <span className="text-yellow">Position</span>
                      </div>
                    );
                  })}
                </h5>
              </div>
              <div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  // style={{ marginLeft: "50px" }}
                  onClick={() => setModalShow(false)}
                ></button>
              </div>
            </div>
          </div>

          <div className="">
            <div>
              <form onSubmit={onMainSubmit}>
                <div
                  className="row resp-row"
                  style={{ paddingTop: "23px", alignItems: "center" }}
                >
                  <div className="bd-current-open-form col-3 mb-25 resp-w-100">
                    <label>
                      Name <span style={{ color: "#ff4545" }}>*</span>
                    </label>
                    <input
                      className="blog-form"
                      type="text"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      placeholder="Enter Name"
                      name="user_name"
                      required
                    />
                  </div>
                  <div className="bd-current-open-form col-3 mb-25 resp-w-100">
                    <label>
                      Email Id <span style={{ color: "#ff4545" }}>*</span>
                    </label>
                    <input
                      type="email"
                      placeholder="Enter Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      name="user_email"
                      style={{ height: "45px" }}
                      required
                    />
                  </div>
                  <div className="bd-current-open-form col-3 mb-25 resp-w-100">
                    <label>
                      Contact Number <span style={{ color: "#ff4545" }}>*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Contact No"
                      value={mobile}
                      onChange={(e) => {
                        setMobile(e.target.value);
                        localStorage.setItem("mobile", e.target.value);
                      }}
                      name="user_contact"
                      style={{ height: "45px" }}
                      maxLength="10"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      required
                    />
                  </div>
                  <div className="bd-current-open-form col-3 mb-25 resp-w-100">
                    <label>
                      Gender <span style={{ color: "#ff4545" }}>*</span>
                    </label>
                    <select
                      className=" "
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <option value=""> Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div className="bd-current-open-form col-12 mb-25 resp-w-100 ">
                    <label>
                      Skills <span style={{ color: "#ff4545" }}>*</span>
                    </label>
                    <Select
                      placeholder="Select Skills"
                      isMulti
                      options={skills}
                      onChange={(e) => {
                        setSelectedSkill(e);
                      }}
                    />
                  </div>
                  <div className="bd-current-open-form col-2 mb-25 resp-w-100">
                    <label>
                      Experience <span style={{ color: "#ff4545" }}>*</span>
                    </label>
                    <div className="row">
                      <div className="col-6">
                        <select
                          onChange={(e) => {
                            setExperienceYear(e.target.value);
                            if (
                              (e.target.value == "0" &&
                                experience_month == "0") ||
                              (e.target.value == "0" &&
                                experience_month.length == "0")
                            ) {
                              setCtcCurrent(false);
                            } else {
                              setCtcCurrent(true);
                            }
                          }}
                          className="p-0 text-center"
                        >
                          <option value=""> Year</option>
                          <option value="0">00</option>
                          <option value="1">01</option>
                          <option value="2">02</option>
                          <option value="3">03</option>
                          <option value="4">04</option>
                          <option value="5">05</option>
                          <option value="6">06</option>
                          <option value="7">07</option>
                          <option value="8">08</option>
                          <option value="9">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                      </div>
                      <div className="col-6">
                        <select
                          onChange={(e) => {
                            setExperienceMonth(e.target.value);
                            if (
                              (e.target.value == "0" &&
                                experience_year == "0") ||
                              (e.target.value == "0" &&
                                experience_year.length == "0")
                            ) {
                              setCtcCurrent(false);
                            } else {
                              setCtcCurrent(true);
                            }
                          }}
                          className="p-0 text-center"
                        >
                          <option value=""> Month</option>
                          <option value="0">00</option>
                          <option value="1">01</option>
                          <option value="2">02</option>
                          <option value="3">03</option>
                          <option value="4">04</option>
                          <option value="5">05</option>
                          <option value="6">06</option>
                          <option value="7">07</option>
                          <option value="8">08</option>
                          <option value="9">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      show_current
                        ? "bd-current-open-form col-cus-2 mb-25 resp-w-100"
                        : "display-none"
                    }
                  >
                    <label>
                      Current CTC <span style={{ fontSize: "10px" }}>(PM)</span>
                      <span style={{ color: "#ff4545" }}>*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Current CTC"
                      name="user_ctc"
                      onChange={(e) => setCtc(e.target.value)}
                      style={{ height: "45px" }}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>

                  <div
                    className={
                      show_current
                        ? "bd-current-open-form col-24 mb-25 resp-w-100"
                        : "bd-current-open-form col-4 mb-25 resp-w-100"
                    }
                  >
                    <label>
                      Expected CTC <span style={{ color: "#ff4545" }}>*</span>
                    </label>
                    <div className="d-flex align-items-center">
                      <div className="w-100">{ExpectedCtC}</div>
                      <div
                        className={
                          otherCalc.length == 0 ? "display-none" : " ml-20 "
                        }
                      >
                        {otherCalc}
                      </div>
                      <div
                        className={
                          total_ctc.length == 0 ? "display-none" : " ml-20 "
                        }
                      >
                        {total_ctc}
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      desc
                        ? "bd-current-open-form col-2 mb-25 resp-w-100"
                        : "bd-current-open-form col-2 mb-25 resp-w-100"
                    }
                  >
                    <label>
                      Notice Period <span style={{ color: "#ff4545" }}>*</span>
                    </label>
                    {/* <input
                      type="text"
                      placeholder="Notice Period"
                      onChange={(e) => setNoticePeriod(e.target.value)}
                      name="user_period"
                      style={{ height: "45px" }}
                      required
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    /> */}
                    <select
                      className=""
                      name="user_period"
                      required
                      onChange={(e) => setNoticePeriod(e.target.value)}
                    >
                      <option value="">Notice Period</option>
                      <option value="less than 5 days">less than 5 days</option>
                      <option value="5 to 15 days">5 to 15 days</option>
                      <option value="15 to 30 days">15 to 30 days</option>
                      <option value="30 to 60 days">30 to 60 days</option>
                      <option value="60 to 90days">60 to 90days</option>
                      <option value="90 to 180 days">90 to 180 days </option>
                    </select>
                  </div>

                  <div
                    className={
                      desc
                        ? "bd-current-open-form col-3 mb-25 resp-w-100"
                        : "bd-current-open-form col-3 mb-25 resp-w-100"
                    }
                  >
                    <label>
                      Upload CV <span style={{ color: "#ff4545" }}>*</span>
                    </label>
                    <input
                      type="file"
                      className="pt-25"
                      onChange={(e) => setResume(e.target.files[0])}
                      required
                    />
                  </div>

                  <div className="bd-current-open-form col-6 mb-25 resp-w-100">
                    <label>
                      Tell us how your abilities align with the position ?
                      <span style={{ color: "#ff4545" }}>*</span>
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      onChange={(e) => setAbilities(e.target.value)}
                      style={{ fontSize: "14px" }}
                      placeholder="Tell us Your Abilities"
                      required
                    />
                  </div>

                  <div
                    className={
                      desc
                        ? "bd-current-open-form col-6 mb-25 resp-w-100"
                        : "display-none"
                    }
                  >
                    <label>
                      Why do you deserve such a CTC Expectation?{" "}
                      <span style={{ color: "#ff4545" }}>*</span>
                    </label>
                    <textarea
                      type="text"
                      className={`form-control ${border_color}`}
                      onChange={(e) => setDescription(e.target.value)}
                      style={{ fontSize: "14px", borderColor: "#5c0385" }}
                      placeholder="Enter Description"
                      required={desc ? true : false}
                    />
                  </div>

                  <div className="col-12 mb-20 ">
                    <div className="bd-contact-field text-right pb-5 pb-md-0">
                      <button type="submit" className="theme-btn">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        show={modalShowDesc}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="current-modal modal-2 "
      >
        <div className="w-100 pt-3 pt-md-0">
          <div className="w-100 size-pop">
            <div className="modal-header" style={{ border: "none" }}>
              <h5 className="modal-title" id="exampleModalLabel">
                Important Instructions and Guidelines !
              </h5>
              <button
                className="btn-close"
                onClick={() => setModalShowDesc(false)}
                // style={{ marginLeft: "50px" }}
              ></button>
            </div>

            <div>
              <div style={{ width: "100%" }}>
                <div className="mt-30">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      style={{
                        color: "var(--clr-body-heading)",
                        paddingRight: "15px",
                        marginTop: "7px",
                        fontSize: "18px",
                      }}
                      icon={faCheck}
                    />
                    <p className="font-15">
                      <strong className="">
                        Complete Contact Information{" "}
                      </strong>{" "}
                      : Provide accurate personal details such as your full
                      name, current address, phone number, and a professional
                      email address where the employer can easily reach you.{" "}
                    </p>
                  </div>

                  <div className="d-flex mt-10">
                    <FontAwesomeIcon
                      style={{
                        color: "var(--clr-body-heading)",
                        paddingRight: "15px",
                        marginTop: "7px",
                        fontSize: "18px",
                      }}
                      icon={faCheck}
                    />
                    <p className="font-15">
                      <strong className="">Mention Desired CTC </strong>{" "}
                      :Clearly state your expected salary or CTC in the
                      designated field, if required. Be realistic and research
                      industry standards to provide a competitive figure.{" "}
                    </p>
                  </div>

                  <div className="d-flex mt-10">
                    <FontAwesomeIcon
                      style={{
                        color: "var(--clr-body-heading)",
                        paddingRight: "15px",
                        marginTop: "7px",
                        fontSize: "18px",
                      }}
                      icon={faCheck}
                    />
                    <p className="font-15">
                      <strong className="">Specify Relevant Skills</strong>{" "}
                      Highlight your relevant skills, that match the job
                      requirements. Emphasise how your abilities align with the
                      position you are applying for.{" "}
                    </p>
                  </div>
                  <div className="d-flex mt-10">
                    <FontAwesomeIcon
                      style={{
                        color: "var(--clr-body-heading)",
                        paddingRight: "15px",
                        marginTop: "7px",
                        fontSize: "18px",
                      }}
                      icon={faCheck}
                    />
                    <p className="font-15">
                      <strong className="">Application ID </strong> :Safeguard
                      Your Application ID for Future Reference{" "}
                    </p>
                  </div>
                  <div className="d-flex mt-10">
                    <FontAwesomeIcon
                      style={{
                        color: "var(--clr-body-heading)",
                        paddingRight: "15px",
                        marginTop: "7px",
                        fontSize: "18px",
                      }}
                      icon={faCheck}
                    />
                    <p className="font-15">
                      <strong className="">Monthly Notation </strong> :Please
                      Provide CTC, Expected CTC, and Notice Period in Months,
                      Not Days{" "}
                    </p>
                  </div>
                  <div className="d-flex mt-10">
                    <FontAwesomeIcon
                      style={{
                        color: "var(--clr-body-heading)",
                        paddingRight: "15px",
                        marginTop: "7px",
                        fontSize: "18px",
                      }}
                      icon={faCheck}
                    />
                    <p className="font-15">
                      <strong className="">Post-Application Inquiries </strong>{" "}
                      : For any queries, kindly contact the HR Department via
                      phone at{" "}
                      <a
                        style={{
                          color: "var(--clr-body-heading)",
                          fontWeight: "600",
                        }}
                        href="tel:(+91) 720-388-2277"
                      >
                        {" "}
                        (+91) 720-388-2277{" "}
                      </a>{" "}
                      or email at{" "}
                      <a
                        style={{
                          color: "var(--clr-body-heading)",
                          fontWeight: "600",
                        }}
                        href="mailto:hr@zithas.com"
                      >
                        hr@zithas.com
                      </a>
                      .{" "}
                    </p>
                  </div>
                  <div className="mt-20 mb-30 text-right pb-5 pb-md-0">
                    <div className="bd-contact-field text-center text-md-end">
                      <button
                        type="submit"
                        className="theme-btn"
                        onClick={() => {
                          setModalShow(true);
                          setModalShowDesc(false);
                        }}
                      >
                        I Understand{" "}
                        <FontAwesomeIcon
                          className="mt-15 ml-15"
                          icon={faThumbsUp}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Details2;
