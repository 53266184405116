import React from 'react'
import '../Styles/Choose.css'
import '../assets/css/flaticon.css'
import '../assets/css/custom-animation.css'

const Choose = () => {
    return (
        <div>
            <section className="bd-choose-area fix">
                <div className="container">
                    <div className="bd-choose-shape choose-main-responsive">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 order-2 order-lg-1">
                                <div className="bd-choose-text mb-30 z-index">
                                    <div className="bd-section-title-wrapper mb-35">
                                        <h5 className="bd-section-subtitle first-tittle-respon">We Believe In </h5>
                                        <h2 className="bd-section-title mb-25">You <span style={{ color: "#ffbf1e" }}>Grow</span> We <span style={{ color: "#ffbf1e" }}>Grow</span> </h2>
                                    </div>
                                    <div className="bd-choose-info-wrapper">
                                        <div className="bd-choose-info mb-40">
                                            <div className="bd-choose-info-icon">
                                                <i>
                                                    <img className="icon" src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440440/website/icons/one-stop-solution_n6towu.png" style={{ maxWidth: "none" }} />
                                                </i>
                                            </div>
                                            <div className="bd-choose-info-text">
                                                <h2 className="bd-choose-info-title mb-15" >One Stop Solution</h2>
                                                <p className="m-0">One-stop destination for complete business automation solutions and original ways to reduce the amount of time you spend on boring, repetitive jobs.</p>
                                            </div>
                                        </div>
                                        <div className="bd-choose-info mb-40">
                                            <div className="bd-choose-info-icon">
                                                <i>
                                                    <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440440/website/icons/Reliable-team_waleeq.png" style={{ maxWidth: "none" }} />
                                                </i>
                                            </div>
                                            <div className="bd-choose-info-text ">
                                                <h2 className="bd-choose-info-title mb-15">Reliable Team</h2>
                                                <p className="m-0">Alongside your company, a dynamic, trustworthy workforce solves problems as well as all projects, regardless of size, receive the same level of attention.</p>
                                            </div>
                                        </div>
                                        <div className="bd-choose-info">
                                            <div className="bd-choose-info-icon">
                                                <i>
                                                    <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440440/website/icons/Robust-development_gatnpy.png" style={{ maxWidth: "none" }} />
                                                </i>
                                            </div>
                                            <div className="bd-choose-info-text ">
                                                <h2 className="bd-choose-info-title mb-15">Robust Development Process</h2>
                                                <p className="m-0">Strong management that carefully manages each project, internal team of skilled project managers and developers.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 order-1 order-lg-2">
                                <div className="bd-choose-img text-end mb-30 z-index">
                                    <div className="row align-items-center img_choose-responsive custom-mar-15">
                                        {/* <div className="col-xl-6 col-lg-6 col-6 custom-pad-15"> */}
                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659350015/website/home/growth-expansion-home_grw2or.jpg" style={{ width: "600px" }}></img>
                                        {/* </div> */}
                                    </div>
                                    <div className="bd-choose-video-popup">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Choose