import React, { useState, useEffect } from 'react'
import { faArrowRight, faCalendarDay, faChevronRight, faMessage, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom"
import axios from 'axios';
import authLogo from "../../assets/logo/MicrosoftTeams-image (14).png"
import Spinner from '../../Components/seperate Components/Spinner';
import Reply from './Reply';
import moment from 'moment/moment';

const CompanyBlogs = ({ posts, isLoading }) => {

    const { permalink } = useParams();
    const [comments, setComments] = useState([])


    const fetchComments = async () => {
        try {
            const { data } = await axios.get("https://mars.zithas.com/api/comment_reply");
            setComments(data.length);
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        fetchComments();
    }, [])

    return (
        <div>

            <section>
                <div className="container pt-80">
                    {isLoading ? (<Spinner />) : (
                        <div className="row">
                            {posts.map((blogs) => {
                                const setdate = moment(blogs.publish_date).format("MMM Do YYYY");

                                let imageVideo;
                                if (blogs.type == "image") {
                                    imageVideo =
                                        <img src={blogs.main_image} className="img-fluid" alt="img" />
                                } else if (blogs.type == "video") {
                                    imageVideo =
                                        <iframe src={blogs.main_image} className="resp-videos"
                                            allowFullScreen="allowFullScreen" frameBorder="0" width="356" height="250" title="Everytime You Go Away - Paul Young" />
                                }
                                return (
                                    <div className="col-xl-4 col-lg-4 col-md-6">
                                        <div className="col-md-12">
                                            <div className="bd-blog mb-30 grid">
                                                <div className="bd-blog-img">
                                                    <Link to={`/blog/${blogs.permalink}`} >
                                                        {/* <img src={blogs.main_image} alt="blog image not found" style={{ width: "410px", height: "250px" }} /> */}
                                                        {imageVideo}
                                                    </Link>
                                                </div>
                                                <div className="bd-blog-text">
                                                    <div className="bd-blog-meta mb-15">
                                                        <ul>
                                                            <li ><Link to={`/blog/${blogs.permalink}`} style={{ textTransform: "none" }}><i > <FontAwesomeIcon icon={faCalendarDay} /></i>{setdate}</Link></li>
                                                            <li><Link to={`/blog/${blogs.permalink}`}><i ><FontAwesomeIcon icon={faMessage} /></i>{comments.length} Comments</Link></li>
                                                        </ul>
                                                    </div>
                                                    <h4 className="bd-blog-title mb-40"><Link to={`/blog/${blogs.permalink}`}>
                                                        {blogs.title.slice(0, 36) + " ..."}
                                                    </Link></h4>
                                                    <div className="bd-blog-author">
                                                        <div className="bd-blog-author-info">
                                                            <img src={authLogo} alt="author image not found" />
                                                            <h6 className="bd-blog-author-info-title">Gia Davies</h6>
                                                        </div>
                                                        <div className="bd-blog-author-link">
                                                            <Link to={`/blog/${blogs.permalink}`}>Read More <i ><FontAwesomeIcon icon={faArrowRight} /></i></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}

                </div>
            </section>


        </div>
    )
}

export default CompanyBlogs