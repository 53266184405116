import React from 'react'
import '../Styles/Testimonial.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonial = () => {


    const settings12 = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 6000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div>

            <section className="bd-testimonial-area gray-bg pt-100 pb-50">
                <div className="container" style={{ maxWidth: "1300px" }}>

                    <div className="row media_test">
                        <div className="col-xl-8 media_testimonial">

                            <div className="bd-testimonial swiper-container testimonial-text mb-50">

                                <div className="swiper-wrapper">
                                    <Slider {...settings12}>
                                        <div className="swiper-slide">
                                            <div className="bd-testimonial-item text-center">
                                                <div className="bd-testimonial-icon mb-45">
                                                    <i className="fas fa-quote-left"></i>
                                                </div>
                                                <p className="mb-20">Best place for all your IT needs. One stop solution for Web design, marketing, SEO and application development. You will get plenty of creative ideas about your business. Polite and workaholic staff. Peaceful environment. Visionary founder.</p>
                                                <div className="home-testimonial-img"  >
                                                    <div className="bd-testimonial-img first-test1">
                                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659450735/website/logo/airdynamic-logo_dvugnn.png" alt="img not found" style={{ width: "190px", marginLeft: "50px" }} />
                                                    </div>
                                                    <div className="first-test2" >
                                                        <h3 className="bd-testimonial-title" >Ameer Munir</h3>
                                                        <span className="testimonial-name pb-20">Founder, Air Dynamic Solutions</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="swiper-slide">
                                            <div className="bd-testimonial-item text-center">
                                                <div className="bd-testimonial-icon mb-45">
                                                    <i className="fas fa-quote-left"></i>
                                                </div>
                                                <p className="mb-20">Working with Zithas has been a wonderful experience. Their team are super friendly, helpful, professional and knowledgeable. I highly recommended Zithas because of great things to do. Very Experienced and trustworthy!</p>
                                                <div className="home-testimonial-img">
                                                    <div className="bd-testimonial-img second-test1">
                                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659450735/website/logo/alfredimmigration_ipg4me.jpg" alt="img not found" style={{ width: "90px", marginLeft: "100px" }} />
                                                    </div>
                                                    <div className="first-test2">
                                                        <h3 className="bd-testimonial-title">Alyson Alfred</h3>
                                                        <span className="testimonial-name pb-20">Founder of Alfred Immigration Services</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="bd-testimonial-item text-center">
                                                <div className="bd-testimonial-icon mb-45">
                                                    <i className="fas fa-quote-left"></i>
                                                </div>
                                                <p className="mb-20">Professional, Reliable and Friendly company to work with. Project was completed without any hassle, helpful staff available any time of the day. Will recommend to anyone who would want to use Zithas Technologies. Definitely looking forward to working with them again.</p>
                                                <div className="home-testimonial-img">
                                                    <div className="bd-testimonial-img third-test1" style={{ width: "35%" }}>
                                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659450735/website/logo/addon_qttxql.png" alt="img not found" style={{ width: "150px", marginLeft: "50px" }} />
                                                    </div>
                                                    <div className="third-test2">
                                                        <h3 className="bd-testimonial-title">Mehul Goswami</h3>
                                                        <span className="testimonial-name pb-20">CEO, Addon Technology</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="bd-testimonial-item text-center">
                                                <div className="bd-testimonial-icon mb-45">
                                                    <i className="fas fa-quote-left"></i>
                                                </div>
                                                <p className="mb-20">I and team are very pleased with our new developed site and will continue to use Zithas for ALL of our web development projects. They are definitely an incredible team. My Team and I highly recommend their services! Thanks Team Zithas for a job well done!</p>

                                                <div className="home-testimonial-img">
                                                    <div className="bd-testimonial-img fourth-test1">
                                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659450735/website/logo/prism-logo_fvb4gp.png" alt="img not found" style={{ width: "135px", marginLeft: "50px", }} />
                                                    </div>
                                                    <div className="third-test2">
                                                        <h3 className="bd-testimonial-title">Sameer Shah</h3>
                                                        <span className="testimonial-name pb-20">Marketing Head, Prism Industries</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="bd-testimonial-item text-center">
                                                <div className="bd-testimonial-icon mb-45">
                                                    <i className="fas fa-quote-left"></i>
                                                </div>
                                                <p className="mb-20">It has been a pleasure working with Zithas Technologies. Thank you for your outstanding support throughout the our website development. I trust that we will soon have new Custom projects to work on together. thanks for your all types support.</p>
                                                <div className="home-testimonial-img">
                                                    <div className="bd-testimonial-img first-test1" >
                                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659450735/website/logo/Faiza_logo_qhw67w.gif" alt="img not found" style={{ width: "190px", marginLeft: "50px" }} />
                                                    </div>
                                                    <div className="first-test2">
                                                        <h3 className="bd-testimonial-title">Faiza</h3>
                                                        <span className="testimonial-name pb-20">Professional Photographer</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="bd-testimonial-item text-center">
                                                <div className="bd-testimonial-icon mb-45">
                                                    <i className="fas fa-quote-left"></i>
                                                </div>
                                                <p className="mb-20">Thank you so much Zithas. The functionality is fantastic!!I love to work on new CRM developed and it looks so good as a smaller platform. I am very, very happy with the work you guys have done. Thanks again - and pass my thanks to your team too :)</p>
                                                <div className="home-testimonial-img">
                                                    <div className="bd-testimonial-img sixth-test1">
                                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659450735/website/logo/tvm_gqehce.png" alt="img not found" style={{ width: "150px", marginLeft: "20px" }} />
                                                    </div>
                                                    <div className="sixth-test2">
                                                        <h3 className="bd-testimonial-title">Bhumit Sangharajaka</h3>
                                                        <span className="testimonial-name pb-20">Head Manager, The Visa Managers</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="bd-testimonial-item text-center">
                                                <div className="bd-testimonial-icon mb-45">
                                                    <i className="fas fa-quote-left"></i>
                                                </div>
                                                <p className="mb-20">We have great experience with Zithas for our Website and Custom CRM development. Innovative team and get the work done as per our requirement. Amazingly satisfied and highly recommended. Thanks a lot Zithas team for your support!</p>

                                                <div className="home-testimonial-img">
                                                    <div className="bd-testimonial-img last-test1" >
                                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659450735/website/logo/hypertechnology-logo_cu2hkx.png" alt="img not found" style={{ width: "150px", marginLeft: "50px", paddingTop: "20px" }} />
                                                    </div>
                                                    <div className="last-test2" >
                                                        <h3 className="bd-testimonial-title">Nikunj Sharma</h3>
                                                        <span className="testimonial-name pb-20">Director, Hyper Technology</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 pl-20 second_width_test">
                            {/* <div className="bd-service-test " style={{backgroundColor:"white"}}> */}
                            <div className="testimonial_video pt-20 pl-40">

                                <iframe src="https://www.youtube-nocookie.com/embed/EyE6_8Uklpg?autoplay=0&rel=0" className="resp-videos "
                                    allowFullScreen="allowFullScreen" frameBorder="0" width="535" height="300" title="Everytime You Go Away - Paul Young" />
                            </div>
                            {/* </div> */}

                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


export default Testimonial;