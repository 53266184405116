import React, { useEffect, useState } from 'react'
import Footer from '../Components/Footer'
import Header1 from '../Components/seperate Components/Header1'
import axios from "axios"
import Spinner from '../Components/seperate Components/Spinner'
import Pagination from '../Components/seperate Components/Pagination'
import { Helmet } from 'react-helmet'

const AllGallery = () => {

    const [gallery, SetGallery] = useState([]);
    const [isLoading, SetLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage] = useState(9);

    useEffect(() => {
        const fetch = async () => {
            try {
                const { data } = await axios.get("https://mars.zithas.com/api/gallery");
                SetGallery(data);
                SetLoading(false);
            } catch (err) {
                console.error(err);
            }
        };
        fetch();
    }, []);

    const indexOfLastPage = currentPage * postPerPage;
    const indexOfFirstPage = indexOfLastPage - postPerPage;
    const currentPosts = gallery.slice(indexOfFirstPage, indexOfLastPage);
    const paginate = currentPage => {
        setCurrentPage(currentPage);
    }



    return (
        <div>
            <Helmet>
                <title>Life at Zithas Technologies</title>
                <meta name='description' content="Experience a vibrant work culture at Zithas Technologies, where innovation, collaboration, and growth define the essence of life within our team." />
            </Helmet>

            <Header1 />

            <div className="bd-page-title-area bd-page-title-bg_career pt-175 pb-155 main-heading" style={{ height: "380px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="bd-page-title text-center z-index">
                                <h2 className="breadcrumb-title" style={{ fontSize: '50px', color: "#4a026c" }}>Gallery</h2>
                                <div className="breadcrumb-menu">
                                    <nav className="breadcrumb-trail breadcrumbs">
                                        <p style={{ color: "black" }}>How We're Helping You Achieve Your Career Goals!</p>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="bd-service-area main-top-service pt-80 ">
                <div className="container" style={{ maxWidth: "1200px" }}>
                    {isLoading ? (<Spinner />) : (
                        <div className="row">
                            {currentPosts.map((img) => {
                                return (
                                    <div className="col-lg-4 col-md-6 pt-20 pl-20 pr-20">
                                        <div className="swiper-slide">
                                            <div className="bd-team-four mb-30" >
                                                <img src={img.link} style={{ height: "300px", width: "100%" }} alt="porfolio not found" />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    )}
                </div>
            </section>

            <Pagination
                postPerPage={postPerPage}
                totalPosts={gallery.length}
                paginate={paginate}
            />

            <Footer />
        </div>
    )
}

export default AllGallery