import React, { useState } from 'react'
import Header1 from '../../Components/seperate Components/Header1'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faCheck, faPhone } from '@fortawesome/free-solid-svg-icons'
import Footer from '../../Components/Footer';
import "../../Styles/Redirecting Styles/NavbarStyles/mobile.css"
import Shape2 from "../../assets/brand/choose-shape-2.png";
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss'
import { Helmet } from 'react-helmet';


const MobileApp = () => {

    const [isOpen, setOpen] = useState(false)

    return (
        <div>
            <Helmet>
                <title>Elevate Your Business with India's Top Mobile App Companies</title>
                <meta name='description' content='Your search for the best mobile app development company in India ends here. We offer top-tier mobile application services for success.' />
                <meta name="keywords" content="mobile app development,best mobile app development company,mobile app development company,app development company,mobile app development services,best app development company,top mobile app company,top mobile app development company,mobile app development company in india,app development company india,mobile app companies,mobile application development,mobile app development india" />
            </Helmet>

            <Header1 />

            {/* Banner section */}
            <div className="bd-page-title-area bd-page-title-bg4 pt-220 pb-155" style={{ height: "380px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="bd-page-title text-center z-index" style={{ bottom: "45px" }}>
                                <h2 className="breadcrumb-title1 mobile-main-title" >Mobile Apps Development</h2>
                                <div className="breadcrumb-menu">
                                    <nav className="breadcrumb-trail breadcrumbs">
                                        <p style={{ color: "black" }}>Unleash the power of mobile technology to empower your business.</p>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* First Section */}
            <section className="bd-choose-area fix pb-80 pt-80">
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="bd-choose-shape">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 order-2 order-lg-1 pr-20">
                                <div className="bd-choose-text mb-30 z-index">
                                    <div className="bd-section-title-wrapper mb-25">
                                        {/* <h5 className="bd-section-subtitle mb-15">Why Choose Us</h5> */}
                                        <h2 className="bd-section-title mb-15 first-mobile-tittle" >
                                            Enhance Your Business With  <span style={{ color: "#ffbf1e" }}>Mobile Application</span>
                                        </h2>
                                    </div>

                                    <p
                                        className="mb-20"
                                        style={{ width: "95%", fontSize: "18px" }}
                                    >
                                        There are a few key benefits of using a mobile application in your business. First, your customers can access your application from any device they have access to. This makes it easier for them to stay connected with your business, and allows you to stay up-to-date on customer needs and preferences. Additionally, mobile applications offer a more efficient way to communicate with your customers. By using quick and easy tools, you can quickly and easily create and manage your customer interactions.
                                    </p>
                                    <p className="mb-20" style={{ fontSize: "18px" }}>A mobile application can be used to manage important business tasks, such as orders, tolls, and reservations. Additionally, a mobile application can be used to store and access important personal information. A mobile application also provides a convenient way to stay connected with friends and family.</p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 order-1 order-lg-2">
                                <div className="bd-choose-img text-end  z-index">
                                    <div className="row align-items-center custom-mar-15 top-resp">
                                        <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                                            <div className="bd-choose-img-inner mb-15 bd-choose-img-inner-1">
                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659376075/website/mobile-apps/mobile-apps-sec-1.2_pc9sgx.jpg" className="bordertop-img1" alt="img not found" />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                                            <div className="bd-choose-img-inner mb-15">
                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659376076/website/mobile-apps/mobile-apps-sec-1.1_eegvjs.jpg" className="bordertop-img2" alt="img not found" />
                                            </div>
                                            <div className="bd-choose-img-inner mb-15">
                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659376075/website/mobile-apps/mobile-apps-sec-1.3_cjon0a.jpg" className="bordertop-img3" alt="img not found" />
                                            </div>
                                        </div>
                                    </div>
                                    <img
                                        className="bd-choose-shape bd-choose-shape-2"
                                        src={Shape2}
                                        alt="shape img not found"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalVideo autoplay isOpen={isOpen} channel='youtube' videoId='u-2jURch9-4' onClose={() => setOpen(false)}>
                </ModalVideo>
                <button className="btn btn responsive_digital first_pop_mobile" onClick={() => setOpen(true)}>
                    <FontAwesomeIcon icon={faPlay} className="play_bt_vid play_transition" />
                </button>
            </section>


            {/* Secound section*/}
            <section className="bd-choose-area  fix">
                <div className="container" style={{ maxWidth: "1300px" }}>
                    <div className="bd-choose-shape">
                        <div className="row second-mobile-responsive">
                            <div className="col-xl-6 col-lg-6 order-2 order-lg-1 second-mobile-section1" >
                                {/* <div className="ablog ablog-4 mb-60"> */}
                                <h2 className="bd-section-title mb-10 ml-15 four-point-tittle">Significantly Alter How Your Business Runs</h2>
                                <div className="bd-choose-text z-index">
                                    <div className="bd-section-title-wrapper">
                                        <p className="ml-25 mobile-para1">Mobile application development can change the business by making it easier to access information and make transactions on the go. By using a mobile application, businesses can save time and energy by not having to carry around multiple devices. Additionally, using a mobile application can help businesses stay connected with their customers, as well as grow their online presence.</p>

                                    </div>
                                    <div>

                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                            <div className="col-xl-6 col-lg-6 order-1 order-lg-2 mt-0 second-mobile-section2" >
                                <div className="bd-choose-img mb-30 z-index">
                                    <div className="bd-choose-info-wrapper">
                                        <div className="container">
                                            <div className="row mb-50">
                                                <div className="col four-section mr-50">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15">
                                                            <i>
                                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440751/website/icons/billboard-64_d0rued.png" />
                                                            </i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2 className="bd-choose-info-title mb-15" style={{ color: "#5e5858" }}>Increased Brand Awareness</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15">
                                                            <i>
                                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440751/website/icons/business-64_qauov3.png" />
                                                            </i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2 className="bd-choose-info-title mb-15" style={{ color: "#5e5858" }}>Higher Business Engagement Level </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row ml-15">
                                            <div className="col four-section2 mr-50">
                                                <div className="animate-hover">
                                                    <div className="bd-choose-info-icon mb-15">
                                                        <i>
                                                            <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440751/website/icons/communication-64_suewof.png" />
                                                        </i>
                                                    </div>
                                                    <div className="bd-choose-info-text">
                                                        <h2 className="bd-choose-info-title mb-15" style={{ color: "#5e5858" }}>Direct Communication Options</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="animate-hover">
                                                    <div className="bd-choose-info-icon mb-15">
                                                        <i>
                                                            <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440751/website/icons/revenue-64_yqhgif.png" />
                                                        </i>
                                                    </div>
                                                    <div className="bd-choose-info-text">
                                                        <h2 className="bd-choose-info-title mb-15" style={{ color: "#5e5858" }}>Boosting Sales & Revenues</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Third Section */}

            <section className="bd-about-six-area  pt-80">
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <h2 className="bd-section-title mb-20 third-mobile-tittle" >Right <span style={{ color: "#ffbf1e" }}>Mobile Application </span>, Make Business More Efficient And Competitive </h2>
                    <ul style={{ color: "black", fontSize: "20px", listStyle: "none" }}>
                        <li>Mobile application development can include various stages, including design, development, testing, and launch. A mobile application can be both a native app and a hybrid app, depending on how it is designed.</li>
                    </ul>
                    <div className="bd-service service-mobile mb-30 mt-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                        <div className="row third-mobile-section1">
                            <div className="col-lg-6 mobile-subpoint-section">
                                <h4 style={{ fontWeight: "600" }}>Native Mobile Application</h4>
                                <div className="bd-about-six-text">
                                    <div className="bd-section-title-wrapper mb-25">
                                        <p style={{ fontSize: "18px", lineHeight: "1.75" }}>Native applications are smartphone programs created exclusively for mobile operating systems. Swift for iOS, Java, and Kotlin for Android, as examples. Applications that are native to one platform cannot run on another. These programs were created specifically for that platform. On the App Store or Google Play Store, native software can be updated.</p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-6 col-md-8 mobile-subpoint-section " >
                                <div className="bd-about-six-img">
                                    <div className="bd-about-six-feature six-feature-mobile pl-140">
                                        <h4 className="mb-15" style={{ fontWeight: "600" }}>Key Features</h4>
                                        <ul className="">
                                            <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> Accessed by the icons on the home screen</li>
                                            <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> Fastest and provide a reliable experience</li>
                                            <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> Advantage of numerous mobile features</li>
                                            <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> Application works in offline mode</li>
                                            <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> Tends to Have Fewer Bugs</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bd-service mb-30 service-mobile" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                        <div className="row third-mobile-section1">
                            <div className="col-lg-6 mobile-subpoint-section">
                                <h4 style={{ fontWeight: "600" }}>Hybrid Mobile Application</h4>
                                <div className="bd-about-six-text">
                                    <div className="bd-section-title-wrapper mb-25">
                                        <p style={{ fontSize: "18px", lineHeight: "1.75" }}>Web browsers and app stores both allow users to access and download hybrid applications. Hybrid applications are created using JavaScript and HTML5, just like web applications. Apps that are hybrid require minimal upkeep. Hybrid apps are never as quick as native apps because their speed is solely dependent on the speed of the browser. It is made to render the content of an existing website in an application manner.</p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-6 col-md-8 mobile-subpoint-section">
                                <div className="bd-about-six-img">
                                    <div className="bd-about-six-feature six-feature-mobile pl-140">
                                        <h4 className="mb-15" style={{ fontWeight: "600" }}>Key Features</h4>
                                        <ul className="">
                                            <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> Work on various platforms</li>
                                            <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> The maintenance is simple</li>
                                            <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i>High-speed capability</li>
                                            <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> Attractive user interface</li>
                                            <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> Target a Wider User Base</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Fourth Section */}

            <section className="bd-about-six-area pt-70">
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row">
                        <h2 className="bd-section-title mb-15 fourth-sec-tittle"><span style={{ color: "#ffbf1e" }}>Mobile Application </span>  is an Essential Part of Any Business. </h2>
                        <div className="col-lg-6 " style={{ width: "100%" }}>
                            <div className="bd-about-six-text">
                                <div className="bd-section-title-wrapper ">
                                    <div className="bd-about-six-feature">
                                        <p style={{ fontSize: "20px", fontWeight: "600", marginBottom: "25px" }}>There are many companies that offer mobile application services, so it is important to select the right one for business.</p>
                                        <ul className="">
                                            <li style={{ fontSize: "22px", fontWeight: "600" }}><i><FontAwesomeIcon icon={faCheck} /></i> Do your research </li>
                                            <p className="pb-20 pl-25">Before choosing a company to develop your mobile application, it is important to do your research and find out what they offer. This will help you choose the right company for your specific needs.</p>

                                            <li style={{ fontSize: "22px", fontWeight: "600" }}><i><FontAwesomeIcon icon={faCheck} /></i> Look for features</li>
                                            <p className="pl-25" >When looking for a company to develop your mobile application, be sure to consider features that are important to your business. These features may include customer loyalty, advertising, and functionalities that are specific to your industry or product.</p>
                                            <p className="pl-25" >We have a strong focus on customer service and developing applications that are easy to use and navigate. We take the time to understand your needs and work with you to create an application that is both innovative and successful.</p>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Fiveth Section */}

            <section className="bd-service-details-area pt-80 pb-80">
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row " >
                        <div className="col-lg-8" style={{ width: "100%" }}>
                            <div className="bd-service-details1">
                                <div className="row pb-10 fifth-responsive-sec">
                                    <div className="col-xl-5 col-lg-6 mobile-fifth-sec1" >
                                        <h5 className="mb-10 fourth-sec-tittle">The Team of Tech Enthusiasts People Ready to Assist Your Business</h5>
                                        <div className="bd-service-details-feature ">
                                            <div>
                                                <p style={{ fontSize: "17px" }}>Our team can help you create an intelligent and efficient mobile application that will be perfect for your business. We can also help you design and develop your mobile application in a way that will be easy to use and navigate. With team of experienced mobile application developers, you can be sure that your business will not only run more efficiently but also look great while doing so. Contact us today to learn more about how we can help you create the perfect mobile application for your business.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-7 col-lg-6 mobile-fifth-sec2" >
                                        <div className="bd-service-details-feature-img" style={{ paddingTop: "10px" }}>
                                            <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659413871/website/mobile-apps/mobile-apps-sec-2_xzcw4h.jpg" alt="img not found" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* CTA */}
            <section className="bd-video-area pt-135 pb-130" style={{ backgroundColor: "#5c0385" }}>
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row responsive-banner">
                        <div className="col-lg-12 first_section">
                            <div className="bd-video-text">
                                <h2 className="bd-video-title first-para-resp" >Looking for App Development Expert?</h2>
                                <p className="contact_section second-para-resp">You're at the right place. The right time</p>
                                <p className="contact_subtext">Surprisingly great App Development, Let's get started right now !</p>
                            </div>
                        </div>
                        <div className="col-lg-12 second_section">
                            <div className="contact-stuff pt-30">
                                <div className="contact_icon icon_respon pt-15 pl-50">
                                    <FontAwesomeIcon icon={faPhone} style={{ fontSize: "30px" }} />
                                </div>
                                <div className="contact_txt ">
                                    <p className="contact-sub-text">CALL US NOW</p>
                                    <a href="tel:(+91)720-288-2277" style={{ fontSize: "17px" }} className="mbb-5 pb-20">(+91) 720-288-2277</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default MobileApp