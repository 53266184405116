import React, { useState, useEffect } from "react";
import "../../Styles/Redirecting Styles/WebDevelopment.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Spinner from "../../Components/seperate Components/Spinner";

const CallBack = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [service, setService] = useState("");

  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(true);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isClick, setClick] = useState(false);
  const closePopup = () => {
    setClick(false);
  };

  const location = useLocation()
  const onHandleSubmit = async (e) => {
    const link = location.pathname?.split('/')
    let Location;
    if (link.includes('ca') == true) {
      Location = 3
    } else {
      Location = 1
    }

    e.preventDefault();
    setLoading(true);
    setSpinner(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("contact", contact);
    formData.append("service", service);
    formData.append("location", Location);

    try {
      const response = await axios({
        method: "post",
        url: "https://mars.zithas.com/api/add_client",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === 200) {
        setLoading(false);
        setSpinner(false);
        setName('')
        setContact('')
        setService('')
        // swal({
        //   icon: "success",
        //   text: "Your message has been sent Successfully"
        // })
        setIsSubmitted(true);
        setTimeout(() => {
          setClick(false);
          setIsSubmitted(false);
        }, 5000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div>
        <button
          className={`callback-button ${isVisible ? "visible" : ""}`}
          onClick={() => {
            setClick(!isClick);
          }}
        >
          Request a call back
        </button>
      </div>
      {isClick == true ? (
        <div className={isSubmitted == true ? "success-popup shadow" : "callback-form shadow"} style={{ zIndex: "99999" }}>
          {isSubmitted ? (
            <div className="text-center p-2">
              <i className="fas fa-check fs-2 bg-success-green p-3 text-white rounded-circle "></i>
              <h5 className="text-center mt-4">
                Your message has been sent Successfully
              </h5>
            </div>
          ) : (
            <div>
              <div className="modal-header" style={{ border: "none" }}>
                <h5 className="modal-title" id="exampleModalLabel">
                  Fill out the Details!
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  style={{ marginLeft: "50px" }}
                  onClick={closePopup}
                ></button>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ width: "100%" }} className="pe-4 ps-3">
                  {spinner && <Spinner />}
                  <form onSubmit={onHandleSubmit}>
                    <div className="row" style={{ paddingTop: "23px" }}>
                      <div className="bd-contact-field mb-20">
                        <input
                          className="blog-form form-tags"
                          type="text"
                          placeholder="Name"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>{" "}
                      <div className="bd-contact-field mb-20 ">
                        <input
                          type="text"
                          className="form-tags"
                          placeholder="Contact No"
                          name="contact"
                          value={contact}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          maxLength={10}
                          onChange={(e) => setContact(e.target.value)}
                          required
                        />
                      </div>
                      <div className="bd-contact-field mb-20 ">
                        <div className="bd-contact-field mb-20 ">
                          <select
                            className="form-select form-select-sm"
                            aria-label=".form-select-sm example"
                            name="service"
                            value={service}
                            onChange={(e) => setService(e.target.value)}
                            style={{
                              width: "100%",
                              height: "40px",
                              border: "none",
                              borderRadius: "10px",
                              color: "var(--clr-body-text)",
                              fontWeight: "400",
                              fontSize: "16px",
                              paddingLeft: "20px",
                            }}
                            required
                          >
                            <option
                              style={{
                                color: "var(--clr-body-text)",
                                fontSize: "16px",
                              }}
                            >
                              Select the Services
                            </option>
                            <option value="Business Audit">
                              {" "}
                              Business Audit{" "}
                            </option>
                            <option value="Bespoke System">Bespoke System</option>
                            <option value="Business Automation">
                              Business Automation
                            </option>
                            <option value="Software Development">
                              Software Development
                            </option>
                            <option value="Mobile Apps Development">
                              Mobile Apps Development
                            </option>
                            <option value="Dedicated Resource">
                              Dedicated Resource
                            </option>
                            <option value="Digital Marketing">
                              Digital Marketing
                            </option>
                            <option value="Hosting Services">
                              Hosting Services
                            </option>
                            <option value="MICRO Services">MICRO Services</option>
                            <option value="Maintenance Packages">
                              Maintenance Packages
                            </option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12 mb-20 text-center">
                        <div className="bd-contact-field">
                          <button
                            type="submit"
                            className="theme-btn submit-chat"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default CallBack;
