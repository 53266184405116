import React from "react";
import Header1 from "../../Components/seperate Components/Header1";
import Footer from "../../Components/Footer";

const Arkzi = () => {
  return (
    <div>
      <Header1 />
      <div className="mt-80 pt-5">
        <iframe
          src="https://pinnacle.in/embedded-signup/fb-signup.php?token=T6aDI86I5HaLl%2FRtSaGBbWd3j%2FW7zXOWfOLKl5vq5wcwjmT5TpTUaJFU1qNQWB4o&app_id=JKFtLPg%2FxXTfTDmA9KMK55tHTlYLYCcnfoIr2nQy9IE%3D&config_id=cyczzmTBWcL38wFd6CHH15tHTlYLYCcnfoIr2nQy9IE%3D&solution_id=7ccRg1OmmkBWOr70%2BGHYA5tHTlYLYCcnfoIr2nQy9IE%3D&mode=syY6PAgG1PR5fAG1p89cCw%3D%3D&themecolor=001d4c&app_secret=w3dTqbiQA99cmrCwqVdkhhgEUQyxpNGbQvZtckH38LGbR05WC2AnJ36CK9p0MvSB
"
          width="100%"
          style={{ height: "90vh" }}
          frameborder="0"
        ></iframe>
      </div>

      <Footer />
    </div>
  );
};

export default Arkzi;
