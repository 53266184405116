import React from 'react'
import "../../Styles/seperate Comp Css/Modal.css"

const Spinner = () => {
  return (
    <div>
      <section className="spinner" style={{ textAlign: "center" }}>
        <div className="spinner-border text-center" role="status" style={{ color: "var(--clr-body-heading)", marginTop: "25%" }}>
          <span className="visually-hidden">Loading...</span>
        </div>
      </section>

    </div>
  )
}

export default Spinner