import React, { useState, useEffect } from 'react'
import Header1 from '../../Components/seperate Components/Header1'
import Footer from '../../Components/Footer';
import { Link, Outlet, useParams } from 'react-router-dom'
import axios from 'axios';
import Spinner from '../../Components/seperate Components/Spinner';
import CompanyBlogs from '../../Components/seperate Components/CompanyBlogs';
import Pagination from '../../Components/seperate Components/Pagination';
import "../../Styles/Redirecting Styles/FooterStyle/BlogDetails.css";
import { Helmet } from 'react-helmet';

const Blog = ({ comments }) => {



    const params = useParams()

    const [posts, setPosts] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage] = useState(9);

    console.log(posts)

    useEffect(() => {
        axios.get("https://mars.zithas.com/api/blog")
            .then(res => {
                setPosts(res.data);
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
            })
    }, [params.id])

    // Get Current Post for

    const indexOfLastPage = currentPage * postPerPage;
    const indexOfFirstPage = indexOfLastPage - postPerPage;
    const currentPosts = posts.slice(indexOfFirstPage, indexOfLastPage);
    const paginate = pageNumber => {
        setCurrentPage(pageNumber);
    }


    return (

        <div>
            <Helmet>
                <title>Read our blogs on the latest tools and technologies</title>
                <meta name='description' content="Stay updated with cutting-edge tech trends. Explore our insightful blogs on the latest tools and technologies. Expert insights await!" />
            </Helmet>
            <Header1 />

            {/* Banner section */}
            <div className="bd-page-title-area bd-page-title-bg_blog pt-175 pb-155" style={{ height: "380px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="bd-page-title text-center z-index">
                                <h2 className="breadcrumb-title" style={{ fontSize: '50px', color: "#4a026c" }}>Blog</h2>
                                <div className="breadcrumb-menu">
                                    <nav className="breadcrumb-trail breadcrumbs">
                                        <p style={{ color: "black" }}>Our Blog is full of examples of technologies we're working on and their benefits.</p>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Blogs section */}
            <CompanyBlogs posts={currentPosts} isLoading={isLoading} comments={comments} />

            <Pagination
                postPerPage={postPerPage}
                totalPosts={posts.length}
                paginate={paginate}
            />

            {/* footer section*/}




            <Footer />

            <Outlet />

        </div>
    )
}

export default Blog