import React from 'react'
import trustPilot from "../../assets/logo/trustpilot.png"
import Spinner from './Spinner'

const CompanyBlog = ({ video, text, isLoading }) => {

    return (
        <div>
            {isLoading ? (<Spinner />) : (
                <>

                    <section className="bd-service-area  ">
                        <div className="container" style={{ maxWidth: "1270px" }}>
                            <div className="row">
                                {video.slice(0, 2).map((content) => {
                                    return (
                                        <div className="col-lg-4 col-md-6 first-resp-section mt-80" >
                                            <div className="bd-service" style={{ backgroundColor: "var(--clr-bg-gray)", paddingRight: "17px", paddingLeft: "18px", paddingTop: "13px", paddingBottom: "14px" }}>
                                                <div className="testimonial_video">
                                                    <iframe src={content.video_link} className="resp-videos" allowFullScreen="allowFullScreen" frameBorder="0" width="576" height="300" title="Everytime You Go Away - Paul Young" />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </section>

                    {text.slice(0, 2).map((content) => {
                        return (
                            <section className="bd-service-area">
                                <div className="container" style={{ maxWidth: "1270px" }}>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 mt-80 " style={{ width: "100%" }}>
                                            <div className="bd-service mb-30 responsive-main_testimonial " style={{ backgroundColor: "var(--clr-bg-gray)", height: "100%" }}>
                                                <div className="comments-box box-responsive">
                                                    <div className="comments-avatar mr-40 test-first-section1" style={{ width: "40%" }}>
                                                        {/* <img src={content.company_logo} className="img-fluid mb-20" style={{ width: "100px" }} alt="img" /> */}
                                                        <p style={{ marginBottom: "0px", color: "black", fontWeight: "570" }}>{content.name}</p>
                                                        <p style={{ textAlign: "center", width: "100%", marginBottom: "0px" }}>{content.designation}</p>
                                                        <p className="mb-20">{content.company_name} </p>
                                                        {content.testimony_link == null ? null : <p style={{ marginBottom: "38px" }}> View Review On <br /><a href={content.testimony_link} target="_blank"> <img src={trustPilot} width="35" /><br />TrustPilot </a></p>}

                                                    </div>
                                                    <div className="comments-text pb-0 mb-0" style={{ border: "none", width: "100%" }}>
                                                        <div className="avatar-name">
                                                            <h4>{content.service} </h4>
                                                        </div>
                                                        <p style={{ lineHeight: "1.55", marginBottom: "10px" }}>{content.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </section>
                        )
                    })}


                    <section className="bd-service-area">
                        <div className="container" style={{ maxWidth: "1270px" }}>
                            <div className="row">
                                {video.slice(2).map((content) => {
                                    return (
                                        <div className="col-lg-4 col-md-6 first-resp-section mt-80" >
                                            <div className="bd-service" style={{ backgroundColor: "var(--clr-bg-gray)", paddingRight: "17px", paddingLeft: "18px", paddingTop: "13px", paddingBottom: "14px" }}>
                                                <div className="testimonial_video">
                                                    <iframe src={content.video_link} className="resp-videos" allowFullScreen="allowFullScreen" frameBorder="0" width="576" height="300" title="Everytime You Go Away - Paul Young" />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </section>

                    {text.slice(2, 4).map((content) => {
                        console.log("content", content)
                        return (
                            <section className="bd-service-area ">
                                <div className="container" style={{ maxWidth: "1270px" }}>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 mt-80 " style={{ width: "100%" }}>
                                            <div className="bd-service mb-30 responsive-main_testimonial " style={{ backgroundColor: "var(--clr-bg-gray)", height: "100%" }}>
                                                <div className="comments-box box-responsive">
                                                    <div className="comments-avatar mr-40 test-first-section1" style={{ width: "40%" }}>
                                                        <img src={content.company_logo} className="img-fluid mb-20" style={{ width: "100px" }} alt="img" />
                                                        <p style={{ marginBottom: "0px", color: "black", fontWeight: "570" }}>{content.name}</p>
                                                        <p style={{ textAlign: "center", width: "100%", marginBottom: "0px" }}>{content.designation}</p>
                                                        <p className="mb-0">{content.company_name} </p>
                                                        {content.testimony_link == null ? null : <p style={{ marginBottom: "38px" }}> View Review On <br /><a href={content.testimony_link} target="_blank"> <img src={trustPilot} width="35" /><br />TrustPilot </a></p>}

                                                    </div>
                                                    <div className="comments-text pb-0 mb-0" style={{ border: "none", width: "100%" }}>
                                                        <div className="avatar-name">
                                                            <h4>{content.service} </h4>
                                                        </div>
                                                        <p style={{ lineHeight: "1.55", marginBottom: "10px" }}>{content.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </section>
                        )
                    })}
                </>
            )}
        </div>
    )
}

export default CompanyBlog