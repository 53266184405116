import React, { useEffect, useState } from 'react'
import Header1 from '../../Components/seperate Components/Header1'
import { faCheck, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from '../../Components/Footer';
import '../../Styles/Redirecting Styles/NavbarStyles/Bespoke.css'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { animateScroll as scroll } from 'react-scroll';
import Shape2 from "../../assets/brand/choose-shape-2.png";
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss'
import { Helmet } from 'react-helmet';


const BespokeSystem = () => {

    const toggleContact = () => {
        scroll.scrollToTop(0, 0);
    }



    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [isOpen, setOpen] = useState(false);


    return (
        <div>
            <Helmet>
                <title>Custom Software Development Company | Best ERP & CRM Solutions</title>
                <meta
                    name="description"
                    content="Discover the best custom software development company in India, providing CRM software, ERP systems, and customized solutions for your business needs."
                />
                <meta
                    name="keywords"
                    content="custom software solutions,custom software development,customized software solutions,custom software development company,custom software development company in india,best software development company,software development company in india,software development company,custom software company,crm software,best erp systems"
                />
            </Helmet>

            <Header1 />

            {/* Banner section */}
            <div className="bd-page-title-area bd-page-title-bg1 pt-220 pb-155" style={{ height: "380px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="bd-page-title text-center z-index" style={{ bottom: "45px" }}>
                                <h2 className="breadcrumb-title" style={{ fontSize: '50px', color: "#4a026c" }}>Custom Software Development Expert</h2>
                                <div className="breadcrumb-menu">
                                    <nav className="breadcrumb-trail breadcrumbs">
                                        <p style={{ color: "black" }}>Collaborating with a Custom Software Development Company enhances operational effectiveness and contributes to increased profits for businesses.</p>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* First Section */}

            <section className="bd-about-area">
                <div className="bd-custom-container">
                    <div className="bd-about-four-bg  pt-80 pb-0 fix">
                        <div className="bd-about-shape-box">
                            <div className="container" style={{ maxWidth: "1270px" }}>
                                <div className="row justify-content-center">
                                    <div className="col-xl-6 col-lg-8 first_resp_bespoke">
                                        <div className="bd-choose-img text-end mb-30 top-resp z-index">
                                            <div className="row align-items-center custom-mar-15">
                                                <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                                                    <div className="bd-choose-img-inner mb-15 bd-choose-img-inner-1">
                                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659370866/website/bespoke-system/bespoke-system-sec-1.2_a5uuxu.jpg" className="resp-img bordertop-img1" alt="img not found" />
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                                                    <div className="bd-choose-img-inner mb-15">
                                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659370866/website/bespoke-system/bespoke-system-sec-1.1_gg2ual.jpg" className="resp-img bordertop-img2" alt="img not found" />
                                                    </div>
                                                    <div className="bd-choose-img-inner mb-15">
                                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659370866/website/bespoke-system/bespoke-system-sec-1.3_bmzmjs.jpg" className="resp-img bordertop-img3" alt="img not found" />
                                                    </div>
                                                </div>
                                            </div>
                                            <img
                                                className="bd-choose-shape bd-choose-shape-2"
                                                src={Shape2}
                                                alt="shape img not found" />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-8 second_resp_bespoke" >
                                        <div className="bd-about-text bd-about-text-four mb-40 z-index">
                                            <div className="bd-section-title-wrapper">
                                                <h2 className="bd-section-title mb-15 md-pr-50">Achieve Success Through <span style={{ color: "#ffbf1e" }}>Custom Software Solutions </span></h2>
                                                <p className="">Custom software solutions are tailored to meet the specific needs of a company, ensuring efficiency and effectiveness in its operations. Custom software enables businesses to streamline their processes, boost productivity, and gain a competitive edge in the market. It allows for adaptability to changing market conditions and emerging trends, contributing to long-term success.</p>
                                                <p >Custom software is not just about efficiency; it's about future-proofing your business. In a rapidly evolving tech landscape, off-the-shelf software often becomes outdated quickly. Custom software, however, can grow and evolve with your organization, accommodating changes and scaling as needed. Additionally, it enhances data security, a vital concern in today's interconnected world.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ModalVideo autoplay isOpen={isOpen} channel='youtube' videoId='6wrgJR1n5ao' onClose={() => setOpen(false)}>
                </ModalVideo>
                <button className="btn btn touch_pop_vid first_pop_video_bespoke" onClick={() => setOpen(true)}>
                    <FontAwesomeIcon icon={faPlay} className="play_bt_vid play_transition" />
                </button>
            </section>

            {/* <!-- about area start here --> */}
            <section className="bd-about-six-area ">
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <h2 className="bd-section-title mb-25 bespoke_section2-tittle" >Obtain Customized Software Solutions for Your Business</h2>
                    <div className="row bespoke_responsive">
                        <div className="col-lg-6 first_section_responsive ">
                            <div className="bd-about-six-text">
                                <div className="bd-section-title-wrapper mb-25">

                                    <p >In the rapidly evolving landscape of modern business, the importance of tailored software solutions cannot be overstated. Every business has its unique challenges, goals, and operational requirements. Off-the-shelf software, while readily available, often falls short in addressing these specific needs. This is where customized software solutions come into play. By opting for tailored software development, your business gains access to a solution that is meticulously crafted to match your precise requirements.</p>
                                    <p >Once you’ve determined what features and applications are essential for your business, it’s time to research and select a development partner who can provide the best quality of services. Selecting the right development partner is important because they will be responsible for developing, testing, and launching your custom software solution. By working with a reputable development team, you can be sure that your solution will meet all of your business goals and objectives.Moreover, it provides a heightened level of security, a paramount concern in today's digital landscape. By customizing your software, you can implement robust security measures, protect sensitive data, and position your business for long-term success in an increasingly competitive market.</p>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-8 second_section_responsive">
                            <div className="bd-about-six-img">
                                <div className="bd-about-six-feature">
                                    <ul className="mb-45">
                                        <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> Individually Crafted Solution</li>
                                        <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> Growth & scalability</li>
                                        <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> Return On Investment</li>
                                        <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> Time-saving</li>
                                        <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> Competitive advantage</li>
                                        <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> No integration issues</li>
                                        <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> Easier maintenance</li>
                                        <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> More independent</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            {/* choose section */}
            <section className="bd-choose-area fix">
                <div className="container" style={{ maxWidth: "1300px" }}>
                    <div className="bd-choose-shape pt-70">
                        <div className="row four_resp_section">

                            <div className="col-xl-6 col-lg-6 order-2 order-lg-1 four_point_section1">
                                {/* <div className="ablog ablog-4 mb-60"> */}
                                <div className="bd-choose-img z-index pt-50">

                                    <div className="bd-choose-info-wrapper">
                                        <div className="container">
                                            <div className="row mb-50">
                                                <div className="col cell_resp mr-30">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15">
                                                            <i>
                                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440034/website/icons/vertical-scalability-64_hj9kjd.png" />
                                                            </i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2 className="bd-choose-info-title mb-15" style={{ color: "#5e5858" }}>Scalability and flexibility</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15">
                                                            <i>
                                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440035/website/icons/ownership-64_iwkti6.png" />
                                                            </i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2 className="bd-choose-info-title mb-15" style={{ color: "#5e5858" }}>Complete ownership</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col cell_resp mr-30">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15">
                                                            <i>
                                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440035/website/icons/integration-64_n7ffaa.png" />
                                                            </i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2 className="bd-choose-info-title mb-15" style={{ color: "#5e5858" }}>Fast integration</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col respon_space">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15">
                                                            <i><img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440034/website/icons/trophy-64_t3l2yz.png" /></i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2 className="bd-choose-info-title mb-15" style={{ color: "#5e5858" }}>Competitive advantage</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                {/* </div> */}
                            </div>
                            <div className="col-xl-6 col-lg-6 order-1 order-lg-2 mt-0 four_point_section2">
                                <div className="bd-choose-text z-index">
                                    <h2 className="bd-section-title section_title2">Benefits of Custom Software Solutions</h2>
                                    <div className="bd-section-title-wrapper">
                                        <p style={{ fontSize: "18px", lineHeight: "1.75" }}>Custom software solutions provide a strategic edge to businesses by offering tailor-made functionalities. Customized software is finely tuned to the unique workflows and requirements of a business, optimizing processes and boosting overall productivity. The personalized nature of these solutions not only enhances efficiency but also simplifies user interactions, minimizing training time and maximizing user adoption. innovation.</p>
                                    </div>
                                    <div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Fifth Section */}

            {/* <section className="bd-about-area">
                <div className="bd-custom-container" >
                    <div className="bd-about-four-bg  pt-80 pb-70 fix">
                        <div className="bd-about-shape-box">
                            <div className="container" style={{ maxWidth: "1270px" }}>
                                <div className="row justify-content-center">

                                    <div className="col-xl-6 col-lg-8 fifth_section_respon">
                                        <div className="bd-about-text bd-about-text-four mb-40 z-index">
                                            <div className="bd-section-title-wrapper">

                                                <h2 className="bd-section-title mb-15 bespoke-lastSection_tittle md-pr-50">Make Business Stand out with our Unique Bespoke Solution</h2>
                                                <p className="">With a customized system in place, businesses can save money, and time and have greater control over their operations. From the initial design to the final delivery, our team of experts will help make sure your business is using the right system for the right reasons. From a simple website design to a customized marketing plan, our team can help you get the system that is right for you. With our customized system, you can save time and improve effectiveness. You can also improve your working procedures and manage your business better. Our system is designed to help you grow your business and grow your profits.</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-8 pl-30 fifth_section_respon2">
                                        <div className="bd-about-img mb-30">
                                            <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659412855/website/bespoke-system/bespoke-system-sec-2_ev7qgp.jpg" style={{ height: "470px" }} className="xss-w-full rounded-10" alt="about img not found" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
                <section className="bd-service-area pb-70 pt-70">
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row">
                    </div>
                    <div className="row sixth-main-responsive">
                        <h2 className="mb-30 sixth-section-soft">Tools and Technologies used in the Process</h2>
                        <div className="col-lg-4 col-md-6 sixth-soft-section1">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <p>Frequently used tools in the realm of software development include assets such as Git, code management platforms like GitLab, and build management systems like Jenkins.</p>
                                <table style={{ marginLeft: "50px" }}>
                                    <tbody>
                                        <tr style={{ marginBottom: "20px" }}>
                                            <td className="pb-30" style={{ width: "180px" }}><img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616468/website/icons/filezilla_t4bxqw.png" /></td>
                                            <td className="pb-30" style={{ width: "180px" }}><img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616468/website/icons/visual-studio-code_ouivep.png" /></td>
                                            <td className="pb-30"><img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616468/website/icons/windows-10_kl8cu2.png" /></td>
                                        </tr>

                                        <tr>
                                            <td><img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616468/website/icons/linux-server_p6azlz.png" /></td>
                                            <td><img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616468/website/icons/xcode_hxtmqb.png" /></td>
                                            <td><img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616468/website/icons/postman_bshp0x.png" /></td>
                                        </tr>
                                    </tbody>
                                </table >

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 sixth-soft-section1">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <p>In the sphere of business processes, a broad spectrum of technologies is available for utilization. These include software development tools, a variety of programming languages, and a diverse assortment of application development frameworks.</p>
                                <table style={{ marginLeft: "50px" }}>
                                    <tbody>
                                        <tr style={{ marginBottom: "20px" }}>
                                            <td className="pb-30" style={{ width: "180px" }}><img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/dot-net_rqknrj.png" /></td>
                                            <td className="pb-30" style={{ width: "180px" }}><img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/angularjs_luxe11.png" /></td>
                                            <td className="pb-30"><img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/nodejs_lab0zs.png" /></td>
                                        </tr>
                                        <tr>
                                            <td><img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/flutter_p8yphu.png" /></td>
                                            <td><img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/php_s2e2xk.png" /></td>
                                            <td><img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/react_hmd8zn.png" /></td>
                                        </tr>
                                    </tbody>
                                </table >
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <ServiceBanner/> */}

            <section className="bd-video-area pt-135 pb-130" style={{ backgroundColor: "#5c0385" }}>
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row">
                        <div className="col-lg-12 first_section">
                            <div className="bd-video-text">
                                <h2 className="bd-video-title first-para-resp" >Looking for a Custom Software Development Expert in India?</h2>
                                <p className="contact_section second-para-resp">You're here at the right moment.</p>
                                <p className="contact_subtext">Outstanding Developers, Let's Kickstart Now!</p>
                            </div>
                        </div>
                        <div className="col-lg-12 second_section">
                            <div className="contact-stuff pt-30">
                                <div className="contact_icon pt-15 pl-50">
                                    <FontAwesomeIcon icon={faPhone} style={{ fontSize: "30px" }} />
                                </div>
                                <div className="contact_txt">
                                    <p className="contact-sub-text">CALL US NOW</p>
                                    <a href="tel:(+91)720-288-2277" style={{ fontSize: "17px" }} className="mbb-5 pb-20">(+91) 720-288-2277</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default BespokeSystem