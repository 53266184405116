import React from 'react'
import '../Styles/Brand.css'
import '../assets/css/swiper-bundle.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";




import { useState } from 'react'


export const BrandArea = () => {

  var setting2 = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2000,
          speed: 300,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div>

      <div className="bd-brand-area gray-bg ">
        <div className="container" style={{ maxWidth: "2000px", height: "250px", backgroundColor: "var(--clr-theme-1)" }}>
          <div className="bd-brand-active swiper-container">

            <div className="slider" style={{ paddingTop: "75px" }}>
              <Slider {...setting2}>
                <span className="bd-brand-single  swiper-slide">
                  <a><img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659376757/website/logo/ai-logo_ks3xul.png" style={{ width: "160px", height: "100px" }} alt="brand img not found" /></a>
                </span>
                <span className="bd-brand-single swiper-slide">
                  <a><img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659376756/website/logo/php-logo_rgcxdy.png" style={{ width: "160px", height: "100px" }} alt="brand img not found" /></a>
                </span>
                <span className="bd-brand-single swiper-slide">
                  <a><img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659376757/website/logo/data-analysis-logo_xxggwt.png" style={{ width: "160px", height: "100px" }} alt="brand img not found" /></a>
                </span>
                <span className="bd-brand-single swiper-slide">
                  <a><img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659376757/website/logo/dotnet-logo_ct58z6.png" style={{ width: "160px", height: "100px" }} alt="brand img not found" /></a>
                </span>
                <span className="bd-brand-single swiper-slide">
                  <a><img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659376756/website/logo/python-logo_w9dgyu.png" style={{ width: "160px", height: "100px" }} alt="brand img not found" /></a>
                </span>
                <span className="bd-brand-single swiper-slide">
                  <a><img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659376756/website/logo/node-logo_brz0fb.png" style={{ width: "160px", height: "100px" }} alt="brand img not found" /></a>
                </span>
                <span className="bd-brand-single swiper-slide">
                  <a><img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659376756/website/logo/power-bi-logo_ic45ti.png" alt="brand img not found" /></a>
                </span>
                <span className="bd-brand-single swiper-slide">
                  <a><img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659376756/website/logo/java-script-logo_jpwekg.png" alt="brand img not found" /></a>
                </span>
                <span className="bd-brand-single swiper-slide">
                  <a><img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659376757/website/logo/aws-logo_a7diyn.png" alt="brand img not found" /></a>
                </span>
                <span className="bd-brand-single swiper-slide">
                  <a><img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659376756/website/logo/react-logo_nla85v.png" alt="brand img not found" /></a>
                </span>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
