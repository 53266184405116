import React, { useEffect, useState } from 'react'
import Header1 from '../../Components/seperate Components/Header1'
import { faCheck, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from '../../Components/Footer';
import '../../Styles/Redirecting Styles/NavbarStyles/Bespoke.css'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { animateScroll as scroll } from 'react-scroll';
import Shape2 from "../../assets/brand/choose-shape-2.png";
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss'
import { Helmet } from 'react-helmet';


const BespokeSystem = () => {

    const toggleContact = () => {
        scroll.scrollToTop(0, 0);
    }



    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [isOpen, setOpen] = useState(false);


    return (
        <div>
            <Helmet>
                <title>Custom Software Development Company | Best ERP & CRM Solutions</title>
                <meta
                    name="description"
                    content="Discover the best custom software development company in India, providing CRM software, ERP systems, and customized solutions for your business needs."
                />
                <meta
                    name="keywords"
                    content="custom software solutions,custom software development,customized software solutions,custom software development company,custom software development company in india,best software development company,software development company in india,software development company,custom software company,crm software,best erp systems"
                />
            </Helmet>

            <Header1 />

            {/* Banner section */}
            <div className="bd-page-title-area bd-page-title-bg1 pt-220 pb-155" style={{ height: "380px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="bd-page-title text-center z-index" style={{ bottom: "45px" }}>
                                <h2 className="breadcrumb-title" style={{ fontSize: '50px', color: "#4a026c" }}>Bespoke System</h2>
                                <div className="breadcrumb-menu">
                                    <nav className="breadcrumb-trail breadcrumbs">
                                        <p style={{ color: "black" }}>Working with a Bespoke System Builder, Businesses can increase Productivity and make Profits.</p>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* First Section */}

            <section className="bd-about-area">
                <div className="bd-custom-container">
                    <div className="bd-about-four-bg  pt-80 pb-0 fix">
                        <div className="bd-about-shape-box">
                            <div className="container" style={{ maxWidth: "1270px" }}>
                                <div className="row justify-content-center">
                                    <div className="col-xl-6 col-lg-8 first_resp_bespoke">
                                        <div className="bd-choose-img text-end mb-30 top-resp z-index">
                                            <div className="row align-items-center custom-mar-15">
                                                <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                                                    <div className="bd-choose-img-inner mb-15 bd-choose-img-inner-1">
                                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659370866/website/bespoke-system/bespoke-system-sec-1.2_a5uuxu.jpg" className="resp-img bordertop-img1" alt="img not found" />
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                                                    <div className="bd-choose-img-inner mb-15">
                                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659370866/website/bespoke-system/bespoke-system-sec-1.1_gg2ual.jpg" className="resp-img bordertop-img2" alt="img not found" />
                                                    </div>
                                                    <div className="bd-choose-img-inner mb-15">
                                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659370866/website/bespoke-system/bespoke-system-sec-1.3_bmzmjs.jpg" className="resp-img bordertop-img3" alt="img not found" />
                                                    </div>
                                                </div>
                                            </div>
                                            <img
                                                className="bd-choose-shape bd-choose-shape-2"
                                                src={Shape2}
                                                alt="shape img not found" />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-8 second_resp_bespoke" >
                                        <div className="bd-about-text bd-about-text-four mb-40 z-index">
                                            <div className="bd-section-title-wrapper">
                                                <h2 className="bd-section-title mb-15 md-pr-50"><span style={{ color: "#ffbf1e" }}>Bespoke system </span> for business is key to Success</h2>
                                                <p className="">A bespoke system is customized software to meet your specific needs and requirements, ensuring fantastic results. With a bespoke system, you can focus on what you do best - running your business!</p>
                                                <p >The benefits of using a bespoke system for business are countless. With a bespoke system, businesses can increase productivity and make profits. By working with a professional system builder, businesses can create a customized solution that works perfectly for them. This allows businesses to focus on the tasks they are best suited to and reduce the impact on their overall functioning. From better communication with customers to enhancing efficiency, a bespoke system can make your business run smoother.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ModalVideo autoplay isOpen={isOpen} channel='youtube' videoId='6wrgJR1n5ao' onClose={() => setOpen(false)}>
                </ModalVideo>
                <button className="btn btn touch_pop_vid first_pop_video_bespoke" onClick={() => setOpen(true)}>
                    <FontAwesomeIcon icon={faPlay} className="play_bt_vid play_transition" />
                </button>
            </section>

            {/* <!-- about area start here --> */}
            <section className="bd-about-six-area ">
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <h2 className="bd-section-title mb-25 bespoke_section2-tittle" >Create Custom Software Solutions That Fit Business</h2>
                    <div className="row bespoke_responsive">
                        <div className="col-lg-6 first_section_responsive ">
                            <div className="bd-about-six-text">
                                <div className="bd-section-title-wrapper mb-25">

                                    <p >The first step in creating a custom software solution is to define your business goals and objectives. This will help you determine which features and applications are necessary for your business. In addition, you’ll need to research and select a development partner who can provide the most efficient and effective support for your project.</p>
                                    <p >Once you’ve determined what features and applications are essential for your business, it’s time to research and select a development partner who can provide the best quality of services. Selecting the right development partner is important because they will be responsible for developing, testing, and launching your custom software solution. By working with a reputable development team, you can be sure that your solution will meet all of your business goals and objectives.</p>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-8 second_section_responsive">
                            <div className="bd-about-six-img">
                                <div className="bd-about-six-feature">
                                    <ul className="mb-45">
                                        <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> Individually Crafted Solution</li>
                                        <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> Growth & scalability</li>
                                        <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> Return On Investment</li>
                                        <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> Time-saving</li>
                                        <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> Competitive advantage</li>
                                        <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> No integration issues</li>
                                        <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> Easier maintenance</li>
                                        <li style={{ fontSize: "18px", color: "var(--clr-body-text)" }}><i><FontAwesomeIcon icon={faCheck} /></i> More independent</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            {/* choose section */}
            <section className="bd-choose-area fix">
                <div className="container" style={{ maxWidth: "1300px" }}>
                    <div className="bd-choose-shape pt-70">
                        <div className="row four_resp_section">

                            <div className="col-xl-6 col-lg-6 order-2 order-lg-1 four_point_section1">
                                {/* <div className="ablog ablog-4 mb-60"> */}
                                <div className="bd-choose-img z-index pt-70">

                                    <div className="bd-choose-info-wrapper">
                                        <div className="container">
                                            <div className="row mb-50">
                                                <div className="col cell_resp mr-30">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15">
                                                            <i>
                                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440034/website/icons/vertical-scalability-64_hj9kjd.png" />
                                                            </i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2 className="bd-choose-info-title mb-15" style={{ color: "#5e5858" }}>Scalability and flexibility</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15">
                                                            <i>
                                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440035/website/icons/ownership-64_iwkti6.png" />
                                                            </i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2 className="bd-choose-info-title mb-15" style={{ color: "#5e5858" }}>Complete ownership</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col cell_resp mr-30">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15">
                                                            <i>
                                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440035/website/icons/integration-64_n7ffaa.png" />
                                                            </i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2 className="bd-choose-info-title mb-15" style={{ color: "#5e5858" }}>Fast integration</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col respon_space">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15">
                                                            <i><img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440034/website/icons/trophy-64_t3l2yz.png" /></i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2 className="bd-choose-info-title mb-15" style={{ color: "#5e5858" }}>Competitive advantage</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                {/* </div> */}
                            </div>
                            <div className="col-xl-6 col-lg-6 order-1 order-lg-2 mt-0 four_point_section2">
                                <div className="bd-choose-text z-index">
                                    <h2 className="bd-section-title section_title2">Get the Best Software Solutions for Business from the Experts</h2>
                                    <div className="bd-section-title-wrapper">
                                        <p style={{ fontSize: "18px", lineHeight: "1.75" }}>How choose the right bespoke system for your business can be difficult. There are a variety of options available, and each has its own advantages and disadvantages. By choosing a bespoke system you can control the entire process. Also it is more efficient than traditional systems. If you are still undecided about which system to choose. With Zithas, you can ensure that you have access to the best possible system that meets your specific needs and goals. So whether you're looking for a new system for your office or a bespoke solution for your business, we have you covered.</p>
                                    </div>
                                    <div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Fifth Section */}

            <section className="bd-about-area">
                <div className="bd-custom-container" >
                    <div className="bd-about-four-bg  pt-80 pb-70 fix">
                        <div className="bd-about-shape-box">
                            <div className="container" style={{ maxWidth: "1270px" }}>
                                <div className="row justify-content-center">

                                    <div className="col-xl-6 col-lg-8 fifth_section_respon">
                                        <div className="bd-about-text bd-about-text-four mb-40 z-index">
                                            <div className="bd-section-title-wrapper">

                                                <h2 className="bd-section-title mb-15 bespoke-lastSection_tittle md-pr-50">Make Business Stand out with our Unique Bespoke Solution</h2>
                                                <p className="">With a customized system in place, businesses can save money, and time and have greater control over their operations. From the initial design to the final delivery, our team of experts will help make sure your business is using the right system for the right reasons. From a simple website design to a customized marketing plan, our team can help you get the system that is right for you. With our customized system, you can save time and improve effectiveness. You can also improve your working procedures and manage your business better. Our system is designed to help you grow your business and grow your profits.</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-8 pl-30 fifth_section_respon2">
                                        <div className="bd-about-img mb-30">
                                            <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659412855/website/bespoke-system/bespoke-system-sec-2_ev7qgp.jpg" style={{ height: "470px" }} className="xss-w-full rounded-10" alt="about img not found" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <ServiceBanner/> */}

            <section className="bd-video-area pt-135 pb-130" style={{ backgroundColor: "#5c0385" }}>
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row">
                        <div className="col-lg-12 first_section">
                            <div className="bd-video-text">
                                <h2 className="bd-video-title first-para-resp" >Looking for Bespoke System expert ?</h2>
                                <p className="contact_section second-para-resp">You're at the right place. The right time</p>
                                <p className="contact_subtext">Surprisingly great system, Let's get started right now !</p>
                            </div>
                        </div>
                        <div className="col-lg-12 second_section">
                            <div className="contact-stuff pt-30">
                                <div className="contact_icon pt-15 pl-50">
                                    <FontAwesomeIcon icon={faPhone} style={{ fontSize: "30px" }} />
                                </div>
                                <div className="contact_txt">
                                    <p className="contact-sub-text">CALL US NOW</p>
                                    <a href="tel:(+91)720-288-2277" style={{ fontSize: "17px" }} className="mbb-5 pb-20">(+91) 720-288-2277</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default BespokeSystem