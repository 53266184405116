import React, { useEffect, useState } from 'react'
import Header1 from '../../Components/seperate Components/Header1'
import Footer from '../../Components/Footer';
import "../../Styles/Redirecting Styles/FooterStyle/MainTestimonial.css"
import "../../Styles/Testimonial.css"
import trustPilot from "../../assets/logo/trustpilot.png"
import { Link } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll';
import axios from "axios"
import CompanyTestimonial from '../../Components/seperate Components/CompanyTestimonial';
import Pagination from '../../Components/seperate Components/Pagination';
import { Helmet } from 'react-helmet';


const MainTestimonial = () => {


    const toggleContact = () => {
        scroll.scrollToTop(0, 0);
    }


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const [isLoading, setLoading] = useState(true);
    const [text, setText] = useState([]);
    const [video, setVideo] = useState([]);
    const [posts, setPosts] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage] = useState(4);


    useEffect(() => {
        const fetch = async () => {
            try {
                const [{
                    data: text
                }, {
                    data: video
                }] = await Promise.all([
                    axios.get('https://mars.zithas.com/api/testimonial/1'),
                    axios.get('https://mars.zithas.com/api/testimonial/2'),
                ])
                setText(text);
                setVideo(video);
                setLoading(false)

            } catch (err) {
                console.error(err);
            }
        };
        fetch();
    }, []);

    // useEffect(() => {
    //     const fetch = async () => {
    //       try {
    //         const { data } = await axios.get("https://mars.zithas.com/api/testimonial");
    //         setText(data);
    //         setLoading(false);

    //       } catch (err) {
    //         console.error(err);
    //       }
    //     };
    //     fetch();
    //   }, []);

    const indexOfLastPage = currentPage * postPerPage;
    const indexOfFirstPage = indexOfLastPage - postPerPage;
    const currentPosts = text.slice(indexOfFirstPage, indexOfLastPage);
    const currentPosts1 = video.slice(indexOfFirstPage, indexOfLastPage);
    const paginate = currentPage => {
        setCurrentPage(currentPage);
    }

    return (
        <div>
            <Helmet>
                <title>Testimonials from our valuable clients resides worldwide</title>
                <meta name='description' content="Discover glowing testimonials from our global clientele. See what our valuable clients say about their experiences with us." />
            </Helmet>


            <Header1 />

            {/* Banner section */}
            <div className="bd-page-title-area bd-page-title-bg3 pt-220 pb-155" style={{ height: "380px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="bd-page-title text-center z-index" style={{ bottom: "45px" }}>
                                <h2 className="breadcrumb-title" style={{ fontSize: '50px', color: "#4a026c" }}>Testimonials</h2>
                                <div className="breadcrumb-menu">
                                    <nav className="breadcrumb-trail breadcrumbs">
                                        <p style={{ color: "black" }}>We're the professionals that have always been there for our clients. We're their personal testimonial!</p>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CompanyTestimonial video={currentPosts1} text={currentPosts} />

            <Pagination
                postPerPage={postPerPage}
                totalPosts={text.length}
                paginate={paginate}
            />

            <Footer />

        </div>
    )
}

export default MainTestimonial