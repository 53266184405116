import React, { useEffect, useState } from 'react'
import '../Styles/Portfolio.css'
import { animateScroll as scroll } from 'react-scroll';
import axios from "axios"
import { Link } from "react-router-dom"

const Portfolio = () => {

    const toggleContact = () => {
        scroll.scrollToTop(0, 0);
    }


    const [casestudies, setCaseStudies] = useState([])
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {

        const fetch = async () => {
            try {
                const { data } = await axios.get("https://mars.zithas.com/api/casestudies");
                setCaseStudies(data);


            } catch (err) {
                console.log(err)
            }
        }
        fetch();
    }, [])




    return (
        <div>

            <section className="bd-portfolio-area bd-portfolio-spacing pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="bd-section-title-wrapper text-center mb-50">
                                <h2 className="bd-section-title mb-25" style={{ fontSize: "60px" }}>Case <span style={{ color: "var(--clr-theme-1)" }}>Studies</span> </h2>
                                <p style={{ fontSize: "20px" }}>How We Help Your Business Succeed</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-30">
                        {casestudies.slice(0, 3).reverse().map((cases) => {
                            return (
                                <div className="col-lg-4 col-md-6 ">
                                    <Link to={`/case-study/${cases.permalink}`} onClick={toggleContact}>
                                        <div className="bd-portfolio mb-30 ">
                                            <img src={cases.main_image} alt="porfolio not found" />
                                            <div className="bd-portfolio-text">
                                                <span>{cases.service}</span>
                                                <h5 className="bd-portfolio-title">{cases.company_name}</h5>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </section>

        </div>
    )
}

export default Portfolio