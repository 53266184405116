import React from 'react'
import Header1 from '../../Components/seperate Components/Header1'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faFilePen, faMailBulk, faArrowPointer, faVideo, faUserGear } from '@fortawesome/free-solid-svg-icons'
import { faSearchengin } from '@fortawesome/free-brands-svg-icons';
import { ServiceBanner } from '../../Components/seperate Components/ServiceBanner';
import Footer from '../../Components/Footer';
import { Helmet } from 'react-helmet';

const MicroService = () => {
    return (
        <div>
            <Helmet>
                <title>Top Graphic Design Services Provider in India</title>
                <meta name='description' content="At Zithas, we provide micro services like content writing, lead generation, Hr recruitment services, graphic services, etc." />
            </Helmet>

            <Header1 />

            {/* First Section */}
            <div className="bd-page-title-area bd-page-title-bg2 pt-220 pb-155" style={{ height: "380px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="bd-page-title text-center z-index" style={{ bottom: "45px" }}>
                                <h2 className="breadcrumb-title" style={{ fontSize: '50px' }}>MICRO Services</h2>
                                <div className="breadcrumb-menu">
                                    <nav className="breadcrumb-trail breadcrumbs">
                                        <p style={{ color: "black" }}>We are your go-to source for marketing and graphic design services.</p>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* second Section */}

            <section className="bd-service-area pb-80 pt-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 pt-20">
                            <div className="bd-section-title-wrapper micro_respon mb-50">
                                <h2 className="bd-section-title micro-service-tittle"><span style={{ color: "#ffbf1e" }}> MICRO Services </span>  Services that are best for Your Business</h2>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i >
                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/v1659617689/website/icons/graphic-design_qtdwyx.png" />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20"><a>Graphic Services</a></h4>
                                <p>Brochures, business cards, logos, social media visuals are all things that our graphic design services.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i>
                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440676/website/icons/video-64_npwx9k.png" />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20"><a>Co-Operate Videos</a></h4>
                                <p>Corporate video include New product or service demonstrations Client and customer testimonial videos.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i>
                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440676/website/icons/content-64_ee1slf.png" />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20"><a>Content Writing</a></h4>
                                <p>With the right content, you can be sure that your message will reach the right people and turn them into customers.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i>
                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440676/website/icons/lead-64_rikrsj.png" />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20"><a>Lead Generation</a></h4>
                                <p>Whether you're looking to acquire new leads or evaluate existing ones, our lead generation services can help.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i>
                                        <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440676/website/icons/recruitment-64_ptz66r.png" />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20"><a href="https://thestaffguru.com/" target="_blank">HR Recruitment Services</a></h4>
                                <p>HR Consultancy & Solutions are offered by our Sister Firm, TheStaffGuru, for your business. <br /> <a className="link-service" href="https://thestaffguru.com/" target="_blank">https://thestaffguru.com/</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* CTA */}
            <section className="bd-video-area pt-135 pb-130" style={{ backgroundColor: "#5c0385" }}>
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row responsive-banner">
                        <div className="col-lg-12 first_section">
                            <div className="bd-video-text">
                                <h2 className="bd-video-title first-para-resp" >Looking for Micro Service Expert?</h2>
                                <p className="contact_section second-para-resp">You're at the right place. The right time</p>
                                <p className="contact_subtext">Surprisingly great Micro Service, Let's get started right now !</p>
                            </div>
                        </div>
                        <div className="col-lg-12 second_section">
                            <div className="contact-stuff pt-30">
                                <div className="contact_icon icon_respon pt-15 pl-50">
                                    <FontAwesomeIcon icon={faPhone} style={{ fontSize: "30px" }} />
                                </div>
                                <div className="contact_txt ">
                                    <p className="contact-sub-text">CALL US NOW</p>
                                    <a href="tel:(+91)720-288-2277" style={{ fontSize: "17px" }} className="mbb-5 pb-20">(+91) 720-288-2277</a>
                                </div>
                            </div>
                            {/* <Link to={"/zithas/contact-us"} onClick={toggleContact}>
                    <button className="touch-btn1 mt-20">Get Started</button>
                    </Link> */}
                        </div>

                    </div>
                </div>
            </section>

            <Footer />


        </div>
    )
}

export default MicroService