import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { faReply } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import replyImg from "../../assets/All Images/blog-reply-img.jpeg"
import { useParams } from 'react-router-dom';
import Spinner from './Spinner';

const Reply = ({ posts }) => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [blog_id, setBlogId] = useState("");
    const [isLoading, setLoading] = useState(false)
    const [comments, setComments] = useState([])

    const onHandleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const formData = new FormData();
        formData.append("name", name);  
        formData.append("email", email);
        formData.append("subject", subject);
        formData.append("message", message);
        {
            posts.map((blogid) => {
                formData.append("blog_id", blogid.id);
                formData.append("permalink", blogid.permalink);
            })
        }

        try {
            const response = await axios({
                method: "post",
                url: "https://mars.zithas.com/api/comments",
                data: formData,
                headers: { "Content-Type": "multipart/form-data", },

            })
            let resJson = await response.json();
            if (response.status === 200) {
                setLoading(false)
                alert("Data Send Successfully")
            }
        } catch (error) {
            console.log(error)
        }
    }

    const { permalink } = useParams();
    const fetch = async () => {
        try {
            const { data } = await axios.get(`https://mars.zithas.com/api/comment_reply/${permalink}`);
            setComments(data);
        } catch (err) {
            console.log(err)
        }
    }


    useEffect(() => {
        fetch();
    }, [])


    return (
        <div>
            <div className="post-comments mb-95">


                <div className="post-comment-title mb-40">
                    {comments.length > 0 ? <h3>{comments.length} Comments</h3> : null}

                </div>

                <div className="latest-comments ">
                    <ul>
                        <li>
                            {comments.map((avtCom) => {
                                return (
                                    <div className="comments-box">

                                        <div className="comments-avatar">
                                            <img src={replyImg} className="img-fluid" alt="img" />
                                        </div>
                                        <div className="comments-text pb-0">
                                            <div className="avatar-name">
                                                <h5>{avtCom.name}</h5>
                                                <span className="post-meta" style={{ color: "var(--clr-body-text)", fontWeight: "500" }}>{avtCom.created_at}</span>
                                            </div>
                                            <p>{avtCom.message}</p>
                                            {/* <a className="comment-reply"><i> <FontAwesomeIcon icon={faReply}/></i> Reply</a> */}
                                        </div>
                                    </div>
                                )
                            })}
                        </li>
                    </ul>
                </div>
                <div className="post-comment-form pt-50 ">
                    <h4>Leave a Reply </h4>

                    <div className="bd-contact-form-wrapper mb-30">
                        {/* {isLoading && (<Spinner />)} */}
                        <form onSubmit={onHandleSubmit}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="bd-contact-field mb-30" >
                                        <input type="name" placeholder="Name"
                                            onChange={(e) => setName(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="bd-contact-field mb-30" >
                                        <input type="email" placeholder="Email"
                                            onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="bd-contact-field mb-30" >
                                        <input type="text" placeholder="Subject"
                                            onChange={(e) => setSubject(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="bd-contact-field mb-30" >
                                        <textarea placeholder="Message" onChange={(e) => setMessage(e.target.value)} ></textarea>
                                    </div>
                                </div>
                                {posts.map((blogid) => {
                                    return (
                                        <div className="col-12">
                                            <div className="bd-contact-field mb-30" >
                                                <input type="hidden" placeholder={blogid.permalink} onSubmit={(e) => setBlogId(e.target.placeholder)} style={{ width: "300px" }} required />
                                            </div>
                                        </div>
                                    )
                                })}

                                <div className="col-12">
                                    <div className="bd-contact-field">
                                        <button type="submit" className="theme-btn" >Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reply