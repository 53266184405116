import React, { useState, useEffect } from 'react'
import Header1 from '../../../Components/seperate Components/Header1'
import { faCalendarDay, faMessage, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from '../../../Components/Footer';
import Reply from '../../../Components/seperate Components/Reply';
import RecentPost from '../../../Components/seperate Components/RecentPost';
import axios from 'axios';
import { useParams, Link } from "react-router-dom"
import Spinner from '../../../Components/seperate Components/Spinner';
import "../../../Styles/Redirecting Styles/FooterStyle/BlogDetails.css";
import ReactAudioPlayer from 'react-audio-player';
import moment from 'moment/moment';
import { Helmet } from 'react-helmet';


const BlogDetails = () => {

    const { permalink } = useParams();
    const [comments, setComments] = useState([])


    const fetchComments = async () => {
        try {
            const { data } = await axios.get(`https://mars.zithas.com/api/comment_reply/${permalink}`);
            setComments(data);
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        fetchComments();
    }, [permalink])



    const [post, SetPost] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const fetch = async () => {
            try {
                const { data } = await axios.get(`https://mars.zithas.com/api/blog/${permalink}`);
                SetPost(data);

                setLoading(false);
            } catch (err) {
                console.error(err);
            }
        };
        fetch();
    }, [permalink]);

    return (
        <div>
            <Helmet>
                <title>
                    {post[0]?.meta_title}
                </title>
                <meta
                    name="description"
                    content={post[0]?.meta_description}
                />
            </Helmet>

            {/* Header section */}
            <Header1 />
            <div className="bd-page-title-area bd-page-title-bg_blog blogdetail_responsive-ban pt-180 pb-155" style={{ height: "380px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {post.map((blogCont) => {
                                const setdate = moment(blogCont.publish_date).format("MMM Do YYYY");
                                return (
                                    <div className="bd-page-title text-center z-index">
                                        <h2 className="breadcrumb-title1 blogdetail-main-tittle">{blogCont.title}</h2>
                                        <div className="breadcrumb-menu">
                                            <div className="bd-blog-meta mb-15">
                                                <ul>
                                                    <li><a href=""><i><FontAwesomeIcon icon={faCalendarDay} /></i>{setdate}</a></li>
                                                    <li><a href=""><i><FontAwesomeIcon icon={faMessage} /></i>{comments.length} Comments</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>

            {/* Blogs section */}
            <section className="bd-blog-deatils-area pt-105 pb-100">
                <div className="container " style={{ maxWidth: "1270px" }}>
                    {isLoading ? (<Spinner />) : (
                        <div className="row">
                            {post.map((blogContent) => {
                                let imageVideo;
                                if (blogContent.type == "image") {
                                    imageVideo =
                                        <div>
                                            <img src={blogContent.detail_image} className="img-fluid" alt="img" />
                                        </div>
                                } else if (blogContent.type == "video") {
                                    imageVideo =
                                        <div>
                                            <iframe src={blogContent.detail_image} className="resp-videos "
                                                allowFullScreen="allowFullScreen" frameBorder="0" width="850" height="400" title="Everytime You Go Away - Paul Young" />
                                        </div>
                                }

                                let audio;
                                if (blogContent.audio == null) {
                                    audio = <></>
                                } else {
                                    audio =
                                        <div className="pt-20 pb-20">
                                            <div className="bd-cta-audio">
                                                <p className="mb-0 text-white pb-10">Listen our Audio Blog here!!</p>
                                                <audio controls style={{ width: "100%" }}>
                                                    <source src={blogContent.audio} type="audio/ogg" />
                                                </audio>
                                            </div>
                                        </div>
                                }

                                return (
                                    <>
                                        <div className="col-lg-8 Blog-responsive-Section1" >
                                            <div className="blog__details--wrapper mr-50 mb-50">
                                                <div className="ablog__img mb-50">
                                                    {imageVideo}
                                                </div>
                                                {audio}
                                                <div className='bg-blog-ul' dangerouslySetInnerHTML={{ __html: blogContent.description }} />

                                                <Reply posts={post} comments={comments} />
                                            </div>

                                        </div>
                                    </>
                                )
                            })}
                            <RecentPost />
                        </div>
                    )
                    }

                </div>
            </section>

            <Footer />
        </div>
    )
}

export default BlogDetails