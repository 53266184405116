import React, { useState } from "react";
import "../../Styles/Redirecting Styles/WebDevelopment.css";
import Footer from "../../Components/Footer";
import { faPhone, faPlay } from "@fortawesome/free-solid-svg-icons";
import "../../Styles/Service.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header1 from "../../Components/seperate Components/Header1";
import "../../Styles/Redirecting Styles/NavbarStyles/Business.css";
import Shape2 from "../../assets/brand/choose-shape-2.png";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import { Helmet } from "react-helmet";

const WebDevelopment = () => {
    const [isOpen, setOpen] = useState(false);

    return (
        <div>
            <Helmet>
                <title>Business Automation Expert India | Effective Automation Solutions</title>
                <meta name='description' content='Unlock the power of business automation with our expert solutions in India. Transform your processes and boost efficiency.' />
                <meta name="keywords" content="business automation expert,business automation expert india,Business Automation,business automation india" />
            </Helmet>

            <Header1 />

            {/* Banner section */}
            <div
                className="bd-page-title-area bd-page-title-bg2 pt-220 pb-155"
                style={{ height: "380px" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div
                                className="bd-page-title text-center z-index"
                                style={{ bottom: "45px" }}
                            >
                                <h2
                                    className="breadcrumb-title"
                                    style={{ fontSize: "50px", color: "#4a026c" }}
                                >
                                    Business Automation
                                </h2>
                                <div className="breadcrumb-menu">
                                    <nav className="breadcrumb-trail breadcrumbs">
                                        <p style={{ color: "black" }}>
                                            Business automation is the combination of technology and
                                            business strategy to help businesses run more effectively.
                                        </p>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* First Section */}
            <section className="bd-choose-area fix pt-80">
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="bd-choose-shape">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 order-2 order-lg-1 pr-20">
                                <div className="bd-choose-text z-index">
                                    <div className="bd-section-title-wrapper ">
                                        <h2 className="bd-section-title mb-15 first_section-title">
                                            Little-Known Service You Should Be Using
                                        </h2>
                                    </div>

                                    <p
                                        className="mb-20 first_section_para1"
                                        style={{ fontSize: "18px" }}
                                    >
                                        Business automation is the combination of technology and
                                        business strategy to help businesses run more effectively.
                                        Whether it's office automation like Outlook or Google
                                        Sheets, or product automation such as Salesforce or Jetpack,
                                        businesses can benefit from using business automation to
                                        streamline their work. Business automation is a key part of
                                        any business, and there are a number of different types of
                                        business automation systems to choose from. here at Zithas,
                                        we've put together a list of the best business automation
                                        systems on the market today, so you can get started on your
                                        own business.
                                    </p>
                                    <p
                                        className="mb-20 first_section_para1"
                                        style={{ width: "95%", fontSize: "18px" }}
                                    >
                                        {" "}
                                        Companies of all sizes need business automation to make
                                        business processes easier. Whether it just starting out or
                                        want to take business to the next level. Business Automation
                                        is a service that can help you automate common tasks in your
                                        business. Business Automation is a great way to improve your
                                        business efficiency and increase your profits.Business
                                        automation is a process of automating tasks and activities
                                        inside or outside of a business to improve efficiency and
                                        effectiveness.
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 order-1 order-lg-2">
                                <div className="bd-choose-img text-end top-resp z-index">
                                    <div className="row align-items-center  custom-mar-15">
                                        <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                                            <div className="bd-choose-img-inner mb-15 bd-choose-img-inner-1">
                                                <img
                                                    src=" https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659372146/website/business-automation/business-automation-sec-1.2_s6k5dn.jpg "
                                                    className="resp-img bordertop-img1"
                                                    alt="img not found"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                                            <div className="bd-choose-img-inner mb-15">
                                                <img
                                                    src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659372146/website/business-automation/business-automation-sec-1.1_gdvyma.jpg"
                                                    className="resp-img bordertop-img2"
                                                    alt="img not found"
                                                />
                                            </div>
                                            <div className="bd-choose-img-inner mb-15">
                                                <img
                                                    src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659372146/website/business-automation/business-automation-sec-1.3_o5bd0e.jpg"
                                                    className="resp-img bordertop-img3"
                                                    alt="img not found"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <img
                                        className="bd-choose-shape bd-choose-shape-2"
                                        src={Shape2}
                                        alt="shape img not found"
                                    />
                                    {/* <div className="bd-choose-video-popup">
                                    <a href="https://www.youtube.com/watch?v=jdkWIdJobSA" className="play_btn popup-video"><i className="fas fa-play"></i></a>
                  </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ModalVideo
                    autoplay
                    isOpen={isOpen}
                    channel="youtube"
                    videoId="2LRxhuDssLQ"
                    onClose={() => setOpen(false)}
                ></ModalVideo>
                <button
                    className="btn btn responsive_digital first_pop_video_business"
                    onClick={() => setOpen(true)}
                >
                    <FontAwesomeIcon
                        icon={faPlay}
                        className="play_bt_vid play_transition"
                    />
                </button>
            </section>

            {/* Second Section */}
            <section className="bd-about-area-two ">
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-8 second_section_Business1">
                            <div className="bd-about-img-two">
                                <img
                                    src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659412581/website/business-automation/business-automation-sec-2_g1vzdo.jpg"
                                    className="pl-110"
                                    alt="img not found"
                                    style={{ width: "93%" }}
                                />
                                {/* <div className="bd-about-img-two-expe">
                                <img src={aboutShape6} alt="img not found"/>
                                <div className="bd-about-img-two-expe-text">
                                    <h4>5+</h4>
                                    <span>Experience</span>
                                </div>
                            </div> */}
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-8 second_section_Business2">
                            <div className="bd-about-text-two">
                                <div className="bd-section-title-wrapper mb-25">
                                    <h2 className="bd-section-title mb-15 second_sec_tittle">
                                        {" "}
                                        <span style={{ color: "#ffbf1e" }}>
                                            Business Automation{" "}
                                        </span>
                                        Is The Next Big Thing Leaders Should Be Embracing
                                    </h2>
                                    <p>
                                        Business automation services provide a number of benefits
                                        for business leaders, including increased efficiency and
                                        accuracy. For example, business automation services can help
                                        to reduce the time it takes to complete tasks, improve
                                        accuracy and consistency in business operations, and reduce
                                        the cost of business operations. Additionally, business
                                        automation services can help to improve communication and
                                        collaboration between business owners and employees.
                                        Automation software can help businesses automate tasks and
                                        processes to speed up workflows and improve efficiency. This
                                        can save time and money for businesses, as well as make
                                        their operations more organized and efficient. Additionally,
                                        automation software can help businesses stay compliant with
                                        regulations and improve their compliance posture.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Third Section */}
            <section className="bd-service-area pt-80 ">
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row">
                        <div className="col-12">
                            <div className="bd-section-title-wrapper text-center mb-25">
                                <h2
                                    className="bd-section-title mb-10 third_sec_tittle"
                                    style={{ textAlign: "left" }}
                                >
                                    Making Your Business More Productive with{" "}
                                    <span style={{ color: "#ffbf1e" }}>Business Automation</span>{" "}
                                </h2>
                            </div>
                        </div>
                    </div>
                    <h4 className="bd-service-title mb-20">
                                    <a>Types of Automation</a>
                                </h4>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="bd-service mb-30"
                                style={{ backgroundColor: "var(--clr-bg-gray)" }}
                            >
                                <h4 className="bd-service-title mb-20">
                                    <a>Task Automation.</a>
                                </h4>
                                <p>
                                    Task automation software can help your company automate
                                    payroll processes so your HR team can focus on developing
                                    teams and other important tasks.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="bd-service mb-30"
                                style={{ backgroundColor: "var(--clr-bg-gray)" }}
                            >
                                <h4 className="bd-service-title mb-20">
                                    <a>Workflow Automation.</a>
                                </h4>
                                <p>
                                    Areas where workflow management can be used include managing
                                    work schedules, on-call staff rotations, deadline management,
                                    or assigning service requests
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="bd-service mb-30"
                                style={{ backgroundColor: "var(--clr-bg-gray)" }}
                            >
                                <h4 className="bd-service-title mb-20">
                                    <a>Process Automation.</a>
                                </h4>
                                <p>
                                    Complete automation of business processes enables
                                    organizations to deliver their products or services
                                    effectively to their customers and make work easier on staff
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Fourth Section */}
            <section className="bd-brand-area-two pb-80 pt-70">
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row fourt_respon_business">
                        <div className="col-xl-4 col-lg-6 col-md-6 first_part_respons">
                            <div className="col-12">
                                <div className="bd-section-title-wrapper mb-20">
                                    <h2 className="bd-section-title mb-25 pl-0 fourth_sec_tittle">
                                        Pitfalls When Implementing{" "}
                                        <span style={{ color: "#ffbf1e" }}>
                                            {" "}
                                            Business Automation
                                        </span>{" "}
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-6 col-md-6 second_part_respons">
                            <div className="bd-brand-active-two swiper-container">
                                <div className="swiper-wrapper">
                                    <p style={{ fontSize: "18px", lineHeight: "1.75" }}>
                                        Businesses that employ business automation confront
                                        difficulties. Some of the difficulties include repeating
                                        chores, completing them rapidly, failing to manage them,
                                        completing them in the wrong order, and failing to spot
                                        potential issues with them. Businesses may occasionally be
                                        unsure of the requirements or best practises for
                                        implementing business automation Businesses might also lack
                                        the resources needed to execute business automation.
                                        Businesses that implement business automation confront
                                        obstacles, such as figuring out how to use technology as
                                        effectively as feasible, managing and overseeing automation
                                        initiatives, and retaining staff who are accustomed to
                                        manual labour. To overcome these challenges, businesses can
                                        use a variety of strategies.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Fiveth Section */}
            <section className="bd-about-six-area ">
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row fifth_section_responsive">
                        <div className="col-lg-6 Business_fifth_flex1">
                            <div className="bd-about-six-text">
                                <div className="bd-section-title-wrapper mb-25">
                                    <h2 className="bd-section-title mb-25 sixth_sec_tittle">
                                        {" "}
                                        <span style={{ color: "#ffbf1e" }}>
                                            Business Automation{" "}
                                        </span>{" "}
                                        Service That's Right For Your Business{" "}
                                    </h2>
                                    <div className="bd-about-six-feature">
                                        <p className="" style={{ width: "95%" }}>
                                            Businesses can determine if business automation services
                                            are right for them by evaluating their specific business
                                            needs and the services offered by different providers.
                                            Additionally, businesses can ensure that their business
                                            automation services are effective and efficient by reading
                                            and reviewing the available providers' customer reviews.
                                        </p>

                                        <p style={{ width: "95%" }}>
                                            The three most common business automation services are
                                            software, hardware, and online tools. Software-based
                                            businesses can use software such as Salesforce or
                                            QuickBooks to manage their sales and accounting data.
                                            Hardware-based businesses can use machines such as those
                                            used in factories or warehouses to automate the tasks of
                                            shipping and handling products. Online tools-based
                                            businesses can use tools such as Google Docs or Microsoft
                                            Excel to manage their business paperwork and data.
                                        </p>

                                        <p style={{ width: "95%" }}>
                                            Whichever business automation service is chosen,
                                            businesses should be sure to understand the features and
                                            benefits of each service before making any decisions.
                                            Additionally, businesses should consider the costs
                                            associated with each type of business automation Look no
                                            further than our team of experts. Our business automation
                                            services can help you create and manage your business
                                            processes more effectively, ensuring that your business is
                                            running smoothly and efficiently. Let us help you make
                                            your business run more smoothly!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-8 Business_fifth_flex2">
                            <div className="bd-about-six-img">
                                <div className="bd-about-six-feature">
                                    <div className="bd-slider-six-img">
                                        <img
                                            src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659412581/website/business-automation/business-automation-sec-3_icf3jr.jpg"
                                            className="rounded-10"
                                            alt="img not found"
                                            style={{ width: "100%" }}
                                        />
                                        <img
                                            src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659587681/website/business-audit/slider-shape-3_rtsny4.png"
                                            className="bd-slider-six-img-shape1"
                                            alt="img not found"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Sixth Section */}
            <section className="bd-choose-area fix">
                <div className="container" style={{ maxWidth: "1300px" }}>
                    <div className="bd-choose-shape pt-80 pb-80 ">
                        <div className="row Business_resp_section">
                            <div className="col-xl-6 col-lg-6 order-2 order-lg-1 sixth_sec1_resp">
                                {/* <div className="ablog ablog-4 mb-60"> */}
                                <div className="bd-choose-img z-index">
                                    <div className="bd-choose-info-wrapper">
                                        <div className="container">
                                            <div className="row mb-50">
                                                <div className="col business-resp mr-50">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15">
                                                            <i>
                                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440127/website/icons/productive-64_slmdbc.png" />
                                                            </i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2
                                                                className="bd-choose-info-title mb-15"
                                                                style={{ color: "#5e5858" }}
                                                            >
                                                                Greater Productivity
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15">
                                                            <i>
                                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440127/website/icons/customer-64_do2spq.png" />
                                                            </i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2
                                                                className="bd-choose-info-title mb-15"
                                                                style={{ color: "#5e5858" }}
                                                            >
                                                                Better Customer Service
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container">
                                            <div className="row ">
                                                <div className="col mr-30">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15">
                                                            <i>
                                                                <img
                                                                    src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440127/website/icons/tracking-32_foagg2.png"
                                                                    style={{ width: "62px" }}
                                                                />
                                                            </i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2
                                                                className="bd-choose-info-title mb-15"
                                                                style={{ color: "#5e5858" }}
                                                            >
                                                                Full Audibility and Tracking
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15">
                                                            <i>
                                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659440127/website/icons/aim-64_o0wi5u.png" />
                                                            </i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2
                                                                className="bd-choose-info-title mb-15"
                                                                style={{ color: "#5e5858" }}
                                                            >
                                                                Increased Efficiency & Improved Accuracy
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                            <div className="col-xl-6 col-lg-6 order-1 order-lg-2 mt-0 sixth_sec2_resp">
                                <div className="bd-choose-text z-index">
                                    <h2 className="bd-section-title business-last-tittle">
                                        How we can Help Developing System
                                    </h2>
                                    <div className="bd-section-title-wrapper">
                                        <p style={{ fontSize: "18px", lineHeight: "1.75" }}>
                                            Our company's mission is to help businesses operate more
                                            efficiently and effectively. We offer a wide range of
                                            business automation services that can help your business
                                            get the most out of its technology. We provide
                                            top-of-the-line tools and services that will make your
                                            business run more smoothly and efficiently. We offer
                                            businesses convenient, affordable business automation
                                            services that make working smarter.
                                        </p>
                                    </div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* CTA */}

            <section
                className="bd-video-area pt-135 pb-130"
                style={{ backgroundColor: "#5c0385" }}
            >
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row responsive-banner">
                        <div className="col-lg-12 first_section">
                            <div className="bd-video-text">
                                <h2 className="bd-video-title first-para-resp">
                                    Looking for Business Automation Expert?
                                </h2>
                                <p className="contact_section second-para-resp">
                                    You're at the right place. The right time
                                </p>
                                <p className="contact_subtext">
                                    Surprisingly great Automation, Let's get started right now !
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-12 second_section">
                            <div className="contact-stuff pt-30">
                                <div className="contact_icon icon_respon pt-15 pl-50">
                                    <FontAwesomeIcon
                                        icon={faPhone}
                                        style={{ fontSize: "30px" }}
                                    />
                                </div>
                                <div className="contact_txt ">
                                    <p className="contact-sub-text">CALL US NOW</p>
                                    <a
                                        href="tel:(+91)720-288-2277"
                                        style={{ fontSize: "17px" }}
                                        className="mbb-5 pb-20"
                                    >
                                        (+91) 720-288-2277
                                    </a>
                                </div>
                            </div>
                            {/* <Link to={"/zithas/contact-us"} onClick={toggleContact}>
                    <button className="touch-btn1 mt-20">Get Started</button>
                    </Link> */}
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            {/* </div> */}
        </div>
    );
};

export default WebDevelopment;
